<template>
	<slot :searchParam="searchParam"></slot>
	<export-2-excel
		:filename="defaultFileName"
		:headers="header"
		:filterVal="filterVal"
		@export2excel="loadData($event)"
		ref="excelExport"
	></export-2-excel>
</template>

<script>
import Export2Excel from '../../Excel/index.vue';
import { AdminFetchCustomerOrder } from '@/api/fetchData.js';
export default {
	components: { Export2Excel },
	props: ['searchParam'],
	data() {
		return {
			defaultFileName: 'Ordini',
			header: [
				'Tipo Vendita',
				'Data Ordine',
				'ID Ordine',
				'Agente',
				'Cliente',
				'Email',
				'Note del cliente',
				'Pagato',
				'IVA',
				'Coupon',
				'Spese di Spedizione',
				'Licenza',
				'Totale Licenza',
				'IVA Licenza',
				'Gift Card',
				'Gift Card ID',
				'Valore Fatturato',
				'Valore Commissioni',
				'Stato',
				'Metodo di Pagamento',
			],
			filterVal: [
				'tipoOrdine',
				'orderCreated',
				'orderID',
				'agente',
				'customer',
				'billingEmail',
				'customerNote',
				'total',
				'totalTax',
				'discountTotal',
				'shippingTotal',
				'licenza',
				'licenzaTotal',
				'licenzaTax',
				'giftCard',
				'giftCardId',
				'totaleFatturabile',
				'totaleCommissionabile',
				'status',
				'paymentMethod',
			],
		};
	},
	methods: {
		loadData() {
			const allRows = true;			
			AdminFetchCustomerOrder(
				undefined,							//pageNumber
				undefined,							//pageSize
				this.searchParam.pkCustomerOrder,	//pkCustomerOrder				
				this.searchParam.DataInizio,		//DataInizio
				this.searchParam.DataFine,			//DataFine
				this.searchParam.PkAgenti,			//PkAgenti
				this.searchParam.Ricerca,			//Ricerca
				this.searchParam.Stato,				
				allRows
			)
				.then((response) => {
					const tableData = response.data;
					this.$refs.excelExport.Export2Excel(tableData);
				})
				.catch((error) =>
					window.alert('Attenzione, si è verificato un errore ' + error)
				)
				.finally(() => {});
		},
	},
};
</script>

<style>
</style>

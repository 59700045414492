<template>
  <slot :id="id" :qualifiche="qualifiche" :agents="agents"></slot>
  <el-button
    type="primary"
    :loading="loadingDialog"
    size="mini"
    @click="onOpenDialog"
  >
    Registra
  </el-button>

  <el-dialog
    v-model="dialogVisible"
    :before-close="onCloseDialog"
    center
    append-to-body
  >
    <template #title>
      <span class="fw-bold">Registra Agente</span>
    </template>
    <div
      :class="[
        'alert',
        'text-center',
        submitResult ? 'alert-success' : 'alert-danger',
      ]"
      v-if="isSubmitted"
    >
      {{ submitResult ? axiosSuccessMessage : axiosErrorMessage }}
    </div>

    <el-form show-message label-position="left" label-width="190px">
       <el-form-item label="Agente" :error="agenteField.errorMessage">
        <el-input
          disabled
          :model-value="agenteField.value"
          type="text"
          @update:model-value="agenteField.handleChange"
          @change="formModified = true"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="Agente Sponsor" :error="parentIDField.errorMessage">
        <el-button
          type="primary"
          class="me-3"
          @click="handleReferralDialogVisible"
          >Modifica</el-button
        >
        <span v-if="parentIDField.value != 0 && parentIDField.value != null">
          {{ agentePadreField.value }} ({{ agentePadreCodiceField.value }})
        </span>
        <span v-else>Selezionare un agente</span>
      </el-form-item>
      <el-form-item label="Sponsor" :error="usernameField.errorMessage">
        {{ sponsorField.value }}
      </el-form-item>
      <el-form-item label="Username" :error="usernameField.errorMessage">
        <el-input
          disabled
          :model-value="usernameField.value"
          type="text"
          @update:model-value="usernameField.handleChange"
          @change="formModified = true"
        >
        </el-input>
      </el-form-item>
      <el-form-item
        label="Inizio Attivita"
        :error="attivitaInizioField.errorMessage"
      >
        <el-date-picker
          type="date"
          placeholder="gg/mm/aaaa"
          format="DD/MM/YYYY"
          name="attivitaInizio"
          v-model="attivitaInizioField.value"
          @update:v-model="attivitaInizioField.handleChange"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item
        label="ID Ordine"
        :error="orderIDField.errorMessage"
        v-if="!orderIDNull"
      >
        <el-input
          :model-value="orderIDField.value"
          type="text"
          @update:model-value="orderIDField.handleChange"
          @change="formModified = true"
        >
        </el-input>
      </el-form-item>
      <el-form-item
        label="Totale Pagato"
        :error="licenzaTotalField.errorMessage"
        v-if="!orderIDNull"
      >
        <el-input
          style="width: 40%"
          :model-value="licenzaTotalField.value"
          type="text"
          @update:model-value="licenzaTotalField.handleChange"
          @change="formModified = true"
        >
        </el-input>
        <span class="ms-4" v-if="isPagato"
          >L'ordine è stato pagato</span
        >
        <span class="ms-4" v-else>L'ordine non è stato ancora pagato</span>
      </el-form-item>
      <el-form-item
        label="Licenza"
        :error="licenzaField.errorMessage"
        v-if="!orderIDNull"
      >
        <el-input
          disabled
          :model-value="licenzaField.value"
          type="text"
          @update:model-value="licenzaField.handleChange"
          @change="formModified = true"
        >
        </el-input>
      </el-form-item>
      <el-form-item
        label="Licenza"
        :error="checkLicenzaField.errorMessage"
        v-if="orderIDNull"
      >
      <span  class="alert alert-danger me-3 mb-1" role="alert">Non è stata trovata una licenza acquistata. Controllare l'email di registrazione.</span>
        <el-checkbox
          label="Iscrivi senza verificare l'acquisto della licenza"
          v-model="checkLicenzaField.value"
          @update:v-model="checkLicenzaField.handleChange"
          @change="formModified = true"
        >
        </el-checkbox
      ></el-form-item>
      <el-form-item label="Qualifica" :error="qualificaField.errorMessage">
        <el-select
          :model-value="qualificaField.value"
          @update:model-value="qualificaField.handleChange"
          @change="formModified = true"
        >
          <el-option
            v-for="item in qualifiche"
            :key="item.pkQualifica"
            :label="item.descrizione"
            :value="item.pkQualifica"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="info" @click="onCloseDialog"> Chiudi </el-button>
        <el-button
          type="primary"
          @click="submit"
          :disabled="saveButtonDisabled"
          :loading="loading"
          v-if="!submitResult"
        >
          Registra
        </el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog center v-model="refIdDialog" width="600px" append-to-body>
    <template #title>
      <span class="fw-bold">Seleziona un Agente</span>
    </template>
    <div class="row">
      <div class="col text-center">
        <el-select
          v-model="referralID"
          placeholder="Seleziona Agente"
          filterable
          size="medium"
          class="ms-3"
          style="width: 260px"
          ><el-option
            v-for="item in agents"
            :key="item.pkAgente"
            :value="
              item.codice +
              '&' +
              item.pkAgente +
              '&' +
              item.nome +
              ' ' +
              item.cognome
            "
            :label="item.nome + ' ' + item.cognome + ' (' + item.codice + ')'"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <template #footer>
      <el-button type="info" @click="refIdDialog = false"> Indietro </el-button>
      <el-button type="primary" @click="handleReferralChange">
        Conferma
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import { AdminFetchRegisterInfo } from "@/api/fetchData.js";
import { AttivaAgente } from "@/api/modifyData.js";
import { useField, useForm } from "vee-validate";
import { reactive } from "vue";
import * as yup from "yup";
export default {
  emits: ["licenzaAttivata"],
  props: ["id", "agents", "qualifiche"],
  setup() {
    const { meta: formMeta, handleSubmit, resetForm, handleReset } = useForm();
    const agenteField = reactive(useField("agente", yup.string()));
    const usernameField = reactive(useField("username", yup.string()));
    const sponsorField = reactive(useField("sponsor", yup.string()));
    const agentePadreField = reactive(useField("agentePadre", yup.string()));
    const orderIDField = reactive(useField("orderID", yup.string().nullable()));
    const licenzaTotalField = reactive(
      useField("licenzaTotal", yup.string().nullable())
    );
    const licenzaField = reactive(useField("licenza", yup.string().nullable()));
    const checkLicenzaField = reactive(useField("checkLicenza", yup.string()));
    const parentIDField = reactive(
      useField("fkAgentePadre", yup.string().required("Campo richiesto"))
    );
    const attivitaInizioField = reactive(
      useField(
        "attivitaInizio",
        yup.string().required("Campo richiesto").nullable()
      )
    );
    const agentePadreCodiceField = reactive(
      useField("agentePadreCodice", yup.string().required("Campo richiesto"))
    );
    const qualificaField = reactive(
      useField("fkQualifica", yup.string().required("Campo richiesto"))
    );
    return {
      agenteField,
      usernameField,
      attivitaInizioField,
      checkLicenzaField,
      sponsorField,
      agentePadreField,
      orderIDField,
      licenzaTotalField,
      agentePadreCodiceField,
      parentIDField,
      qualificaField,
      licenzaField,
      handleSubmit,
      formMeta,
      resetForm,
      handleReset,
    };
  },  
  data() {
    return {
      loadingDialog: false,
      isSubmitted: false,
      submitResult: false,
      formModified: false,
      refIdDialog: false,
      referralID: "",
      saveButtonDisabled: false,
      axiosSuccessMessage: "",
      axiosErrorMessage: "",
      submit: this.handleSubmit(this.onFormSubmit),
      dialogVisible: false,
      loading: false,
      agentRegisterInfo: [],
      orderIDNull: false,
      isPagato:false,
    };
  },
  methods: {
    inizioAttesa(){
      this.loadingDialog = true;
    },
    fineAttesa(){
      this.loadingDialog = false;
    },
    showForm(response){
      this.initForm(response);
      this.dialogVisible = true;
    },
    closeForm(done){
      if (done != undefined) done;
      this.dialogVisible = false;
      this.formModified = false;
    },
    initForm(agentRegisterInfo) {
      this.isSubmitted = false;
      this.submitResult = false;
      this.formModified = false;
      this.refIdDialog = false;
      this.referralID = "";
      this.saveButtonDisabled = false;
      this.axiosSuccessMessage = "";
      this.axiosErrorMessage = "";
      this.dialogVisible = false;
      this.loading = false;
      this.orderIDNull = false;
      this.isPagato=false

      this.resetForm();

      if (agentRegisterInfo.pkCustomerOrder != null) {
        this.orderIDField.value = agentRegisterInfo.orderID;
        this.licenzaTotalField.value =
          agentRegisterInfo.licenzaTotal.toFixed(2) + "€";
        this.licenzaField.value = agentRegisterInfo.licenza;
      } else {
        this.orderIDNull = true;
      }
      this.agenteField.value=agentRegisterInfo.agente
      this.checkLicenzaField.value = false;
      this.usernameField.value = agentRegisterInfo.username;
      this.attivitaInizioField.value = agentRegisterInfo.attivitaInizio;
      this.sponsorField.value = agentRegisterInfo.sponsor;
      this.agentePadreField.value = agentRegisterInfo.agentePadre;
      this.agentePadreCodiceField.value = agentRegisterInfo.agentePadreCodice;
      this.parentIDField.value = agentRegisterInfo.fkAgentePadre;
      if (agentRegisterInfo.fkQualifica != 0) {
        this.qualificaField.value = agentRegisterInfo.fkQualifica;
      }
      this.isPagato=agentRegisterInfo.isPagato
    },
    
    onOpenDialog() {
      
      this.inizioAttesa();
      
      AdminFetchRegisterInfo(this.id).then((response) => {          
          
          this.showForm(response.data);          
          
        })
        .catch(() => {
          alert("Errore durante il caricamento delle informazioni dell'agente");
        })
        .finally(() => {
          this.fineAttesa();
        });
    },

    handleReferralChange() {
      let referral = this.referralID.split("&");
      this.parentIDField.value = referral[0];
      this.agentePadreCodiceField.value = referral[1];
      this.agentePadreField.value = referral[2];
      this.refIdDialog = false;
    },
    handleReferralClose() {
      this.refIdDialog = false;
      this.formModified = true;
    },
    handleReferralDialogVisible() {
      this.refIdDialog = true;
    },

    onFormSubmitDone(){
          this.isSubmitted = true;
          this.submitResult = true;
          this.axiosSuccessMessage = "Licenza attivata con successo";
          this.formModified = false;
          this.$emit("licenzaAttivata");
    },
    onFormSubmitError(e){
          this.isSubmitted = true;
          this.submitResult = false;
          this.axiosErrorMessage = "Errore durante l'attivazione della licenza "+e;          
    },
    onCloseDialog(done) {
    
    if (this.formModified == true) {
        this.$confirm("Are you sure to close this dialog?").then(() => {            
            this.closeForm(done)
          }).catch(() => {
            //alert("Si è verificato un errore:"+e);
            });
      } else {
        //this.dialogVisible = false;
        this.closeForm();
      }
    },
    onFormSubmit(formValues) {      
      this.loading = true;
      this.isSubmitted = false;
      this.saveButtonDisabled = true;
      AttivaAgente(this.id, formValues).then(() => {
        this.onFormSubmitDone();
        })
        .catch((e) => {
          this.onFormSubmitError(e);
        })
        .finally(() => {
          this.saveButtonDisabled = false;
          this.loading = false;
        });
    },
  },
};
</script>

<style>
</style>

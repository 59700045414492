<template>
  <slot :id="id"></slot>

  <el-popconfirm
    title="Sei sicuro di voler ricalcolare le provvigioni?"
    @confirm="ricalcolaProvvigioni"
    confirmButtonText="Si"
    cancelButtonText="No"
  >
    <template #reference>
      <el-button type="warning" :loading="loading">
        Ricalcola
      </el-button>
    </template>
  </el-popconfirm>
</template>

<script>
import { RicalcolaProvvigioni } from "@/api/modifyData.js";
export default {
  props: ["id"],
  emits:["ricalcolaSuccess"],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async ricalcolaProvvigioni() {
      this.loading = true;
      await RicalcolaProvvigioni(this.id)
        .then(() => {
          alert("Provvigioni ricalcolate con successo");
          this.$emit('ricalcolaSuccess')
        })
        .catch((e) => {		
          alert("Errore nel calcolo delle provvigioni:\r\n"+e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
</style>
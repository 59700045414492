<template>
  <div class="row">
    <div class="col ms-3 mb-4">
      <el-date-picker
        v-model="dateSearch"
        type="daterange"
        size="medium"
        format="DD/MM/YYYY"
        placeholder="Seleziona"
        start-placeholder="Data iniziale"
        end-placeholder="Data finale"
        range-separator="al"
        clearable
      >
      </el-date-picker>
      <el-select
        clearable
        :loading="loadingAgents"
        v-model="ordersSearch.PkAgenti"
        placeholder="Seleziona Agente"
        filterable
        multiple
        size="medium"
        class="ms-3"
        style="width: 260px"
        ><el-option
          v-for="item in agentsSelect"
          :key="item.pkAgente"
          :value="item.pkAgente"
          :label="item.nome + ' ' + item.cognome + ' (' + item.codice + ')'"
        >
        </el-option>
      </el-select>
      <el-button size="medium" icon="el-icon-search" type="primary" class="ms-3" @click="onRicerca" :loading="loadingRicerca"
        >Cerca</el-button
      >
    </div>
  </div>
  <el-table
    v-loading="loading"
    :data="fatture"
    stripe
    fit
    highlight-current-row
    border
  >
  <el-table-column type="expand">
    <template v-slot="props">
      <table-expand-row :id="props.row.pkFattura"></table-expand-row>
    </template>
  </el-table-column>
    <el-table-column label="Numero" prop="numero" align="center" />
    <el-table-column label="Data" prop="dataOra" align="center" />
    <el-table-column
      label="Ragione Sociale"
      prop="cedenteRagioneSociale"
      align="center"
    />
    <el-table-column
      label="Partita IVA"
      prop="cedentepartitaIVA"
      align="center"
    />
    <el-table-column
      label="Codice Fiscale"
      prop="cedenteCodiceFiscale"
      align="center"
    />
    <el-table-column label="Nota" prop="nota" align="center" />
    <el-table-column label="Pagata" align="center">
      <template v-slot="props">
        <font-awesome-icon
          :icon="paid"
          v-if="props.row.pagata"
        ></font-awesome-icon>
        <font-awesome-icon :icon="notPaid" v-else></font-awesome-icon>
      </template>
    </el-table-column>
    <el-table-column
      label="Azioni"
      align="center"
      width="180px"
    >
      <template v-slot="{ row }">
        <visualizza-fattura :id="row.pkFattura" :numeroFattura="row.numero.replace('/', '_')"> </visualizza-fattura>
      </template>
    </el-table-column>
  </el-table>
  <pagination
    v-show="Number(pager.TotalCount) > 0"
    :total="Number(pager.TotalCount)"
    v-model:current-page="pageNumber"
    v-model:page-size="pageSize"
    @pagination="fetchFatture"
    :disabled="disabledPagination"
  >
  </pagination>
</template>

<script>
import TableExpandRow from "./AdminTableProvvigioniExpandRow.vue"
import Pagination from "../../Pagination/index.vue";
import VisualizzaFattura from "./AdminStampareFattura.vue";
import { faEuroSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faCreativeCommonsNcEu } from "@fortawesome/free-brands-svg-icons";
import moment from "moment";
import { AdminFetchFatture, FetchAgentsSelect } from "@/api/fetchData.js";
export default {
  components: { FontAwesomeIcon, VisualizzaFattura, Pagination,TableExpandRow },
  data() {
    return {
      loadingRicerca: false,
      disabledPagination: false,
      notPaid: faCreativeCommonsNcEu,
      paid: faEuroSign,
      loadingAgents: false,
      agentsSelect: [],
      dateSearch: null,
      loading: false,
      fatture: [],
      pager: [],
      pageNumber: 1,
      pageSize: 10,
      ordersSearch: {
        DataInizio: undefined,
        DataFine: undefined,
        PkAgenti: undefined,
        Ricerca: undefined,
        Status: undefined,
      },
    };
  },
  created() {
    this.fetchFatture();
    this.fetchAgentsSelect();
  },

  methods: {
    onRicerca() {
      try {
        //const $ = await this;
        const $ = this;
        $.disabledPagination = true;
        $.pageNumber = 1;
        $.pageSize = 10;
        $.loadingRicerca = true;
        if ($.dateSearch != null) {
          if ($.dateSearch[0] != null) {
            $.ordersSearch.DataInizio = moment
              .utc($.dateSearch[0])
              .local()
              .format("YYYY-MM-DD[T]00:00:00");
          } else {
            $.ordersSearch.DataInizio = undefined;
          }
          if ($.dateSearch[1] != null) {
            $.ordersSearch.DataFine = moment
              .utc($.dateSearch[1])
              .local()
              .format("YYYY-MM-DD[T]00:00:00");
          } else {
            $.ordersSearch.DataFine = undefined;
          }
        } else {
          $.ordersSearch.DataInizio = undefined;
          $.ordersSearch.DataFine = undefined;
        }
        AdminFetchFatture(
          $.pageNumber,
          $.pageSize,
          $.ordersSearch.DataInizio,
          $.ordersSearch.DataFine,
          $.ordersSearch.PkAgenti
        )
          .then((response) => {
            $.fatture = response.data;
            $.pager = JSON.parse(response.headers["x-pagination"]);
            $.fatture = $.initTable($.fatture);
          })
          .catch((error) =>
            window.alert("Attenzione, si è verificato un errore " + error)
          )
          .finally(() => {
            $.loadingRicerca = false;
            $.disabledPagination = false;
          });
      } catch (e) {
        this.loadingTotals = false;
        this.loadingRicerca = false;
        this.disabledPagination = false;
        window.alert("Attenzione, si è verificato un errore " + e);
      } finally {
        this.loadingTotals = false;
        this.loadingRicerca = false;
        this.disabledPagination = false;
      }
    },
    initTable(data) {
      data.forEach((element) => {
        element.dataOra = moment(element.dataOra).format(
          "DD/MM/YYYY"
        );
      });
      return data;
    },
    fetchAgentsSelect() {
      this.loadingAgents = true;
      FetchAgentsSelect()
        .then((response) => {
          this.agentsSelect = response.data;
        })
        .catch((error) =>
          window.alert("Attenzione, si è verificato un errore " + error)
        )
        .finally(() => {
          this.loadingAgents = false;
        });
    },
    fetchFatture() {
      this.disabledPagination = true;
      this.loading = true;
      AdminFetchFatture(
        this.pageNumber,
        this.pageSize,
        this.ordersSearch.DataInizio,
        this.ordersSearch.DataFine,
        this.ordersSearch.PkAgenti
      )
        .then((response) => {
          this.fatture = this.initTable(response.data);
          this.pager = JSON.parse(response.headers["x-pagination"]);
        })
        .catch((error) =>
          window.alert("Attenzione, si è verificato un errore " + error)
        )
        .finally(() => {
          this.loading = false;
          this.disabledPagination = false;
        });
    },
  },
};
</script>

<style>
</style>
<template>
  <slot :id="id" :numeroFattura="numeroFattura"></slot>

  <el-dropdown
    split-button
    type="primary"
    size="mini"
    @click="visualizzaFattura"
  >
    Visualizza
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item @click="downloadFattura"> Download </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
  <fattura
    :fattura="fattura"
    :provvigioni="provvigioni"
    :riporto="riporto"
    :totale="totale"
    :deduzione="deduzione"
    :imponibile="imponibile"
    :ritenutaFiscale="ritenutaFiscale"
    :totaleDovuto="totaleDovuto"
    :provvigioniTableData="provvigioniTableData"
    :pagine="pagine"
    :marginTop="marginTop"
    :totaleIVA="totaleIVA"
    :nomeFattura="numeroFattura"
    :showLayout="false"
    :floatLayout="true"
    ref="fatturaPdfDownloadDiretto"
  ></fattura>
  <el-dialog v-model="dialogVisible" width="1300px" top="4vh">
    <el-button
      @click="onClickDownload"
      type="success"
      :loading="loadingDatiFiscali && loadingProvvigioni"
      >Download</el-button
    >
    <div class="container-fluid mt-2">
      <el-row justify="center">
        <el-col :xs="24" :sm="22" :md="18" :lg="15">
          <el-card
            class="fattura"
            v-loading="loadingDatiFiscali && loadingProvvigioni"
            ><el-scrollbar height="800px">
              <fattura
                :fattura="fattura"
                :provvigioni="provvigioni"
                :riporto="riporto"
                :totale="totale"
                :deduzione="deduzione"
                :imponibile="imponibile"
                :ritenutaFiscale="ritenutaFiscale"
                :totaleDovuto="totaleDovuto"
                :provvigioniTableData="provvigioniTableData"
                :pagine="pagine"
                :marginTop="marginTop"
                :totaleIVA="totaleIVA"
                :nomeFattura="numeroFattura"
                :showLayout="showLayout"
                :floatLayout="floatLayout"
                ref="fatturaPdfDownloadDopoVisualizza"
              ></fattura
            ></el-scrollbar>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </el-dialog>
</template>

<script>
import moment from "moment";
import {
  AdminFetchFatturaStampare,
  AdminFetchFatturaProvvigioniStampare,
} from "../../../api/fetchData";
import Fattura from "../../Fatturazione/User/Fattura.vue";
export default {
  components: { Fattura },
  props: ["id","numeroFattura"],
  data() {
    return {
      fattura: [],
      dialogVisible: false,
      provvigioni: null,
      riporto: null,
      totale: null,
      deduzione: null,
      imponibile: null,
      ritenutaFiscale: null,
      totaleDovuto: null,
      totaleIVA: null,
      provvigioniTableData: [],
      pagine: 0,
      marginTop: [],
      tableData: [],
      loadingDatiFiscali: false,
      loadingProvvigioni: false,
      showLayout: null,
      floatLayout: null,
    };
  },

  methods: {
    onClickDownload() {
      this.$refs.fatturaPdfDownloadDopoVisualizza.onClickDownload();
    },

    async fetchDatiFiscaliFattura() {
      const response = await AdminFetchFatturaStampare(this.id);
      return response.data;
    },

    async fetchProvvigioniFattura() {
      const response = await AdminFetchFatturaProvvigioniStampare(this.id);

      return response.data;
    },

    downloadFattura() {
      Promise.all([
        this.fetchDatiFiscaliFattura(),
        this.fetchProvvigioniFattura(),
      ])
        .then((response) => {
          this.fattura = response[0].fattura;
          this.fattura.dataOra = moment(this.fattura.dataOra).format(
            "DD/MM/YYYY"
          );
          if (response[0].fattureRighe.Provvigioni != undefined) {
            this.provvigioni = response[0].fattureRighe.Provvigioni;
            this.provvigioni.prezzo = this.provvigioni.prezzo.toFixed(2);
          }
          if (response[0].fattureRighe.Riporto != undefined) {
            this.riporto = response[0].fattureRighe.Riporto;
          }
          if (response[0].fattureRighe.Totale != undefined) {
            this.totale = response[0].fattureRighe.Totale;
          }
          if (response[0].fattureRighe.Deduzione != undefined) {
            this.deduzione = response[0].fattureRighe.Deduzione;
          }
          if (response[0].fattureRighe.Imponibile != undefined) {
            this.imponibile = response[0].fattureRighe.Imponibile;
          }
          if (response[0].fattureRighe.RitenutaFiscale != undefined) {
            this.ritenutaFiscale = response[0].fattureRighe.RitenutaFiscale;
          }
          if (response[0].fattureRighe.TotaleDovuto != undefined) {
            this.totaleDovuto = response[0].fattureRighe.TotaleDovuto;
          }
          if (response[0].fattureRighe.TotaleIVA != undefined) {
            this.totaleIVA = response[0].fattureRighe.TotaleIVA;
          }

          this.tableData = response[1];
          this.pagine = Math.ceil(this.tableData.length / 28);

          while (this.tableData.length > 0) {
            if (this.tableData.length >= 28) {
              let a = [];
              for (let i = 0; i < 28; i++) {
                a[i] = this.tableData.shift();
              }
              this.provvigioniTableData.push(a);
            } else {
              let b = [];
              let length = this.tableData.length;
              for (let i = 0; i < length; i++) {
                b[i] = this.tableData.shift();
              }
              this.provvigioniTableData.push(b);
            }
          }
          let index = 0;
          this.provvigioniTableData.forEach((element) => {
            let number = (28 - Number(element.length)) * 30 + 35;
            this.marginTop[index] = "margin-top:" + `${number}` + "px;";
            index++;
          });

          this.$refs.fatturaPdfDownloadDiretto.onClickDownload();
        })
        .catch((error) =>
          window.alert("Attenzione, si è verificato un errore " + error)
        );
    },
    visualizzaFattura() {
      this.dialogVisible = true;
      this.loadingDatiFiscali = true;
      this.loadingProvvigioni = true;
      this.floatLayout = false;
      this.showLayout = false;

      AdminFetchFatturaStampare(this.id)
        .then((response) => {
          this.fattura = response.data.fattura;
          this.fattura.dataOra = moment(this.fattura.dataOra).format(
            "DD/MM/YYYY"
          );
          if (response.data.fattureRighe.Provvigioni != undefined) {
            this.provvigioni = response.data.fattureRighe.Provvigioni;
            this.provvigioni.prezzo = this.provvigioni.prezzo.toFixed(2);
          }
          if (response.data.fattureRighe.Riporto != undefined) {
            this.riporto = response.data.fattureRighe.Riporto;
          }
          if (response.data.fattureRighe.Totale != undefined) {
            this.totale = response.data.fattureRighe.Totale;
          }
          if (response.data.fattureRighe.Deduzione != undefined) {
            this.deduzione = response.data.fattureRighe.Deduzione;
          }
          if (response.data.fattureRighe.Imponibile != undefined) {
            this.imponibile = response.data.fattureRighe.Imponibile;
          }
          if (response.data.fattureRighe.RitenutaFiscale != undefined) {
            this.ritenutaFiscale = response.data.fattureRighe.RitenutaFiscale;
          }
          if (response.data.fattureRighe.TotaleDovuto != undefined) {
            this.totaleDovuto = response.data.fattureRighe.TotaleDovuto;
          }
          if (response.data.fattureRighe.TotaleIVA != undefined) {
            this.totaleIVA = response.data.fattureRighe.TotaleIVA;
          }
        })
        .catch((error) =>
          window.alert("Attenzione, si è verificato un errore " + error)
        )
        .finally(() => {
          this.loadingDatiFiscali = false;
        });

      AdminFetchFatturaProvvigioniStampare(this.id)
        .then((response) => {
          this.tableData = response.data;
          this.pagine = Math.ceil(this.tableData.length / 28);

          while (this.tableData.length > 0) {
            if (this.tableData.length >= 28) {
              let a = [];
              for (let i = 0; i < 28; i++) {
                a[i] = this.tableData.shift();
              }
              this.provvigioniTableData.push(a);
            } else {
              let b = [];
              let length = this.tableData.length;
              for (let i = 0; i < length; i++) {
                b[i] = this.tableData.shift();
              }
              this.provvigioniTableData.push(b);
            }
          }
          let index = 0;
          this.provvigioniTableData.forEach((element) => {
            let number = (28 - Number(element.length)) * 30 + 35;
            this.marginTop[index] = "margin-top:" + `${number}` + "px;";
            index++;
          });
        })
        .catch((error) =>
          window.alert("Attenzione, si è verificato un errore " + error)
        )
        .finally(() => {
          this.loadingProvvigioni = false;
        });
    },
  },
};
</script>

<style>
.el-dropdown {
  vertical-align: top;
}
.el-dropdown + .el-dropdown {
  margin-left: 15px;
}
.el-icon-arrow-down {
  font-size: 11px;
}

.fattura {
  height: 900px;
  width: 800px;
}
</style>
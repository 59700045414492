<template>
  <slot :id="id" :DataMese="DataMese"></slot>
  <el-popconfirm
    title="Sei sicuro di voler contabilizzare le provvigioni?"
    @confirm="contabilizzareProvvigione"
    confirmButtonText="Si"
    cancelButtonText="No"
  >
    <template #reference>
      <el-button
        type="primary"
        icon="el-icon-coin"
        size="mini"
        :loading="loading"
      >
        Contabilizza
      </el-button>
    </template>
  </el-popconfirm>
</template>

<script>
import { RequestContabilizzareProvvigione } from "@/api/modifyData.js";
export default {
    emits:["contabilizzareSuccess",],
  props: ["id","DataMese"],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async contabilizzareProvvigione() {
      this.loading = true;
       let data = {Datamese:this.DataMese}
      await RequestContabilizzareProvvigione(this.id, data)
        .then(() => {
          alert("Provvigioni contabilizzate con successo.");
          this.$emit('contabilizzareSuccess')
        })
        .catch(() => {
          alert("Errore nella contabilizzazione delle provvigioni");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
</style>
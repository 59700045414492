<template>
	<el-button type="primary" size="medium" @click="handleDialog">
		Esporta in Excel
	</el-button>

	<el-dialog v-model="dialogVisible" :before-close="handleClose" center>
		<template #title>
			<span class="fw-bold"></span>
		</template>

		<el-form show-message @submit.prevent="handleDownload">
			<el-form-item label="Nome File" :error="filenameField.errorMessage">
				<el-input
					:model-value="filenameField.value"
					type="text"
					@update:model-value="filenameField.handleChange"
					@change="formModified = true"
				/>
			</el-form-item>
		</el-form>
		<template #footer>
			<el-button type="info" @click="dialogVisible = false"> Chiudi </el-button>
			<el-button type="primary" @click="handleDownload" :loading="loading">
				Salva
			</el-button>
		</template>
	</el-dialog>
</template>

<script>
import moment from 'moment';
import { useField, useForm } from 'vee-validate';
import { reactive } from 'vue';
import * as yup from 'yup';
export default {
	emits: ['export2excel'],
	setup() {
		const { meta: formMeta, handleSubmit, resetForm, handleReset } = useForm();
		const filenameField = reactive(
			useField('filename', yup.string().required('Campo richiesto'))
		);
		return {
			formMeta,
			handleSubmit,
			resetForm,
			handleReset,
			filenameField,
		};
	},
	props: {
		filename: {
			type: String,
			required: true,
		},
		bookType: {
			type: String,
			default: 'xlsx',
		},
		headers: {
			required: true,
			type: Array,
		},
		filterVal: {
			required: true,
			type: Array,
		},
	},
	data() {
		return {
			loading: false,
			dialogVisible: false,
			handleDownload: this.handleSubmit(this.onClickDownload),
		};
	},
	methods: {
		handleDialog() {
			this.dialogVisible = true;
			this.loading = false;
			this.resetForm();
			this.filenameField.value = this.filename + moment().format('YYYYMMDD');
		},
		onClickDownload() {
			this.loading = true;
			this.$emit('export2excel');
		},
		handleClose(done) {
			if (this.formModified == true) {
				this.$confirm('Are you sure to close this dialog?')
					.then(() => {
						done;
						(this.dialogVisible = false), (this.formModified = false);
					})
					.catch(() => {});
			} else {
				this.dialogVisible = false;
			}
		},
		Export2Excel(tableData) {
			this.loading = true;
			try {
				import('@/utils/export2Excel').then((excel) => {
					const tHeader = this.headers;
					const filterVal = this.filterVal;
					const list = tableData;
					const data = this.formatJson(filterVal, list);
					excel.export_json_to_excel({
						header: tHeader,
						data,
						filename: this.filenameField.value,
						autoWidth: true,
						bookType: this.bookType,
					});
				});
			} catch {
				alert('Errore durante il download dei dati');
			} finally {
				this.loading = false;
				this.dialogVisible = false;
			}
		},
		formatJson(filterVal, jsonData) {
			return jsonData.map((v) =>
				filterVal.map((j) => {
					return v[j];
				})
			);
		},
	},
};
</script>

<style></style>

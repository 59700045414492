<template>
  <slot :id="id" :agents="agents"></slot>
  <el-button type="primary" size="mini" @click="handleUpdateCustomerOrder">
    Modifica
  </el-button>
  <el-dialog
    v-model="dialogFormUpdateVisible"
    :before-close="handleClose"
    center
    append-to-body
  >
    <template #title>
      <span class="fw-bold">Modifica Ordine</span>
    </template>
    <div
      :class="[
        'alert',
        'text-center',
        submitResult ? 'alert-success' : 'alert-danger',
      ]"
      v-if="isSubmitted"
    >
      {{ submitResult ? axiosSuccessMessage : axiosErrorMessage }}
    </div>
    <el-form show-message label-position="left" label-width="190px">
      <el-form-item label="ID Ordine" :error="orderIDField.errorMessage">
        <el-input
          :disabled="
            statoOrdine == 'Calcolato'
              ? true
              : statoOrdine == 'Pagato'
              ? true
              : false
          "
          :model-value="orderIDField.value"
          type="number"
          @update:model-value="orderIDField.handleChange"
          @change="formModified = true"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="Agente Sponsor" :error="agenteField.errorMessage">
        <el-button
          :disabled="
            statoOrdine == 'Calcolato'
              ? false
              : statoOrdine == 'Pagato'
              ? false
              : false
          "
          type="primary"
          class="me-3"
          @click="handleAgenteDialogVisible"
          >Modifica</el-button
        >
        <span class="ms-2" v-if="(agenteSponsorCodice != null) && (agenteSponsorCodice != 0)"
          >{{ agenteField.value }} ({{ agenteSponsorCodice }})</span
        >
        <span class="ms-2" v-else>Selezionare un agente</span>
      </el-form-item>
      <el-form-item label="Sponsor" :error="sponsorField.errorMessage">
        {{ sponsorField.value }}
      </el-form-item>
      <el-form-item label="Referral ID" :error="referralIDField.errorMessage">
        {{ referralIDField.value }}
      </el-form-item>
      <el-form-item label="Cliente" :error="customerField.errorMessage">
        <el-input
          :disabled="
            statoOrdine == 'Calcolato'
              ? true
              : statoOrdine == 'Pagato'
              ? true
              : false
          "
          :model-value="customerField.value"
          type="text"
          @update:model-value="customerField.handleChange"
          @change="formModified = true"
        >
        </el-input>
      </el-form-item>
      <el-form-item
        label="Email Cliente"
        :error="billingEmailField.errorMessage"
      >
        <el-input
          :model-value="billingEmailField.value"
          type="text"
          @update:model-value="billingEmailField.handleChange"
          @change="formModified = true"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="Stato" :error="statusField.errorMessage">
        <el-select
          :model-value="statusField.value"
          :disabled="
            statoOrdine == 'Calcolato'
              ? true
              : statoOrdine == 'Pagato'
              ? true
              : false
          "
          @update:model-value="statusField.handleChange"
          @change="formModified = true"
        >
          <el-option
            v-for="item in status"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Totale" :error="totalField.errorMessage">
        <el-input
          :disabled="
            statoOrdine == 'Calcolato'
              ? true
              : statoOrdine == 'Pagato'
              ? true
              : false
          "
          :model-value="totalField.value"
          type="number"
          @update:model-value="totalField.handleChange"
          @change="formModified = true"
          @keyup="calcoloTotali"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="IVA" :error="totalTaxField.errorMessage">
        <el-input
          :disabled="
            statoOrdine == 'Calcolato'
              ? true
              : statoOrdine == 'Pagato'
              ? true
              : false
          "
          :model-value="totalTaxField.value"
          type="number"
          @update:model-value="totalTaxField.handleChange"
          @change="formModified = true"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="Coupon" :error="discountTotalField.errorMessage">
        <el-input
          :disabled="
            statoOrdine == 'Calcolato'
              ? true
              : statoOrdine == 'Pagato'
              ? true
              : false
          "
          :model-value="discountTotalField.value"
          type="number"
          @update:model-value="discountTotalField.handleChange"
          @change="formModified = true"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="IVA Coupon" :error="discountTaxField.errorMessage">
        <el-input
          :disabled="
            statoOrdine == 'Calcolato'
              ? true
              : statoOrdine == 'Pagato'
              ? true
              : false
          "
          :model-value="discountTaxField.value"
          type="number"
          @update:model-value="discountTaxField.handleChange"
          @change="formModified = true"
        >
        </el-input>
      </el-form-item>
      <el-form-item
        label="Spese di Spedizione"
        :error="shippingTotalField.errorMessage"
      >
        <el-input
          :disabled="
            statoOrdine == 'Calcolato'
              ? true
              : statoOrdine == 'Pagato'
              ? true
              : false
          "
          :model-value="shippingTotalField.value"
          type="number"
          @update:model-value="shippingTotalField.handleChange"
          @change="formModified = true"
          @keyup="calcoloTotali"
        >
        </el-input>
      </el-form-item>
      <el-form-item
        label="IVA Spese di Spedizione"
        :error="shippingTaxField.errorMessage"
      >
        <el-input
          :disabled="
            statoOrdine == 'Calcolato'
              ? true
              : statoOrdine == 'Pagato'
              ? true
              : false
          "
          :model-value="shippingTaxField.value"
          type="number"
          @update:model-value="shippingTaxField.handleChange"
          @change="formModified = true"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="Gift Card" :error="giftCardField.errorMessage">
        <el-input
          :disabled="
            statoOrdine == 'Calcolato'
              ? true
              : statoOrdine == 'Pagato'
              ? true
              : false
          "
          :model-value="giftCardField.value"
          type="number"
          @update:model-value="giftCardField.handleChange"
          @change="formModified = true"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="Gift Card ID" :error="giftCardIdField.errorMessage">
        <el-input
          :disabled="
            statoOrdine == 'Calcolato'
              ? true
              : statoOrdine == 'Pagato'
              ? true
              : false
          "
          :model-value="giftCardIdField.value"
          type="text"
          @update:model-value="giftCardIdField.handleChange"
          @change="formModified = true"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="info" @click="handleClose"> Chiudi </el-button>
        <el-button
          type="primary"
          @click="submitUpdate"
          :disabled="saveButtonDisabled"
          :loading="loadingUpdate"
          v-if="!submitResult"
        >
          Salva
        </el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog center v-model="agenteSponsorDialog" width="600px" append-to-body>
    <template #title>
      <span class="fw-bold">Seleziona un Agente</span>
    </template>
    <div class="row">
      <div class="col text-center">
        <el-select
          v-model="agenteSponsor"
          placeholder="Seleziona Agente"
          filterable
          size="medium"
          class="ms-3"
          style="width: 260px"
          ><el-option
            v-for="item in agents"
            :key="item.pkAgente"
            :value="
              item.pkAgente +
              '&' +
              item.nome +
              ' ' +
              item.cognome +
              '&' +
              item.codice
            "
            :label="item.nome + ' ' + item.cognome + ' (' + item.codice + ')'"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <template #footer>
      <el-button type="info" @click="agenteSponsorDialog = false"> Indietro </el-button>
      <el-button type="primary" @click="onAgenteSponsorChange">
        Conferma
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import { UpdateCustomerOrder } from "@/api/modifyData.js";
import { Read } from "@/api/fetchData.js";
import { useField, useForm } from "vee-validate";
import { reactive } from "vue";
import * as yup from "yup";
export default {
  emits: ["updateSuccess"],
  props: ["id", "agents"],
  setup() {
    const { meta: formMeta, handleSubmit, resetForm, handleReset } = useForm();
    const giftCardIdField = reactive(useField("giftCardId"));
    const customerField = reactive(useField("customer"));
    const giftCardField = reactive(useField("giftCard"));
    const agenteField = reactive(useField("agente"));
    const sponsorField = reactive(useField("sponsor",yup.string().nullable()));
    const currencyField = reactive(useField("currency"));
    const versionField = reactive(useField("version"));
    const customerOrderField = reactive(useField("pkCustomerOrder"));
    const parentIDField = reactive(useField("parentID"));
    const orderCreatedField = reactive(useField("orderCreated"));
    const statusField = reactive(
      useField("status", yup.string().required("Campo richiesto"))
    );
    const orderIDField = reactive(useField("orderID", yup.string().nullable()));
    const referralIDField = reactive(
      useField("referralID", yup.string().nullable())
    );
    const billingEmailField = reactive(
      useField(
        "billingEmail",
        yup
          .string()
          .required("Campo richiesto")
          .email("inserire un'email valida")
      )
    );
    const totalField = reactive(
      useField("total", yup.string().required("Campo richiesto"))
    );
    const totalTaxField = reactive(
      useField("totalTax", yup.string().required("Campo richiesto"))
    );
    const discountTotalField = reactive(
      useField("discountTotal", yup.string().required("Campo richiesto"))
    );
    const discountTaxField = reactive(
      useField("discountTax", yup.string().required("Campo richiesto"))
    );
    const shippingTotalField = reactive(
      useField("shippingTotal", yup.string().required("Campo richiesto"))
    );
    const shippingTaxField = reactive(
      useField("shippingTax", yup.string().required("Campo richiesto"))
    );

    return {
      giftCardIdField,
      giftCardField,
      customerField,
      agenteField,
      sponsorField,
      versionField,
      currencyField,
      statusField,
      customerOrderField,
      parentIDField,
      orderCreatedField,
      orderIDField,
      referralIDField,
      handleSubmit,
      formMeta,
      billingEmailField,
      resetForm,
      handleReset,
      totalField,
      totalTaxField,
      discountTotalField,
      discountTaxField,
      shippingTotalField,
      shippingTaxField,
    };
  },
  data() {
    return {
      agenteSponsorCodice: "",
      isSubmitted: false,
      submitResult: false,
      dialogFormUpdateVisible: false,
      axiosSuccessMessage: "",
      axiosErrorMessage: "",
      submitUpdate: this.handleSubmit(this.onSubmitUpdate),
      formModified: false,
      agenteSponsorDialog: false,
      agenteSponsor: "",
      order: [],
      statoOrdine: "",
      saveButtonDisabled: false,
      loadingUpdate: false,
      status: [
        {
          value: "Creato",
          label: "Creato",
        },
        {
          value: "Pagato",
          label: "Pagato",
        },
        /* {
          value: "Calcolato",
          label: "Calcolato",
        },*/
      ],
    };
  },
  methods: {
    calcoloTotali() {
      this.formModified = true;
      if (
        this.totalField.value != undefined &&
        this.shippingTotalField.value != undefined
      ) {
        this.totalTaxField.value =
          this.totalField.value -
          this.shippingTotalField.value -
          (this.totalField.value - this.shippingTotalField.value) / 1.22;
        this.totalTaxField.value = this.totalTaxField.value.toFixed(2);
      }
    },
    calcoloCoupon() {
      if (this.discountTotalField.value != undefined) {
        this.discountTaxField.value =
          this.discountTotalField.value - this.discountTotalField.value / 1.22;
        this.discountTaxField.value = this.discountTaxField.value.toFixed(2);
        this.discountTotalField.value =
          this.discountTotalField.value - this.discountTaxField.value;
        this.discountTotalField.value =
          this.discountTotalField.value.toFixed(2);
      }
    },
    onAgenteSponsorChange() {
      let referral = this.agenteSponsor.split("&");
      this.parentIDField.value = referral[0];
      this.agenteField.value = referral[1];
      this.agenteSponsorCodice = referral[2];
      this.agenteSponsorDialog = false;
    },
    handleReferralClose() {
      this.agenteSponsorDialog = false;
      this.formModified = true;
    },
    handleAgenteDialogVisible() {
      this.agenteSponsorDialog = true;
      this.agenteSponsor = "";
    },

    handleClose(done) {
      if (this.formModified == true) {
        this.$confirm("Are you sure to close this dialog?")
          .then(() => {
            done;
            this.dialogFormUpdateVisible = false;
            this.dialogFormCreateVisible = false;
            this.formModified = false;
          })
          .catch(() => {});
      } else {
        this.dialogFormUpdateVisible = false;
        this.dialogFormCreateVisible = false;
      }
    },
    initForm() {
      this.isSubmitted = false;
      this.agenteSponsorCodice = this.order.agenteCodice;
      this.giftCardField.value = this.order.giftCard;
      this.giftCardIdField.value = this.order.giftCardId;
      this.customerField.value = this.order.customer;
      this.agenteField.value = this.order.agente;
      this.sponsorField.value = this.order.sponsor;
      this.customerOrderField.value = this.order.pkCustomerOrder;
      this.parentIDField.value = this.order.parentID;
      this.orderCreatedField.value = this.order.orderCreated;
      this.orderIDField.value = this.order.orderID;
      this.referralIDField.value = this.order.referralID;
      this.billingEmailField.value = this.order.billingEmail;
      this.statusField.value = this.order.status;
      this.currencyField.value = this.order.currency;
      this.versionField.value = this.order.version;
      this.totalField.value = this.order.total;
      this.totalTaxField.value = this.order.totalTax;
      this.discountTotalField.value = this.order.discountTotal;
      this.discountTaxField.value = this.order.discountTax;
      this.shippingTotalField.value = this.order.shippingTotal;
      this.shippingTaxField.value = this.order.shippingTax;
    },

    async handleUpdateCustomerOrder() {
      this.isSubmitted = false;
      this.submitResult = false;
      this.resetForm();
      await Read(this.id)
        .then((response) => {
          this.agenteSponsorNome = "";
          this.order = response.data;
          this.statoOrdine = response.data.status;
          this.dialogFormUpdateVisible = true;
          this.initForm();
        })
        .catch((error) =>
          window.alert("Attenzione, si è verificato un errore " + error)
        );
      ///.finally(()=> ());
    },
    async onSubmitUpdate(formValues) {
      this.loadingUpdate = true;
      let id = this.customerOrderField.value;
      this.isSubmitted = false;
      this.saveButtonDisabled = true;
      await UpdateCustomerOrder(id, formValues)
        .then(() => {
          this.isSubmitted = true;
          this.submitResult = true;
          this.axiosSuccessMessage = "Dati aggiornati con successo";
          this.formModified = false;
          this.$emit("updateSuccess");
        })
        .catch((error) => {
          this.isSubmitted = true;
          this.submitResult = false;
          this.axiosErrorMessage = "Aggiornamento fallito";
          if (error != undefined) {
            this.checkErrorInputForm(error);
          }
        })
        .finally(() => {
          this.saveButtonDisabled = false;
          this.loadingUpdate = false;
        });
    },
    checkErrorInputForm(error) {
      if (
        error.response != undefined &&
        error.response.data != undefined &&
        error.response.data.detail != undefined
      ) {
        this.axiosErrorMessage += " : " + error.response.data.detail;
      }
      if (
        error.response != undefined &&
        error.response.data != undefined &&
        error.response.errors != undefined
      ) {
        let keys = Object.keys(error.response.data.errors);
        if (keys.indexOf("OrderID") !== -1) {
          this.orderIDField.errors.push(error.response.data.errors.OrderID[0]);
        }
        if (keys.indexOf("agenteSponsor") !== -1) {
          this.agenteSponsorField.errors.push(
            error.response.data.errors.agenteSponsor[0]
          );
        }
        if (keys.indexOf("BillinEmail") !== -1) {
          this.billinEmailField.errors.push(
            error.response.data.errors.BillinEmail[0]
          );
        }
        if (keys.indexOf("Status") !== -1) {
          this.statusField.errors.push(error.response.data.errors.Status[0]);
        }
        if (keys.indexOf("Currency") !== -1) {
          this.currencyField.errors.push(
            error.response.data.errors.Currency[0]
          );
        }
        if (keys.indexOf("Version") !== -1) {
          this.versionField.errors.push(error.response.data.errors.Version[0]);
        }
        if (keys.indexOf("Total") !== -1) {
          this.totalField.errors.push(error.response.data.errors.Total[0]);
        }
        if (keys.indexOf("TotalTax") !== -1) {
          this.totalTaxField.errors.push(
            error.response.data.errors.TotalTax[0]
          );
        }
        if (keys.indexOf("DiscountTotal") !== -1) {
          this.discountTotalField.errors.push(
            error.response.data.errors.DiscountTotal[0]
          );
        }
        if (keys.indexOf("DiscountTax") !== -1) {
          this.discountTaxField.errors.push(
            error.response.data.errors.DiscountTax[0]
          );
        }
        if (keys.indexOf("ShippingTotal") !== -1) {
          this.shippingTotalField.errors.push(
            error.response.data.errors.ShippingTotal[0]
          );
        }
        if (keys.indexOf("ShippingTax") !== -1) {
          this.shippingTaxField.errors.push(
            error.response.data.errors.ShippingTax[0]
          );
        }
      }
    },
  }, //end methods
};
</script>

<style>
</style>
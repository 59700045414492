<template>
    <div class="row justify-content-between">
        <div class="col ms-3 mb-3">
            <dialog-create :agents="ListaAgenti" @createSuccess="RefreshTabella"></dialog-create>
            <span class="ms-3"></span>
            <export-excel :searchParam="FormRicerca"></export-excel>
        </div>
    </div>
    <div class="row">
        <div class="col ms-3 mb-4">
            <el-date-picker v-model="FormRicerca.DataOra" type="daterange" size="medium" format="DD/MM/YYYY" placeholder="Seleziona" start-placeholder="Data iniziale" end-placeholder="Data finale" range-separator="al" clearable>
            </el-date-picker>

            <el-input v-model="FormRicerca.Ricerca" placeholder="Ricerca" size="medium" class="ms-3" style="width: 210px" />

            <el-select clearable v-model="FormRicerca.Stato" placeholder="Stato" multiple size="medium" class="ms-3" style="width: 260px">
                <el-option v-for="item in ListaStato" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>
            <el-select clearable :loading="selectAgentiIsLoading" v-model="FormRicerca.PkAgenti" placeholder="Seleziona Agente" filterable multiple size="medium" class="ms-3" style="width: 260px">
                <el-option v-for="item in ListaAgenti" :key="item.pkAgente" :value="item.pkAgente"
                           :label="item.nome + ' ' + item.cognome + ' (' + item.codice + ')'">
                </el-option>
            </el-select>

            <el-button size="medium" icon="el-icon-search" type="primary" class="ms-3" :loading="btnRicercaIsLoading" @click="onBtnRicercaClick()">Cerca</el-button>

        </div>
    </div>
    <el-table v-loading="IsLoading" :data="tableData" stripe fit highlight-current-row border>
        <el-table-column type="expand">
            <template #default="props">
                <div v-if="props.row.isElaborato">
                    <admin-provvigioni-table :id="props.row.pkCustomerOrder" :refresh="refresh" :currentID="currentID" @refreshCompleted="endRefresh">
                    </admin-provvigioni-table>

                </div>
                <div class="row mt-2">
                    <div class="col text-center">
                        <ricalcola :id="props.row.pkCustomerOrder" @ricalcolaSuccess="RefreshTabella(props.row.pkCustomerOrder)"></ricalcola>
                        <log :id="props.row.pkCustomerOrder"></log>
                    </div>
                </div>
            </template>
        </el-table-column>

        <el-table-column align="center" width="50px">
            <template #default="props">
                <el-popover placement="bottom" :width="350" trigger="click" v-if="props.row.isElaborato">
                    <p>
                        <span class="fw-bold">Debug Info:</span> <pre>{{ props.row.debugInfo }}</pre>
                    </p>
                    <template #reference>
                        <el-button type="text">
                            <font-awesome-icon :icon="iconCalculator"></font-awesome-icon>
                        </el-button>
                    </template>
                </el-popover>

                <el-popover placement="bottom" :width="350" trigger="click" v-else>
                    <p>
                        <span class="fw-bold">Debug Info:</span> <pre>{{ props.row.debugInfo }}</pre>
                    </p>
                    <template #reference>
                        <el-button type="text">
                            <font-awesome-icon :icon="iconBug"></font-awesome-icon>
                        </el-button>
                    </template>
                </el-popover>
            </template>
        </el-table-column>
        <el-table-column label="Tipo Vendita" prop="tipoOrdine" align="center" width="110px" />
        <el-table-column label="Data Ordine" prop="orderCreated" align="center">
            <template v-slot="props">
                <span v-show="props.row.orderCreated!=null">
                    {{moment(props.row.orderCreated).format("DD/MM/YYYY")}} <br />
                    <small>{{moment(props.row.orderCreated).format("HH:mm:ss")}}</small>
                </span>
            </template>
        </el-table-column>

        <el-table-column label="ID Ordine" prop="orderID" align="center" width="100px" />
        <el-table-column label="Agente" prop="agente" align="center" />
        <el-table-column label="Cliente" align="center">
            <template v-slot="props">
                <el-popover placement="bottom" :width="350" trigger="click" v-if="props.row.customer!=null">
                    <p>
                        <span class="fw-bold">Email:</span> {{ props.row.billingEmail }}
                    </p>
                    <p><span class="fw-bold">Note del cliente:</span></p>
                    <br />
                    <p>{{ props.row.customerNote }}</p>
                    <template #reference>
                        <el-button type="text">
                            {{ props.row.customer }}<i class="el-icon-info ms-1"></i>
                        </el-button>
                    </template>
                </el-popover>
                <el-popover placement="bottom" :width="350" trigger="click" v-else>
                    <p><span class="fw-bold">Note del cliente:</span></p>
                    <br />
                    <p>{{ props.row.customerNote }}</p>
                    <template #reference>
                        <el-button type="text">
                            {{ props.row.billingEmail }}<i class="el-icon-info ms-1"></i>
                        </el-button>
                    </template>
                </el-popover>
            </template>
        </el-table-column>
        <el-table-column label="Pagato" prop="total" align="center" width="90px">
            <template v-slot="props">
                <el-popover placement="bottom" :width="350" trigger="click">
                    <p><span class="fw-bold">IVA:</span> {{ props.row.totalTax }}</p>
                    <p>
                        <span class="fw-bold">Coupon:</span> {{ props.row.discountTotal }}
                    </p>
                    <p>
                        <span class="fw-bold"> Spese di spedizione:</span>
                        {{ props.row.shippingTotal }}
                    </p>
                    <p>
                        <span class="fw-bold"> Licenza:</span>
                        {{ props.row.licenza }}
                    </p>
                    <p>
                        <span class="fw-bold"> Totale licenza:</span>
                        {{ props.row.licenzaTotal }}
                    </p>
                    <p>
                        <span class="fw-bold"> IVA licenza:</span>
                        {{ props.row.licenzaTax }}
                    </p>
                    <p>
                        <span class="fw-bold"> Gift Card:</span>
                        {{ props.row.giftCard }}
                    </p>
                    <p>
                        <span class="fw-bold"> Gift Card ID:</span>
                        {{ props.row.giftCardId }}
                    </p>
                    <template #reference>
                        <el-button type="text">
                            {{ props.row.total.toFixed(2) }}€<i class="el-icon-info ms-1"></i>
                        </el-button>
                    </template>
                </el-popover>
            </template>
        </el-table-column>
        <el-table-column label="Valore Fatturato" prop="totaleFatturabile" align="center" width="130">
            <template v-slot="props">
                {{props.row.totaleFatturabile.toFixed(2)}}€
            </template>
        </el-table-column>
        <el-table-column label="Valore Commissioni" prop="totaleCommissionabile" align="center" width="155">
            <template v-slot="props">
                {{props.row.totaleCommissionabile.toFixed(2)}}€
            </template>
        </el-table-column>
        <el-table-column label="Stato" prop="status" align="center" width="80" />
        <el-table-column label="Metodo di Pagamento" prop="paymentMethod" align="center" width="170" />
        <el-table-column label="Azioni" align="center" class-name=" fixed-width" width="120">
            <template v-slot="{ row }">
                <dialog-update :id="row.pkCustomerOrder" :agents="ListaAgenti" @updateSuccess="RefreshTabella"></dialog-update>
                <div class="mb-1"></div>
                <delete-order :id="row.pkCustomerOrder" @deleteSuccess="RefreshTabella">
                </delete-order>
            </template>
        </el-table-column>

    </el-table>
    <pagination v-show="Number(pager.TotalCount) > 0" :total="Number(pager.TotalCount)" v-model:current-page="currentPage" v-model:page-size="pageSize" @pagination="onChangePage" :disabled="disabledPagination" />
</template>
<script>
    import moment from 'moment';

    import ExportExcel from "./AdminExport2ExcelOrdini.vue";
    import Log from "./AdminLogProvvigioni.vue";
    import Ricalcola from "./AdminRicalcolaProvvigioni.vue";
    import DeleteOrder from "./DeleteOrder.vue";


    import { CustomerOrder, DatiComuni } from '@/api/fetchData.js';
    import Pagination from "@/components/Pagination";

    import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
    import { faCalculator, faBug } from "@fortawesome/free-solid-svg-icons";

    import DialogCreate from "./DialogCreate.vue";
    import DialogUpdate from "./DialogUpdate.vue";
    import AdminProvvigioniTable from "./AdminProvvigioniTable.vue";

    export default {

        components: {
            ExportExcel,

            Log,
            Ricalcola,
            DeleteOrder,

            Pagination,
            FontAwesomeIcon,

            DialogCreate,
            DialogUpdate,
            AdminProvvigioniTable,

        },

        data() {
            return {
                iconBug: faBug,
                iconCalculator: faCalculator,
                btnRicercaIsLoading: false,
                selectAgentiIsLoading: false,
                IsLoading: false,
                disabledPagination: false,
                ListaAgenti: [],
                tableData: [],
                ListaStato: [{ value: "Creato", label: "Ordini in attesa di pagamento" }
                    , { value: "Pagato", label: "Ordini pagati" }
                ],
                currentPage: 1,
                pageSize: 10,
                pkCustomerOrder: undefined,
                pager: {
                    TotalCount: 0,
                },
                FormRicerca: {
                    DataOra: undefined,
                    Ricerca: undefined,
                    PkAgenti: undefined,
                    Stato: undefined,

                }
            };
        },

        created() {

            this.SelectAgentiInit();

            if (this.$route.params.pkCustomerOrder != undefined) {
                this.pkCustomerOrder = this.$route.params.pkCustomerOrder;
            }

            this.RiempiTabella(this.pkCustomerOrder, this.FormRicerca.DataOra, this.FormRicerca.Ricerca, this.FormRicerca.PkAgenti, this.FormRicerca.Stato);

        },


        methods: {

            moment: moment,

            startLoading() {
                this.disabledPagination = true;
                this.IsLoading = true;
                this.tableData = [];

            },

            loadingDone() {
                this.disabledPagination = false;
                this.IsLoading = false;
                this.btnRicercaIsLoading = false;
            },

            RefreshTabella() {
                this.RiempiTabella(this.pkCustomerOrder, this.FormRicerca.DataOra, this.FormRicerca.Ricerca, this.FormRicerca.PkAgenti, this.FormRicerca.Stato);
            },

            RiempiTabella(pkCustomerOrder, dataOra, ricerca, pkAgenti, stato) {

                this.startLoading();

                let DataInizio = undefined;
                let DataFine = undefined;

                if ((dataOra != null) && (dataOra[0] != null)) {
                    DataInizio = moment.utc(dataOra[0]).local().format("YYYY-MM-DD[T]00:00:00");
                }
                if ((dataOra != null) && (dataOra[1] != null)) {
                    DataFine = moment.utc(dataOra[1]).local().format("YYYY-MM-DD[T]00:00:00");
                }

                let c = new CustomerOrder(this.currentPage, this.pageSize);
                let result = c.Search(pkCustomerOrder, DataInizio, DataFine, ricerca, pkAgenti, stato);

                result.then((response) => {

                    this.tableData = response.data;
                    this.pager = JSON.parse(response.headers["x-pagination"]);

                }).catch((error) =>
                    window.alert("Attenzione, si è verificato un errore " + error)
                ).finally(() => {
                    this.loadingDone();
                });


            },

            SelectAgentiInit() {
                this.selectAgentiIsLoading = true;
                let a = new DatiComuni();
                let result = a.getAgenti();
                result.then((response) => {
                    this.ListaAgenti = response.data;
                }).catch((error) =>
                    window.alert("Attenzione, si è verificato un errore " + error)
                ).finally(() => {
                    this.selectAgentiIsLoading = false;
                });
            },

            onDlgAgentiCreateSuccess() {

            },            

            onChangePage() {

                this.RiempiTabella(this.pkCustomerOrder, this.FormRicerca.DataOra, this.FormRicerca.Ricerca, this.FormRicerca.PkAgenti, this.FormRicerca.Stato);

            },

            onBtnRicercaClick() {

                this.currentPage = 1;
                this.btnRicercaIsLoading = true;

                this.RiempiTabella(this.pkCustomerOrder, this.FormRicerca.DataOra, this.FormRicerca.Ricerca, this.FormRicerca.PkAgenti, this.FormRicerca.Stato);



            }

        },
    };
</script>

<style scoped>
</style>

<template>
	<slot :id="id"></slot>
	<el-tabs v-model="activeName">
		<el-tab-pane label="Licenze" name="1"
			><licenze-agente :id="id"></licenze-agente
		></el-tab-pane>
		<el-tab-pane label="Qualifica" name="2"
			><qualifiche-agente :id="id"></qualifiche-agente
		></el-tab-pane>
		<el-tab-pane label="Dashboard" name="3"
			><dashboard-agente :id="id"></dashboard-agente
		></el-tab-pane>
		<!--	<el-tab-pane label="Agente" name="4"
			><agente :id="id"></agente>
		</el-tab-pane>
		<el-tab-pane label="Residenza" name="5"
			><Address :id="id"></Address>
		</el-tab-pane>
		<el-tab-pane label="Conto Corrente " name="6"
			><bank-account :id="id"></bank-account>
		</el-tab-pane>
		<el-tab-pane label="Dati Fiscali" name="7"
			><tax-options :id="id"></tax-options>
		</el-tab-pane>
		<el-tab-pane label="Contatti" name="8"
			><contacts :id="id"></contacts>
		</el-tab-pane>-->
	</el-tabs>
</template>

<script>
//import Agente from './AdminAgente.vue';
//import Address from './AdminAgenteAddress.vue';
//import BankAccount from './AdminAgenteBankAccount.vue';
//import Contacts from './AdminAgenteContacts.vue';
//import TaxOptions from './AdminAgenteTaxOptions.vue';
import DashboardAgente from './DashboardAgente.vue';
import LicenzeAgente from './LicenzeAgente.vue';
import QualificheAgente from './QualificheAgente.vue';
export default {
	props: ['id'],
	components: {
		LicenzeAgente,
		QualificheAgente,
		DashboardAgente,
		/*Agente,
		Address,
		BankAccount,
		Contacts,
		TaxOptions,*/
	},
	data() {
		return {
			activeName: '1',
		};
	},
};
</script>

<style>
</style>

<template>
  <el-card class="box-card" shadow="never" v-loading="loadingCard">
    <template #header>
      <p class="text-center fs-3 fw-bold">Dati Fiscali</p>
    </template>
    <div v-if="fetchTaxOptionsError == true">
      <div class="text-center">
        <i class="el-icon-warning-outline"></i>
        <h2 class="warning-title fw-bold">Ooops...</h2>
        <p class="warning-description h4">Qualcosa è andato storto, Riprova!</p>
      </div>

      <div class="d-grid gap-2 col-3 mx-auto">
        <el-button type="danger" @click="tryAgain">Riprova</el-button>
      </div>
    </div>
    <div v-else>
      <dl class="row">
        <dt class="col-sm-3 fs-6 fw-bold">Contributo INPS:</dt>
        <dd class="col-sm-9 fs-6">{{ taxOptions.tipoContributoINPS }}</dd>
        <el-divider></el-divider>
        <dt class="col-sm-3 fs-6 fw-bold">Profilo Fiscale:</dt>
        <dd class="col-sm-9 fs-6">{{ taxOptions.tipoProfiloFiscale }}</dd>
        <el-divider></el-divider>
        <dt class="col-sm-3 fs-6 fw-bold">Ritenute Fiscali:</dt>
        <dd class="col-sm-9 fs-6">{{ taxOptions.tipoRitenuteFiscale }}</dd>
        <el-divider></el-divider>
        <dt class="col-sm-3 fs-6 fw-bold">Codice Fiscale:</dt>
        <dd class="col-sm-9 fs-6">{{ taxOptions.codiceFiscale }}</dd>
        <el-divider></el-divider>
        <dt class="col-sm-3 fs-6 fw-bold">Comune di Nascita:</dt>
        <dd class="col-sm-9 fs-6">{{ taxOptions.comuneNascita }}</dd>
        <el-divider></el-divider>
        <dt class="col-sm-3 fs-6 fw-bold">Data di Nascita:</dt>
        <dd class="col-sm-9 fs-6">{{ taxOptions.dataNascita }}</dd>
        <el-divider></el-divider>
        <dt class="col-sm-3 fs-6 fw-bold">Sesso:</dt>
        <dd class="col-sm-9 fs-6">{{ taxOptions.sesso }}</dd>
        <el-divider></el-divider>
        <dt class="col-sm-3 fs-6 fw-bold">Società:</dt>
        <dd class="col-sm-9 fs-6">{{ taxOptions.societa }}</dd>
        <el-divider></el-divider>
        <dt class="col-sm-3 fs-6 fw-bold">Partita IVA:</dt>
        <dd class="col-sm-9 fs-6">{{ taxOptions.partitaIVA }}</dd>
        <el-divider></el-divider>
      </dl>
      <div class="d-grid gap-2 col-3 mx-auto">
        <el-button type="primary" icon="el-icon-edit" @click="handleUpdate"
          >Edit</el-button
        >
      </div>
    </div>
  </el-card>
  <el-dialog
    v-model="dialogFormVisible"
    :before-close="handleClose"
    :width="device === 'mobile' ? '80%' : '50%'"
    center
  >
    <template #title>
      <span class="fw-bold">Modifica Dati Fiscali</span>
    </template>
    <div
      :class="[
        'alert',
        'text-center',
        submitResult ? 'alert-success' : 'alert-danger',
      ]"
      v-if="isSubmitted"
    >
      {{ submitResult ? axiosSuccessMessage : axiosErrorMessage }}
    </div>
    <el-form
      show-message
      :label-position="device === 'mobile' ? 'top' : 'left'"
      label-width="140px"
    >
      <el-form-item
        label="Contributo INPS"
        :error="contributoINPSField.errorMessage"
      >
        <el-select
          :style="device === 'mobile' ? 'width: 100%;' : ''"
          v-model="contributoINPSField.value"
          filterable
          placeholder="Select"
          @update:v-model="contributoINPSField.handleChange"
          @change="handleChange"
        >
          <el-option
            v-for="item in contributoINPS"
            :key="item.pkTipoContributoINPS"
            :label="item.descrizione"
            :value="item.pkTipoContributoINPS"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="Profilo Fiscale"
        :error="profiloFiscaleField.errorMessage"
      >
        <el-select
          :style="device === 'mobile' ? 'width: 100%;' : ''"
          v-model="profiloFiscaleField.value"
          filterable
          placeholder="Select"
          @update:v-model="profiloFiscaleField.handleChange"
          @change="handleChange"
        >
          <el-option
            v-for="item in profiliFiscali"
            :key="item.pkTipoProfiloFiscale"
            :label="item.descrizione"
            :value="item.pkTipoProfiloFiscale"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="Ritenute Fiscali"
        :error="ritenuteFiscaliField.errorMessage"
      >
        <el-select
          :style="device === 'mobile' ? 'width: 100%;' : ''"
          v-model="ritenuteFiscaliField.value"
          filterable
          placeholder="Select"
          @update:v-model="ritenuteFiscaliField.handleChange"
          @change="handleChange"
        >
          <el-option
            v-for="item in ritenuteFiscali"
            :key="item.pkTipoRitenutaFiscale"
            :label="item.descrizione"
            :value="item.pkTipoRitenutaFiscale"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        label="Codice Fiscale"
        :error="codiceFiscaleField.errorMessage"
      >
        <el-input
          type="text"
          @change="handleChange"
          :model-value="codiceFiscaleField.value"
          @update:model-value="codiceFiscaleField.handleChange"
        >
        </el-input>
      </el-form-item>
      <el-form-item
        label="Comune di Nascita"
        :error="comuneNascitaField.errorMessage"
      >
        <el-input
          type="text"
          @change="handleChange"
          :model-value="comuneNascitaField.value"
          @update:model-value="comuneNascitaField.handleChange"
        >
        </el-input>
      </el-form-item>
      <el-form-item
        label="Data di nascita"
        :error="dataNascitaField.errorMessage"
      >
        <el-date-picker
          @change="handleChange"
          @update:model-value="dataNascitaField.handleChange"
          :model-value="dataNascitaField.value"
          type="date"
          placeholder="gg/mm/aaaa"
          format="DD/MM/YYYY"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="Sesso" :error="sessoField.errorMessage">
        <el-radio-group
          @change="sessoField.handleChange"
          v-model="sessoField.value"
        >
          <el-radio label="F">F</el-radio>
          <el-radio label="M">M</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="Società" :error="societaField.errorMessage">
        <el-input
          type="text"
          @change="handleChange"
          :model-value="societaField.value"
          @update:model-value="societaField.handleChange"
        >
        </el-input>
      </el-form-item>

      <el-form-item label="Partita IVA" :error="partitaIVAField.errorMessage">
        <el-input
          type="text"
          @change="handleChange"
          :model-value="partitaIVAField.value"
          @update:model-value="partitaIVAField.handleChange"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="info" @click="handleClose"> Indietro </el-button>
        <el-button :loading="loading" type="primary" @click="submitForm">
          Conferma
        </el-button>
      </span></template
    >
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import {
  FetchTaxOptions,
  FetchTaxProfiles,
  FetchINPSTax,
  FetchWithholdingTax,
} from "@/api/fetchData.js";
import { useField, useForm } from "vee-validate";
import { UpdateTaxOptions } from "@/api/modifyData.js";
import { reactive } from "vue";
import * as yup from "yup";
export default {
  computed: {
    ...mapState({
      device: (state) => state.app.device,
    }),
  },
  setup() {
    const { meta: formMeta, handleSubmit, resetForm, handleReset } = useForm();
    const contributoINPSField = reactive(
      useField("fkTipoContributoINPS", yup.string().required("Campo richiesto"))
    );
    const profiloFiscaleField = reactive(
      useField("fkTipoProfiloFiscale", yup.string().required("Campo richiesto"))
    );
    const ritenuteFiscaliField = reactive(
      useField(
        "fkTipoRitenuteFiscale",
        yup.string().required("Campo richiesto")
      )
    );
    const societaField = reactive(useField("societa", yup.string().nullable()));
    const sessoField = reactive(
      useField("Sesso", yup.string().required("Campo richiesto"))
    );
    const partitaIVAField = reactive(
      useField("partitaIVA", yup.string().nullable())
    );
    const codiceFiscaleField = reactive(
      useField("codiceFiscale", yup.string().required("Campo richiesto"))
    );
    const comuneNascitaField = reactive(
      useField("comuneNascita", yup.string().required("Campo richiesto").nullable())
    );
    const dataNascitaField = reactive(
      useField("dataNascita", yup.string().required("Campo richiesto"))
    );
    return {
      formMeta,
      handleSubmit,
      resetForm,
      handleReset,
      contributoINPSField,
      profiloFiscaleField,
      ritenuteFiscaliField,
      societaField,
      sessoField,
      partitaIVAField,
      codiceFiscaleField,
      comuneNascitaField,
      dataNascitaField,
    };
  },
  data() {
    return {
      taxOptions: [],
      profiliFiscali: [],
      contributoINPS: [],
      ritenuteFiscali: [],
      dialogFormVisible: false,
      formModified: false,
      isSubmitted: false,
      submitResult: false,
      axiosErrorMessage: "",
      axiosSuccessMessage: "",
      submitForm: this.handleSubmit(this.onSubmit),
      loading: false,
      loadingCard: false,
      fetchWithholdingTaxError: false,
      fetchINPSTaxError: false,
      fetchTaxProfilesError: false,
      checklist: [],
      fetchTaxOptionsError: false,
    };
  },

  created() {
    this.fetchTaxOptions();
  },
  methods: {
    handleChange() {
      this.formModified = true;
      this.isSubmitted = false;
    },
    tryAgain() {
      this.fetchTaxOptions().then(() => {
        if (this.taxOptions == []) {
          this.fetchTaxOptionsError = false;
        } else {
          this.fetchTaxOptionsError = true;
        }
      });
    },
    async fetchTaxOptions() {
      this.loadingCard = true;
      await FetchTaxOptions()
        .then((response) => {
          this.taxOptions = response.data;
          this.taxOptions.dataNascita = moment(
            this.taxOptions.dataNascita
          ).format("DD/MM/YYYY");
        })
        .catch(() => {
          this.fetchTaxOptionsError = true;
        });
      this.loadingCard = false;
    },
    async fetchTaxProfiles() {
      await FetchTaxProfiles()
        .then((response) => {
          this.profiliFiscali = response.data;
        })
        .catch(() => {
          this.fetchTaxProfilesError = true;
        });
    },
    async fetchINPSTax() {
      await FetchINPSTax()
        .then((response) => {
          this.contributoINPS = response.data;
        })
        .catch(() => {
          this.fetchINPSTaxError = true;
        });
    },
    async fetchWithholdingTax() {
      await FetchWithholdingTax()
        .then((response) => {
          this.ritenuteFiscali = response.data;
        })
        .catch(() => {
          this.fetchWithholdingTaxError = true;
        });
    },
    async onSubmit(formValues) {
      this.loading = true;
      this.isSubmitted = false;
      formValues.dataNascita = moment
        .utc(formValues.dataNascita)
        .local()
        .format();
      await UpdateTaxOptions(formValues)
        .then(() => {
          this.isSubmitted = true;
          this.submitResult = true;
          this.axiosSuccessMessage = "Dati aggiornati con successo";
          this.formModified = false;
          this.fetchTaxOptions();
        })
        .catch((error) => {
          this.isSubmitted = true;
          this.submitResult = false;
          this.axiosErrorMessage = "Aggiornamento dati fallito";
          let keys = Object.keys(error.response.data.errors);
          if (keys.indexOf("fkTipoContributoINPS") !== -1) {
            this.contributoINPSField.errors.push(
              error.response.data.errors.fkTipoContributoINPS[0]
            );
          }
          if (keys.indexOf("fkTipoProfiloFiscale") !== -1) {
            this.profiloFiscaleField.errors.push(
              error.response.data.errors.fkTipoProfiloFiscale[0]
            );
          }
          if (keys.indexOf("fkTipoRitenuteFiscale") !== -1) {
            this.ritenuteFiscaliField.errors.push(
              error.response.data.errors.fkTipoRitenuteFiscale[0]
            );
          }
          if (keys.indexOf("societa") !== -1) {
            this.societaField.errors.push(
              error.response.data.errors.societa[0]
            );
          }
          if (keys.indexOf("partitaIVA") !== -1) {
            this.partitaIVAField.errors.push(
              error.response.data.errors.partitaIVA[0]
            );
          }
          if (keys.indexOf("CodiceFiscale") !== -1) {
            this.codiceFiscaleField.errors.push(
              error.response.data.errors.CodiceFiscale[0]
            );
          }
          if (keys.indexOf("comuneNascita") !== -1) {
            this.comuneNascitaField.errors.push(
              error.response.data.errors.comuneNascita[0]
            );
          }
          if (keys.indexOf("Sesso") !== -1) {
            this.sessoField.errors.push(error.response.data.errors.Sesso[0]);
          }
        });
      this.loading = false;
    },

    async handleUpdate() {
      await this.fetchWithholdingTax();
      await this.fetchINPSTax();
      await this.fetchTaxProfiles();
      if (
        this.fetchWithholdingTaxError == false &&
        this.fetchINPSTaxError == false &&
        this.fetchTaxProfilesError == false
      ) {
        this.initForm();
        this.dialogFormVisible = true;
      } else {
        this.errorAlert();
      }
    },
    initForm() {
      this.isSubmitted = false;
      this.contributoINPSField.value = this.taxOptions.fkTipoContributoINPS;
      this.profiloFiscaleField.value = this.taxOptions.fkTipoProfiloFiscale;
      this.ritenuteFiscaliField.value = this.taxOptions.fkTipoRitenuteFiscale;
      this.societaField.value = this.taxOptions.societa;
      this.sessoField.value = this.taxOptions.sesso;
      this.partitaIVAField.value = this.taxOptions.partitaIVA;
      this.codiceFiscaleField.value = this.taxOptions.codiceFiscale;
      this.comuneNascitaField.value = this.taxOptions.comuneNascita;
      this.dataNascitaField.value = this.taxOptions.dataNascita;
    },
    handleClose(done) {
      if (this.formModified == true) {
        this.$confirm("Sei sicuro di voler chiudere?")
          .then(() => {
            done;
            this.dialogFormVisible = false;

            this.formModified = false;
          })
          .catch(() => {});
      } else {
        this.dialogFormVisible = false;
      }
    },
    errorAlert() {
      this.$message.error("Qualcosa è andato storto, riprova!");
    },
  },
};
</script>

<style scoped>
.warning-description {
  color: #ff4949;
  padding: 4px;
  margin-bottom: 15px;
}
.warning-title {
  color: #ff4949;
  padding: 4px;
}
.el-icon-warning-outline {
  color: #ff4949;
  font-size: 150px;
  margin-bottom: 25px;
}
.box-card {
  margin-bottom: 25px;
}
.row {
  margin-bottom: 0px;
}
</style>
<template>
  <slot :id="id"></slot>
  <el-table
    v-loading="loading"
    :data="provvigioni"
    stripe
    highlight-current-row
    border
  >
    <el-table-column label="Cliente" align="center" prop="customer">
      <template v-slot="props">
        <span v-if="props.row.customer != null">{{ props.row.customer }}</span>
        <span v-else>{{ props.row.billingEmail }}</span>
      </template>
    </el-table-column>

    <el-table-column label="Numero" align="center">
      <template v-slot="props">
        <router-link
          :to="{
            name: 'CustomerOrderAdmin',
            params: { id: props.row.orderID },
          }"
          ><el-button type="text">{{
            props.row.orderID
          }}</el-button></router-link
        >
      </template></el-table-column
    >
    <el-table-column label="Data" align="center" prop="orderCreated" />
    <el-table-column label="Qualifica" align="center" prop="qualifica" />
    <el-table-column label="Livello" align="center" prop="livello" />
    <el-table-column label="Pagato" align="center" prop="total" />

    <el-table-column label="Agente" align="center" prop="nome" />
    <el-table-column label="Tipo" align="center" prop="tipo" />
    <el-table-column label="Percentuale" align="center" prop="percentuale" />
    <el-table-column label="Importo" align="center" prop="valore" />
  </el-table>
</template>

<script>
import { AdminFetchFatturaProvvigioniStampare } from "../../../api/fetchData";
export default {
  props: ["id"],
  data() {
    return {
      provvigioni: null,
      loading: false,
    };
  },
  created() {
    this.fetchProvvigioniStampare();
  },
  methods: {
    initTable(data) {
      data.forEach((element) => {
        element.nome = element.nome + element.cognome;
        element.total = element.total.toFixed(2) + "€";
        element.percentuale = element.percentuale + "%";
        element.valore = element.valore + "€";
      });
      return data;
    },
    fetchProvvigioniStampare() {
      this.loading = true;
      if (this.id != undefined) {
        AdminFetchFatturaProvvigioniStampare(this.id)
          .then((response) => {
            this.provvigioni = this.initTable(response.data);
          })
          .catch((error) =>
            window.alert("Attenzione, si è verificato un errore " + error)
          )
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style>
</style>
<template>
  <slot :id="id"></slot>
  <div class="row mt-3 text-center">
    <div class="col">
      <span class="fw-bold fs-5">Provvigioni</span>
    </div>
  </div>
  <div class="row mt-3 justify-content-center">
    <div class="col-10">
      <table class="table table-striped table-hover table-responsive">
        <thead>
          <tr>
            <td class="fw-bold text-center">Tipo</td>
            <td class="fw-bold text-center">Numero Rendiconto</td>
            <td class="fw-bold text-center">Stato</td>
            <td class="fw-bold text-center">Agente</td>
            <td class="fw-bold text-center">Qualifica</td>
            <td class="fw-bold text-center">Livello</td>
            <td class="fw-bold text-center">Percentuale</td>
            <td class="fw-bold text-center">Valore</td>
          </tr>
        </thead>
        <tbody v-for="item in provvigioni" :key="item">
          <tr>
            <td class="text-center">{{ item.tipo }}</td>
            <td class="text-center">{{ item.numeroRendiconto }}</td>
            <td class="text-center">{{ item.stato }}</td>
            <td class="text-center">{{ item.agente }}</td>
            <td class="text-center">{{ item.qualificaAgente }}</td>
            <td class="text-center">{{ item.livello }}</td>
            <td class="text-center">{{ item.percentuale }}</td>
            <td class="text-center">{{ item.valore }}</td>
          </tr>
          <tr>
            <td v-if="item.note != null" colspan="8" class="text-center"><pre>{{ item.note }}</pre></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { FetchProvvigioniOrdine } from "@/api/fetchData.js";
export default {
  props: ["id"],

  data() {
    return {
      provvigioni: [],
      loadingProvvigioni: false,
    };
  },
  created() {
    this.loadProvvigioni();
  },
  methods: {
    async loadProvvigioni() {
      this.loadingProvvigioni = true;
      await FetchProvvigioniOrdine(this.id)
        .then((response) => {
          this.provvigioni = response.data;
          this.provvigioni.forEach((element) => {
            element.total = element.total.toFixed(2) + "€";
            element.valore = element.valore.toFixed(2) + "€";
            element.percentuale = element.percentuale + "%";
            element.dataOra = moment(element.dataOra).format(
              "DD/MM/YYYY, HH:mm:ss"
            );

            if (element.agenteCodice != null) {
              element.agente =
                element.agente + " (" + element.agenteCodice + ")";
            }
          });
        })
        .catch((error) =>
          window.alert("Attenzione, si è verificato un errore " + error)
        )
        .finally(() => (this.loadingProvvigioni = false));
    },
  },
};
</script>

<style scoped>
</style>
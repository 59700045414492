<template>
  <slot :id="id"></slot>
      <el-button type="primary" size="mini" :loading="loading" @click="fetchLoginAccount">
        Reset password
      </el-button>

  <el-dialog v-model="dialogVisible" :before-close="handleClose" width="500px" title="Seleziona un username">
    <div v-if="resetPasswordResponse!=''">
          <p><pre>{{resetPasswordResponse}}</pre></p>  
    <el-button type="success" size="medium" @click="doCopy(resetPasswordResponse)">Copia</el-button>
    </div>
<div v-else>
  <el-form>
  <el-form-item :error="usernameField.errorMessage">
        <el-select
          :model-value="usernameField.value"
          @update:model-value="usernameField.handleChange"
          @change="formModified = true"
        >
          <el-option
            v-for="item in accounts"
            :key="item.username"
            :label="item.username"
            :value="item.username"
          >
          </el-option>
        </el-select>
      </el-form-item>
      </el-form>
<el-button type="info" size="medium" @click="handleClose">Indietro</el-button><el-button type="primary" size="medium" @click="submit">Conferma</el-button>

</div>
  </el-dialog>
</template>

<script>
import { useField, useForm } from "vee-validate";
import { reactive } from "vue";
import * as yup from "yup";
import { copyText } from "vue3-clipboard";
import { AdminResetPassword, AdminLoginAccount } from "@/api/login.js";
export default {
  props: ["id"],
  setup() {
    const { meta: formMeta, handleSubmit, resetForm, handleReset } = useForm();
    const usernameField = reactive(
      useField("email", yup.string().required("Campo richiesto"))
    );
    return {
      usernameField,
      formMeta,
      handleSubmit,
      resetForm,
      handleReset,
    };
  },
  data() {
    return {
      loading: false,
      resetPasswordResponse: "",
      dialogVisible: false,
      accounts: [],
      formModified: false,
      submit: this.handleSubmit(this.resetPassword),
    };
  },
  methods: {
    handleClose(done) {
      if (this.formModified == true) {
        this.$confirm("Are you sure to close this dialog?")
          .then(() => {
            done;
            this.dialogVisible = false;
            this.formModified = false;
          })
          .catch(() => {});
      } else {
        this.dialogVisible = false;
      }
    },
    async fetchLoginAccount() {
      this.resetForm();
      this.loading = true;
      this.resetPasswordResponse = "";
      await AdminLoginAccount(this.id)
        .then((response) => {
          this.accounts = response.data;
          this.dialogVisible = true;
        })
        .catch(() => {
          alert("Errore durante il reset della password");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    doCopy(data) {
      copyText(data, undefined, (error) => {
        if (error) {
          this.$message.error("Impossibile copiare");
        } else {
          this.$message({
            message: "Copiato!",
            type: "success",
          });
        }
      });
    },
    async resetPassword(formValues) {
console.log(formValues)
      await AdminResetPassword(this.id, formValues)
        .then((response) => {
          this.resetPasswordResponse = response.data;
          this.dialogVisible = true;
        })
        .catch(() => {
          alert("Errore durante il reset della password");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
</style>
<template>
  <slot :id="id"></slot>
  <el-popconfirm
    title="Sei sicuro di voler cancellare l'ordine?"
    @confirm="deleteOrder"
    confirmButtonText="Si"
    cancelButtonText="No"
  >
    <template #reference>
      <el-button
        type="danger"
        icon="el-icon-delete"
        size="mini"
        :loading="loading"
      >
        Cancella
      </el-button>
    </template>
  </el-popconfirm>
</template>

<script>
import { DeleteOrder } from "@/api/modifyData.js";
export default {
    emits:["deleteSuccess"],
  props: ["id"],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async deleteOrder() {
      this.loading = true;
      await DeleteOrder(this.id)
        .then(() => {
          alert("Ordine eliminato con successo");
          this.$emit('deleteSuccess')
        })
        .catch(() => {
          alert("Errore nell'eliminazione dell'ordine");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
</style>
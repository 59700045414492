<template>
  <div class="pagination-container">
    <el-pagination
      :total="total"
      :background="background"
      :layout="layout"
      :pageSizes="pageSizes"
      :current-page="page"
      :page-size="limit"
      v-bind="$attrs"
      @current-change="$emit('pagination')"
      @size-change="$emit('pagination')"
      :disabled="disabled"
    >
    </el-pagination>
  </div>
</template>


<script>
export default {
  $emit: ["pagination"],
  props: {
    total: {
      required: true,
      type: Number,
    },
    page: {
      type: Number,
      default: 1,
    },
    limit: {
      type: Number,
      default: 10,
    },
    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 50];
      },
    },
    layout: {
      type: String,
      default: "total, sizes, prev, pager, next,",
    },
    background: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
</style>
<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-4 px-3 py-3 mt-3">
        <Agent />
      </div>
      <div class="col-lg-8 px-3 py-3 mt-3">
        <Address />    <TaxOptions />
        <BankAccount />
        <Contacts />
      </div>
    </div>

  </div>
</template>

<script>
import Address from "@/components/Account/Address.vue";
import Agent from "@/components/Account/Agent.vue";
import BankAccount from "@/components/Account/BankAccount.vue";
import Contacts from "@/components/Account/Contacts.vue";

import TaxOptions from "@/components/Account/TaxOptions.vue";
export default {
  components: { Address, Agent, BankAccount, Contacts, TaxOptions },
};
</script>

<style scoped>
.container-fluid{
  background-color: #e0e0e0;
}
</style>
<template>
    <div class="row">
        <div class="col ms-3 mb-3">
            <export-excel :searchParam="provvigioniSearch"></export-excel>
        </div>
    </div>
    <div class="row">
        <div class="col ms-3 mb-4">
            <!--<el-date-picker v-model="provvigioniSearch.DataMese"
                            type="month"
                            size="medium"
                            format="MM/YYYY"
                            :default-value="provvigioniSearch.DataMese">
            </el-date-picker>-->

            <el-date-picker v-model="provvigioniSearch.dateSearch" type="daterange" size="medium" format="DD/MM/YYYY" placeholder="Seleziona" start-placeholder="Data iniziale" end-placeholder="Data finale" range-separator="al" clearable>
            </el-date-picker>

            <el-input v-model="provvigioniSearch.IdOrdine" placeholder="ID Ordine" size="medium" class="ms-3" style="width: 210px" />

            <!--<el-select clearable
                       v-model="provvigioniSearch.Status"
                       placeholder="Stato"
                       multiple
                       size="medium"
                       class="ms-3"
                       style="width: 260">-->
            <!--<el-option v-for="item in status"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">
                </el-option>


            </el-select>-->
            <el-select clearable :loading="loadingAgents" v-model="provvigioniSearch.PkAgenti" placeholder="Seleziona Agente" filterable multiple size="medium" class="ms-3" style="width: 260px">
                <el-option v-for="item in agentsSelect" :key="item.pkAgente" :value="item.pkAgente" :label="item.nome + ' ' + item.cognome + ' (' + item.codice + ')'">
                </el-option>
            </el-select>

            <el-select clearable v-model="provvigioniSearch.Stato" placeholder="Stato Provvigione" multiple size="medium" class="ms-3" style="width: 260px">
                <el-option v-for="item in tipo" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>

            <el-button size="medium" icon="el-icon-search" type="primary" class="ms-3" :loading="loadingRicerca" @click="onRicerca">
                Cerca
            </el-button>
        </div>
    </div>
    <el-table v-loading="loading" :data="provvigioni" stripe fit highlight-current-row border>

        <el-table-column label="ID" prop="pkAgenteProvvigione" align="center" min-width="25" />

        <el-table-column label="Agente" align="left" min-width="160">
            <template v-slot="props">
                <span v-if="props.row.agenteCodice != null">{{props.row.agenteCodice}}</span>
                &nbsp;
                <b>{{props.row.agente}}</b>
                <br />
                <small>{{props.row.qualificaAgente}}</small>
            </template>
        </el-table-column>

        <el-table-column label="Data" prop="dataOra" align="center" min-width="45">
            <template v-slot="props">
                {{moment(props.row.dataOra).format("DD/MM/YYYY")}}
                <br />
                <small>{{moment(props.row.dataOra).format("HH:mm:ss")}}</small>
            </template>
        </el-table-column>

        <el-table-column label="ID Ordine" align="center" min-width="50">

            <template #default="props">

                <router-link :to="{name: 'CustomerOrderAdmin',params: { pkCustomerOrder: props.row.pkCustomerOrder },}">
                    <el-button type="text">
                        <span v-if="props.row.orderID!=null">{{props.row.orderID}}</span>
                        <span class="el-icon-goods" v-else> Ordine</span>
                    </el-button>
                    <el-popover placement="bottom" trigger="click" v-if="props.row.DebugInfo != null">
                        {{ props.row.DebugInfo }}
                        <template #reference>
                            <el-button type="text">
                                <i class="el-icon-info ms-1"></i>
                            </el-button>
                        </template>
                    </el-popover>

                </router-link>

                <el-popover placement="bottom" :width="400" trigger="click" v-if="props.row.debugInfo!=null">
                    <pre>{{ props.row.debugInfo }}</pre>
                    <template #reference>
                        <el-button type="text">
                            <i class="el-icon-info ms-1"></i>
                        </el-button>
                    </template>
                </el-popover>

            </template>


            <!--<template v-slot="props">
            </template>-->
        </el-table-column>

        <el-table-column label="Totale pagato" align="right" min-width="55">
            <template v-slot="props">
                <el-popover placement="bottom" trigger="click" :width="280">
                    <small v-show="props.row.customer!=null">Cliente :<b>{{props.row.customer}}</b><br /></small>
                    <small>Pagato : {{props.row.total.toFixed(2)}} ( {{props.row.totalTax.toFixed(2)}}€ )<br /></small>
                    <small v-show="props.row.shippingTotal>0">Spedizioni : {{props.row.shippingTotal.toFixed(2)}}€ ( {{props.row.shippingTax.toFixed(2)}}€ )<br /></small>
                    <small v-show="props.row.discountTotal>0">Sconto : {{props.row.discountTotal.toFixed(2)}}€ ( {{props.row.discountTax.toFixed(2)}}€ )<br /></small>
                    <small v-show="(props.row.licenza!=null) && (props.row.licenza!='')">Licenza : {{props.row.licenza}} {{props.row.licenzaTotal.toFixed(2)}}€ ( {{props.row.licenzaTax.toFixed(2)}}€ )<br /></small>
                    <small v-show="(props.row.giftCardId!=null) && (props.row.giftCardId!='')">Gift Card : {{props.row.giftCardId}} {{props.row.giftCard.toFixed(2)}}€<br /></small>
                    <template #reference>
                        <el-button type="text">{{props.row.total.toFixed(2)}}€<i class="el-icon-info ms-1"></i></el-button>
                    </template>
                </el-popover>
            </template>

        </el-table-column>
        <el-table-column label="Base provvigione" align="right" min-width="60">
            <template v-slot="props">
                {{props.row.commissionabile.toFixed(2)}}€
            </template>
        </el-table-column>
        <el-table-column label="Percentuale" align="center">
            <template v-slot="props">


                <b>{{props.row.percentuale}}%</b>
                <el-popover placement="bottom" trigger="click" :width="200" v-if="props.row.pianoMarketing != null">
                    <small>{{ props.row.pianoMarketing }}</small><br />
                    <small>ID:{{ props.row.pkAgenteProvvigione }}</small>
                    <template #reference>
                        <el-button type="text">
                            <i class="el-icon-info ms-1"></i>
                        </el-button>
                    </template>
                </el-popover>
                <el-popover placement="bottom" trigger="click" :width="200" v-else>
                    <small>ID:{{ props.row.pkAgenteProvvigione }}</small>
                    <template #reference>
                        <el-button type="text">
                            <i class="el-icon-error ms-1"></i>
                        </el-button>
                    </template>
                </el-popover>

                <br />
                <span>{{props.row.qualifica}}</span>
                <br />
                <small v-if="props.row.livello==0">Vendita diretta</small>
                <small v-else>Agente {{props.row.livello}}° livello</small>
            </template>
        </el-table-column>

        <el-table-column label="Provvigione" align="right">
            <template v-slot="props">
                <b>{{ props.row.valore.toFixed(2) }}€</b>
                <el-popover placement="bottom" trigger="click" :width="450" v-if="props.row.note != null">
                    <small>{{ props.row.note }}</small>
                    <template #reference>
                        <el-button type="text">
                            <i class="el-icon-info ms-1"></i>
                        </el-button>
                    </template>
                </el-popover>
            </template>
        </el-table-column>

        <el-table-column label="Tipo" prop="tipo" align="center" />
        <el-table-column label="Stato" prop="stato" align="center" />
        <!--<el-table-column label="Azioni" align="center" class-name=" fixed-width" v-show="false">

            <template v-slot="{ row }">
                <BtnContabilizzareProvvigione :id="row.pkAgente" :DataMese="provvigioniSearch.DataMese" @contabilizzareSuccess="loadData" v-if="row.stato === 'Maturata'"/>
                <btn-fatturare-provvigione :id="row.pkAgente" :DataMese="provvigioniSearch.DataMese" @fatturareSuccess="loadData" v-if="row.stato === 'Contabilizzata'" />
            </template>
        </el-table-column>-->

    </el-table>

    <pagination v-show="Number(pager.TotalCount) > 0" :total="Number(pager.TotalCount)" v-model:current-page="pageNumber" v-model:page-size="pageSize" @pagination="loadData" :disabled="disabledPagination" />

</template>

<script>
    import ExportExcel from "./AdminExport2ExcelProvvigioni.vue";
    import moment from "moment";
    //import ProvvigioniTable from "./AdminProvvigioniExpandRow.vue";
    //import BtnContabilizzareProvvigione from "./BtnContabilizzareProvvigione.vue";
    //import BtnFatturareProvvigione from "./BtnFatturareProvvigione.vue";
	import { AdminFetchProvvigioni, DatiComuni  } from "@/api/fetchData.js";
    import Pagination from "@/components/Pagination";
    export default {
        components: {
            ExportExcel,
            Pagination,
            //ProvvigioniTable,
            //    BtnContabilizzareProvvigione,
            //    BtnFatturareProvvigione,
        },

        data() {

            return {
                dateSearch: null,
                loading: false,
                pager: [],
                pageNumber: 1,
                pageSize: 10,
                provvigioni: [],
                provvigioniSearch: {
                    //DataMese: moment().utc().local(),
                    PkAgenti: undefined,
                    IdOrdine: undefined,
                    //Status: undefined,
                    DataInizio: undefined,
                    DataFine: undefined,
                    Stato: 0,
                    AllRows: undefined,
                },

                tipo: [
                    {
                        label: "Tutte",
                        value: 0,
                    },
                    {
                        label: "Sospesa",
                        value: 1,
                    },
                    {
                        label: "Maturata",
                        value: 2,
                    },
                    {
                        label: "Contabilizzata",
                        value: 3,
                    },
                    {
                        label: "Fatturata",
                        value: 4,
                    },
                ],
                //status: [
                //    {
                //        label: "Tutte",
                //        value: 0,
                //    },
                //    {
                //        label: "Da pagare",
                //        value: 1,
                //    },
                //    {
                //        label: "Da non pagare",
                //        value: 2,
                //    },
                //    {
                //        label: "Con Riporto",
                //        value: 3,
                //    },
                //],
                loadingAgents: false,
                loadingRicerca: false,
                disabledPagination: false,
                agentsSelect: [],
            };
        },

        created() {
			this.SelectAgentiInit();
            this.loadData();
        },

        methods: {


            moment: moment,

            loadData() {

                this.provvigioniSearch.AllRows = undefined;
                this.disabledPagination = true;
                this.loading = true;
                //    if (this.provvigioniSearch.DataMese != undefined) {
                //        this.provvigioniSearch.DataMese = moment
                //            .utc(this.provvigioniSearch.DataMese)
                //            .local()
                //            .format("YYYY-MM-DD[T]00:00:00");
                //    }

                AdminFetchProvvigioni(this.pageNumber, this.pageSize,
                    //this.provvigioniSearch.DataMese,
                    this.provvigioniSearch.DataInizio, this.provvigioniSearch.DataFine, this.provvigioniSearch.PkAgenti, this.provvigioniSearch.IdOrdine, this.provvigioniSearch.Status, this.provvigioniSearch.Stato, this.provvigioniSearch.AllRows).then((response) => {

                        this.provvigioni = response.data;
                        this.pager = JSON.parse(response.headers["x-pagination"]);

                    }).catch((error) => window.alert("Attenzione, si è verificato un errore " + error)
                    ).finally(() => {
                        this.loading = false;
                        this.disabledPagination = false;
                    });

            },

			SelectAgentiInit() {
				this.loadingAgents = true;
				let a = new DatiComuni();
				let result = a.getAgenti();
				result.then((response) => {
					this.agentsSelect = response.data;
				}).catch((error) =>
					window.alert("Attenzione, si è verificato un errore " + error)
				).finally(() => {
					this.loadingAgents = false;
				});
			},


            onRicerca() {

                try {
                    this.disabledPagination = true;
                    this.pageNumber = 1;
                    this.pageSize = 10;
                    this.loadingRicerca = true;
                    this.provvigioni = [];
                    this.loading = true;

                    if (this.provvigioniSearch.dateSearch != null) {
                        if (this.provvigioniSearch.dateSearch[0] != null) {
                            this.provvigioniSearch.DataInizio = moment.utc(this.provvigioniSearch.dateSearch[0]).local().format("YYYY-MM-DD[T]00:00:00");
                        } else {
                            this.provvigioniSearch.DataInizio = undefined;
                        }
                        if (this.provvigioniSearch.dateSearch[1] != null) {
                            this.provvigioniSearch.DataFine = moment.utc(this.provvigioniSearch.dateSearch[1]).local().format("YYYY-MM-DD[T]23:59:59");
                        } else {
                            this.provvigioniSearch.DataFine = undefined;
                        }
                    } else {
                        this.provvigioniSearch.DataInizio = undefined;
                        this.provvigioniSearch.DataFine = undefined;
                    }

                    //if (this.provvigioniSearch.DataMese != undefined) {
                    //    this.provvigioniSearch.DataMese = moment.utc(this.provvigioniSearch.DataMese).local().format("YYYY-MM-DD[T]00:00:00");
                    //}
                    //this.provvigioniSearch.DataMese,
                    AdminFetchProvvigioni(this.pageNumber, this.pageSize, this.provvigioniSearch.DataInizio, this.provvigioniSearch.DataFine, this.provvigioniSearch.PkAgenti, this.provvigioniSearch.IdOrdine, this.provvigioniSearch.Status, this.provvigioniSearch.Stato).then((response) => {
                        this.provvigioni = response.data;
                        this.pager = JSON.parse(response.headers["x-pagination"]);

                    }).catch((error) =>
                        window.alert("Attenzione, si è verificato un errore " + error)
                    ).finally(() => {
                        this.loadingRicerca = false;
                        this.disabledPagination = false;
                        this.loading = false;
                    });


                } catch (e) {
                    this.loadingTotals = false;
                    this.loadingRicerca = false;
                    window.alert("Attenzione, si è verificato un errore " + e);
                }

            },

        },
    };
</script>

<style>
</style>

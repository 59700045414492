<template>
  <div class="container">
    <div class="row p-4 text-center">
      <div class="col">
        <h2>Struttura vendita</h2>
      </div>
    </div>
  </div>
  <div class="col" v-if="treeData == null"></div>
  <div v-else>
    <div class="row justify-content-evenly my-5">
      <div class="col-11">
        <span class="fs-5 me-3">Orientamento:</span>
        <el-select v-model="horizontal">
          <el-option
            v-for="item in option"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <el-scrollbar width="600px" class="ms-3 me-3">
      <div class="row p-4">
        <div class="col">
          <vue-blocks-tree
            :data="treeData"
            :horizontal="horizontal"
            :collapsable="true"
            :props="{
              label: 'label',
              expand: 'expand',
              children: 'children',
              key: 'id',
            }"
          >
            <template #node="{ data }">
              <span
                :class="[
                  'fw-bold',
                  data.Licenza == '2'
                    ? 'cessata'
                    : data.Licenza == '1'
                    ? 'sospesa'
                    : data.Licenza == '0'
                    ? 'attiva'
                    : 'indefinito',
                ]"
                >{{ data.label }}
              </span>
              <p class="qualifica">({{ data.Qualifica }})</p>
              <p>{{ data.FatturatoPersonale }}€</p>
            </template>
          </vue-blocks-tree>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import VueBlocksTree from "vue3-blocks-tree";
import "vue3-blocks-tree/dist/vue3-blocks-tree.css";
import { ElLoading } from "element-plus";
import { FetchTree } from "@/api/fetchData.js";
import { reactive } from "vue";
export default {
  components: { VueBlocksTree },

  data() {
    let loadingInstance = ElLoading.service({ fullscreen: true });
    return {
      treeData: null,
      loadingInstance,
      activeNames: [],
      horizontal: true,
      option: [
        { value: false, label: "Verticale" },
        {
          value: true,
          label: "Orizzontale",
        },
      ],
    };
  },
  created() {
    this.fetchTree();
  },
  methods: {
    async fetchTree() {
      this.loadingInstance;
      await FetchTree()
        .then((response) => {
          this.treeData = reactive(response.data);
        })
        .catch(() => {
          this.$nextTick(() => {
            this.loadingInstance.close();
          });
        });
      this.$nextTick(() => {
        this.loadingInstance.close();
      });
    },
  },
};
</script>

<style scoped>
.cessata {
  color: red;
}
.sospesa {
  color: orange;
}
.attiva {
  color: black;
}
.indefinito {
  color: lightgray;
}

.qualifica{
  margin-bottom: 0px;
}
</style>
<template>
	<slot :searchParam="searchParam"></slot>
	<export-2-excel
		:filename="defaultFileName"
		:headers="header"
		:filterVal="filterVal"
		@export2excel="loadData($event)"
		ref="excelExport"
	></export-2-excel>
</template>

<script>
import Export2Excel from '../../Excel/index.vue';
import { AdminFetchProvvigioni } from '@/api/fetchData.js';
export default {
	components: { Export2Excel },
	props: ['searchParam'],
	data() {
		return {
			defaultFileName: 'Provvigioni',
			header: [
				'PK',
				'Codice Agente',
				'Agente',
				'Qualifica',
				'Data',
				'ID Ordine',
				'Totale pagato',
				'Cliente',
				'Spedizioni',
				'IVA Spedizioni',
				'Sconto',
				'IVA Sconto',
				'Licenza',
				'Totale Licenza',
				'Gift Card ID',
				'Totale Gift Card',
				'Base provvigione',
				'Percentuale',
				'Piano Marketing',
				'ID Agente Provvigione',
				'Livello',
				'Provvigione',
				'Note',
				'Tipo',
				'Stato',
			],
			filterVal: [
				'pkAgenteProvvigione',
				'agenteCodice',
				'agente',
				'qualificaAgente',
				'dataOra',
				'orderID',
				'total',
				'customer',
				'shippingTotal',
				'shippingTax',
				'discountTotal',
				'discountTax',
				'licenza',
				'licenzaTotal',
				'giftCardId',
				'giftCard',
				'commissionabile',
				'percentuale',
				'pianoMarketing',
				'pkAgenteProvvigione',
				'livello',
				'valore',
				'note',
				'tipo',
				'stato',
			],
		};
	},
	methods: {
		loadData() {
			const allRows = true;
			AdminFetchProvvigioni(
				undefined,
				undefined,
				this.searchParam.DataInizio,
				this.searchParam.DataFine,
				this.searchParam.PkAgenti,
				this.searchParam.IdOrdine,
				this.searchParam.Status,
				this.searchParam.Stato,
				allRows
			)
				.then((response) => {
					const tableData = response.data;
					this.$refs.excelExport.Export2Excel(tableData);
				})
				.catch((error) =>
					window.alert('Attenzione, si è verificato un errore ' + error)
				)
				.finally(() => {});
		},
	},
};
</script>

<style>
</style>

<template>
  <div class="text-center" v-if="pager.HasNext">
    <el-button type="info" round @click="loadMore" :loading="loading"
      >Vedi altri</el-button
    >
  </div>
</template>
<script>
import moment from "moment";
import { FetchCustomerOrder } from "@/api/fetchData.js";
export default {
  emits: ["loadMoreOrder"],
  props: ["pageNumber", "pager", "ordersSearch"],
  data() {
    return {
      newOrders: [],
      loading: false,
      pageSize: 10,
    };
  },
  methods: {
    initTable(data) {
      data.forEach((element) => {
        element.orderCreated = moment(element.orderCreated).format(
          "DD/MM/YYYY, HH:mm:ss"
        );
        element.discountTotal =
          (element.discountTotal + element.discountTax).toFixed(2) + "€";

        element.shippingTotal =
          (element.shippingTotal + element.shippingTax).toFixed(2) + "€";
        element.total = element.total.toFixed(2) + "€";
        element.totalTax = element.totalTax.toFixed(2) + "€";
      });
      return data;
    },
    async loadData() {
      await FetchCustomerOrder(
        this.pageNumber,
        this.pageSize,
        this.ordersSearch.DataInizio,
        this.ordersSearch.DataFine,
        this.ordersSearch.PkAgenti,
        this.ordersSearch.Ricerca,
        this.ordersSearch.Status
      ).then((response) => {
        this.newOrders = this.initTable(response.data);
      });
    },
    async loadMore() {
      this.loading = true;

      await this.loadData().then(() => {
        this.$emit("loadMoreOrder", this.newOrders);
      });

      this.loading = false;
    },
  },
};
</script>

<style>
</style>
<template>
  <slot
    :fattura="fattura"
    :provvigioni="provvigioni"
    :riporto="riporto"
    :totale="totale"
    :deduzione="deduzione"
    :imponibile="imponibile"
    :ritenutaFiscale="ritenutaFiscale"
    :totaleDovuto="totaleDovuto"
    :provvigioniTableData="provvigioniTableData"
    :pagine="pagine"
    :marginTop="marginTop"
    :totaleIVA="totaleIVA"
    :nomeFattura="nomeFattura"
    :showLayout="showLayout"
    :floatLayout="floatLayout"
  ></slot>
  <vue-html2pdf
    :show-layout="showLayout"
    :float-layout="floatLayout"
    :enable-download="true"
    :preview-modal="false"
    :paginate-elements-by-height="1400"
    :manual-pagination="true"
    pdf-format="a4"
    pdf-orientation="portrait"
    pdf-content-width="715px"
    ref="html2Pdf"
    :html-to-pdf-options="htmlToPdfOptions"
  >
    <template #pdf-content>
      <div class="container-fluid">
        <div class="row justify-content-between border-intestazione">
          <div class="col mb-4 text-start">
            <p class="fw-bold">Spettabile</p>
            <span>{{ fattura.committenteRagioneSociale }}</span
            ><br />
            <span>{{ fattura.committenteIndirizzo }}</span
            ><br />
            <span
              >{{ fattura.committenteCAP }} {{ fattura.committenteCitta }} ({{
                fattura.committenteProvincia
              }})</span
            ><br />
            <span class="fw-bold">Codice Fiscale </span
            ><span>{{ fattura.committenteCodiceFiscale }}</span
            ><br />

            <span class="fw-bold">Partita IVA </span
            ><span>{{ fattura.committentePartitaIVA }}</span
            ><br />
          </div>
          <div class="col text-center">
            <img src="../../../assets/images/Logo-Amanthy.png" />
          </div>
        </div>

        <el-row class="mt-5">
          <el-col :span="12">
            <p class="fw-bold ricevuta">Ricevuta fiscale</p>
            <div class="box-model">
              <div class="row">
                <div class="col text-center">
                  <span class="fw-bold">Numero documento</span
                  ><span class="fw-bold ms-3">Data Documento</span><br />
                </div>
                <div class="row">
                  <div class="col text-center">
                    <span class="me-5 ms-5">{{ fattura.numero }}</span
                    ><span class="ms-5">{{ fattura.dataOra }}</span
                    ><br />
                  </div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="12" class="text-end">
            <span>{{ fattura.cedenteRagioneSociale }}</span
            ><br />
            <span>{{ fattura.cedenteIndirizzo }}</span
            ><br />
            <span>{{ fattura.cedenteCAP }}</span
            ><br />
            <span>{{ fattura.cedenteCitta }}</span
            ><br />
            <p>
              <span class="fw-bold">Codice Fiscale </span>
              <span>{{ fattura.cedenteCodiceFiscale }}</span
              ><br />
              <span class="fw-bold">Rif. agente </span>
              <span>{{ fattura.nota }}</span
              ><br />
            </p>
          </el-col>
        </el-row>
        <el-row class="mt-5" v-if="provvigioni != null">
          <el-col :span="14" :offset="1" class="text-start">
            <p>
              <span class="fw-bold">{{ provvigioni.descrizione }}</span>
              <br />
            </p>
          </el-col>
          <el-col class="text-center" :span="4">
            <p>
              <span class="fw-bold">€</span>
            </p>
          </el-col>
          <el-col :span="5" class="text-end">
            <p>
              <span>{{ provvigioni.prezzo }}</span>
            </p>
          </el-col>
        </el-row>
        <el-row v-if="riporto != null">
          <el-col :span="14" :offset="1" class="text-start">
            <p>
              <span class="fw-bold">{{ riporto.descrizione }}</span>
            </p>
          </el-col>
          <el-col class="text-center" :span="4">
            <p>
              <span class="fw-bold">€</span>
            </p>
          </el-col>
          <el-col :span="5" class="text-end">
            <p>
              <span>{{ riporto.prezzo }}</span>
            </p>
          </el-col>
        </el-row>
        <el-row class="mt-5" v-if="totale != null">
          <el-col :span="14" :offset="1" class="text-end">
            <p>
              <span class="fw-bold">{{ totale.descrizione }}</span>
            </p>
          </el-col>
          <el-col :span="4" class="text-center">
            <p>
              <span class="fw-bold">€</span>
            </p>
          </el-col>
          <el-col :span="5" class="text-end">
            <p>
              <span>{{ totale.prezzo }}</span>
            </p>
          </el-col>
        </el-row>
        <el-row class="mt-5" v-if="totaleIVA != null">
          <el-col :span="14" :offset="1" class="text-end">
            <p>
              <span class="fw-bold">{{ totaleIVA.descrizione }}</span>
            </p>
          </el-col>
          <el-col :span="4" class="text-center">
            <p>
              <span class="fw-bold">€</span>
            </p>
          </el-col>
          <el-col :span="5" class="text-end">
            <p>
              <span>{{ totaleIVA.totale }}</span>
            </p>
          </el-col>
        </el-row>
        <el-row class="mt-5" v-if="deduzione != null">
          <el-col :span="14" :offset="1" class="text-end">
            <span class="fw-bold">{{ deduzione.descrizione }}</span>
          </el-col>
          <el-col :span="4" class="text-center">
            <span class="fw-bold">€</span>
          </el-col>
          <el-col :span="5" class="text-end">
            <p>
              <span>{{ deduzione.prezzo }}</span>
            </p>
          </el-col>
        </el-row>
        <el-row v-if="imponibile != null">
          <el-col :span="14" :offset="1" class="text-end">
            <span class="fw-bold">{{ imponibile.descrizione }}</span>
          </el-col>
          <el-col :span="4" class="text-center">
            <span class="fw-bold">€</span>
          </el-col>
          <el-col :span="5" class="text-end">
            <p>
              <span>{{ imponibile.prezzo }}</span>
            </p>
          </el-col>
        </el-row>
        <el-row class="mt-4" v-if="ritenutaFiscale != null">
          <el-col :span="14" :offset="1" class="text-end">
            <p>
              <span class="fw-bold">{{ ritenutaFiscale.descrizione }}</span>
            </p>
          </el-col>
          <el-col :span="4" class="text-center">
            <span class="fw-bold">€</span>
          </el-col>
          <el-col :span="5" class="text-end">
            <p>
              <span>{{ ritenutaFiscale.prezzo }}</span>
            </p>
          </el-col>
        </el-row>
        <!-- <el-row class="mt-3">
                  <el-col :span="14" :offset="1" class="text-end">
                    <p>
                      <span class="fw-bold">Totale</span>
                    </p>
                  </el-col>
                  <el-col :span="4" class="text-center">
                    <span class="fw-bold">€</span>
                  </el-col>
                  <el-col :span="5" class="text-start">
                    <p><span>0000,00</span></p>
                  </el-col>
                </el-row>-->
        <el-row class="mt-5" v-if="totaleDovuto != null">
          <el-col :span="14" :offset="1" class="text-end">
            <p>
              <span class="fw-bold">{{ totaleDovuto.descrizione }}</span>
            </p>
          </el-col>
          <el-col :span="4" class="text-center">
            <span class="fw-bold">€</span>
          </el-col>
          <el-col :span="5" class="text-end">
            <p>
              <span>{{ totaleDovuto.totale }}</span>
            </p>
          </el-col>
        </el-row>
        <div class="border-footer-first-page">
          <div class="row mt-2 justify-content-between">
            <div class="col-5 ms-4">Numero Fattura: {{ fattura.numero }}</div>
            <div class="col-5 text-end me-4">Pagina 1/{{ pagine + 1 }}</div>
          </div>
        </div>
        <div class="html2pdf__page-break"></div>
        <div
          class="row mt-5 justify-content-center"
          v-for="n in pagine"
          :key="n"
        >
          <table class="table">
            <thead class="text-center" v-if="n < 2">
              <tr>
                <td class="intestazione-tabella">Cliente</td>
                <td class="table-active intestazione-tabella">Numero</td>
                <td class="table-active intestazione-tabella">Data</td>
                <td class="table-active intestazione-tabella">Qualifica</td>
                <td class="table-active intestazione-tabella">Livello</td>
                <td class="table-active intestazione-tabella">Pagato</td>
                <td class="intestazione-tabella">Agente</td>
                <td class="intestazione-tabella">Tipo</td>
                <td class="table-active intestazione-tabella">%</td>
                <td class="table-active intestazione-tabella">Importo</td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in provvigioniTableData[n - 1]"
                :key="item.orderID"
              >
                <td class="dato-tabella" v-if="item.customer != null">
                  {{ item.customer }}
                </td>
                <td class="dato-tabella" v-else>
                  {{ item.billingEmail }}
                </td>
                <td class="dato-tabella text-center">
                  {{ item.orderID }}
                </td>
                <td class="dato-tabella text-center">
                  {{ item.orderCreated }}
                </td>

                <td class="dato-tabella text-center">
                  {{ item.qualifica }}
                </td>
                <td class="dato-tabella text-center">
                  {{ item.livello }}
                </td>
                <td class="dato-tabella text-center">{{ item.total }}€</td>
                <td class="dato-tabella text-center">
                  {{ item.nome }} {{ item.cognome }}
                </td>
                <td class="dato-tabella text-center">
                  {{ item.tipo }}
                </td>
                <td class="dato-tabella text-center">
                  {{ item.percentuale }}%
                </td>
                <td class="dato-tabella text-center">{{ item.valore }}€</td>
              </tr>
            </tbody>
          </table>
          <div :style="marginTop[n - 1]">
            <div class="border-footer">
              <div class="row mt-2 justify-content-between">
                <div class="col-5 ms-4">
                  Numero Fattura: {{ fattura.numero }}
                </div>
                <div class="col-5 text-end me-4">
                  Pagina {{ n + 1 }}/{{ pagine + 1 }}
                </div>
              </div>
            </div>
          </div>
          <div class="html2pdf__page-break"></div>
        </div>
      </div>
    </template>
  </vue-html2pdf>
</template>
<script>
import VueHtml2pdf from "vue3-html2pdf";
import moment from "moment";
export default {
  props: [
    "fattura",
    "provvigioni",
    "riporto",
    "totale",
    "deduzione",
    "imponibile",
    "ritenutaFiscale",
    "totaleDovuto",
    "provvigioniTableData",
    "pagine",
    "marginTop",
    "totaleIVA",
    "nomeFattura",
    "showLayout",
    "floatLayout",
  ],
  components: { VueHtml2pdf },
  data() {
    return {
      htmlToPdfOptions: {
        margin: 10,
        filename: "",
        enableLinks: false,

        html2canvas: {
          scale: 3,
          useCORS: true,
        },

        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
          compress: true,
        },
      },
      orders: [],
      loading: false,
      ordersPage: 0,
    };
  },
  created() {},

  methods: {
    onClickDownload() {
      this.htmlToPdfOptions.filename = this.nomeFattura;
      this.$refs.html2Pdf.generatePdf();
    },

    initTable(data) {
      data.forEach((element) => {
        element.orderCreated = moment(element.orderCreated).format(
          "DD/MM/YYYY"
        );
        element.created = moment(element.created).format("DD/MM/YYYY");
        element.modified = moment(element.modified).format("DD/MM/YYYY");
        element.discountTotal = element.discountTotal.toFixed(2) + "€";
        element.discountTax = element.discountTax.toFixed(2) + "€";
        element.shippingTotal = element.shippingTotal.toFixed(2) + "€";
        element.shippingTax = element.shippingTax.toFixed(2) + "€";
        element.total = element.total.toFixed(2) + "€";
        element.totalTax = element.totalTax.toFixed(2) + "€";
      });
      return data;
    },
  },
};
</script>

<style lang="scss" scoped>
.ricevuta {
  font-size: 1.3rem;
}
.border-intestazione {
  border-bottom: 8px solid #a18103;
}
.box-model {
  width: 350px;
  border: 1px solid black;
  padding: 10px;
  margin: 4px;
}
.border-footer {
  border-top: 5px solid #a18103;
}
.intestazione-tabella {
  font-size: 0.65rem;
  font-weight: bold;
}
.dato-tabella {
  font-size: 0.55rem;
}
.border-footer-first-page {
  margin-top: 78px;
  border-top: 5px solid #a18103;
}

.background {
  background-color: white;
}
</style>
<template>
  <slot :id="id" :refresh="refresh" :currentID="currentID"></slot>
  <div class="row mt-3 text-center">
    <div class="col">
      <span class="fw-bold fs-5">Provvigioni</span>
    </div>
  </div>
    <div v-if="loadingProvvigioni" class="d-flex justify-content-center mt-3 mb-3">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div class="row mt-3 justify-content-center" v-else>
    <div class="col-10">
      <table class="table table-striped table-hover table-responsive">
        <thead>
          <tr>
            <td class="fw-bold text-center">Tipo</td>
            <td class="fw-bold text-center">Numero Rendiconto</td>
            <td class="fw-bold text-center">Stato</td>
            <td class="fw-bold text-center">Agente</td>
            <td class="fw-bold text-center">Qualifica</td>
            <td class="fw-bold text-center">Livello</td>
            <td class="fw-bold text-center">Percentuale</td>
            <td class="fw-bold text-center">Valore</td>
          </tr>
        </thead>
        <tbody v-for="item in provvigioni" :key="item">
          <tr>
            <td class="text-center">{{ item.tipo }}</td>
            <td class="text-center">{{ item.numeroRendiconto }}</td>
            <td class="text-center">{{ item.stato }}</td>
            <td class="text-center">{{ item.agente }}</td>
            <td class="text-center">{{ item.qualificaAgente }}</td>
            <td class="text-center">{{ item.livello }}</td>
            <td class="text-center">{{ item.percentuale }}</td>
            <td class="text-center">{{ item.valore }}</td>
          </tr>
          <tr>
            <td v-if="item.note != null" colspan="8" class="text-center">
              <pre>{{ item.note }}</pre>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { FetchProvvigioniOrdine } from "@/api/fetchData.js";
export default {
  props: ["id", "refresh", "currentID"],
  emits: ["refreshCompleted"],
  data() {
    return {
      provvigioni: [],
      loadingProvvigioni: false,
    };
  },
  created() {
    this.loadProvvigioni();
  },
  watch: {
    refresh: function () {
      this.refreshTable();
    },
  },
  methods: {
    async refreshTable() {
      if (this.refresh == true && this.currentID == this.id) {
        await this.loadProvvigioni().then(() => {
          this.$emit("refreshCompleted");
        });
      }
    },
    async loadProvvigioni() {
      this.loadingProvvigioni = true;
      await FetchProvvigioniOrdine(this.id)
        .then((response) => {
          this.provvigioni = response.data;
          this.provvigioni.forEach((element) => {
            element.total = element.total.toFixed(2) + "€";
            element.valore = element.valore.toFixed(2) + "€";
            element.percentuale = element.percentuale + "%";
            element.dataOra = moment(element.dataOra).format(
              "DD/MM/YYYY, HH:mm:ss"
            );

            if (element.agenteCodice != null) {
              element.agente =
                element.agente + " (" + element.agenteCodice + ")";
            }
          });
        })
        .catch((error) =>
          window.alert("Attenzione, si è verificato un errore " + error)
        )
        .finally(() => (this.loadingProvvigioni = false));
    },
  },
};
</script>

<style>
</style>
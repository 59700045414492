<template>
  <div v-if="loading" class="d-flex justify-content-center">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div v-else>
    <div class="table-responsive">
      <table class="table table-striped text-center">
        <thead>
          <tr>
            <td class="fw-bold">Data Ordine</td>
            <td class="fw-bold">ID Ordine</td>
            <td class="fw-bold">Agente</td>
            <td class="fw-bold">Codice Agente</td>
            <td class="fw-bold">Totale</td>
            <td class="fw-bold">IVA</td>
            <td class="fw-bold">Coupon</td>
            <td class="fw-bold">Spese di Spedizione</td>
            <td class="fw-bold">Stato</td>
            <td class="fw-bold">Email Cliente</td>
            <td class="fw-bold">Azioni</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in orders" :key="item.pkCustomerOrder">
            <td>{{ item.orderCreated }}</td>
            <td>{{ item.orderID }}</td>
            <td>{{ item.agente }}</td>
            <td>{{ item.agenteCodice }}</td>
            <td>{{ item.total }}</td>
            <td>{{ item.totalTax }}</td>
            <td>{{ item.discountTotal }}</td>
            <td>{{ item.shippingTotal }}</td>
            <td>{{ item.status }}</td>
            <td>{{ item.billingEmail }}</td>
            <td>
              <el-button
                type="primary"
                size="mini"
                @click="handleUpdate(item.pkCustomerOrder)"
              >
                Modifica
              </el-button>
              <el-button size="mini" type="danger"> Elimina </el-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <loading-mobile-table
      @loadMoreOrder="handleloadMore"
      :pageNumber="pageNumber"
      :pager="pager"
      :noMoreOrders="noMoreOrders"
      class="mt-2"
    ></loading-mobile-table>
  </div>
</template>

<script>
import moment from "moment";
import { FetchCustomerOrder } from "@/api/fetchData.js";
import LoadingMobileTable from "@/components/CustomerOrder/LoadingMobileTable";
export default {
  components: { LoadingMobileTable },
  data() {
    return {
      // search: null,
      statusSearch: null,
      agentSearch: null,
      dateSearch: null,
      loading: false,
      pageNumber: 1,
      pageSize: 10,
      pager: [],
      orders: [],
      status: [
        {
          value: "Creato",
          label: "Creato",
        },
        {
          value: "Pagato",
          label: "Pagato",
        },
        {
          value: "Calcolato",
          label: "Calcolato",
        },
      ],
      agents: [],
      loadingAfterScroll: false,
      noMoreOrders: false,
      ordersSearch: {
        DataInizio: undefined,
        DataFine: undefined,
        PkAgenti: undefined,
        Ricerca: undefined,
        Status: undefined,
      },
    };
  },
  created() {
    this.loadData();
  },

  methods: {
    initTable(data) {
      data.forEach((element) => {
        element.orderCreated = moment(element.orderCreated).format(
          "DD/MM/YYYY, HH:mm:ss"
        );
        element.discountTotal =
          (element.discountTotal + element.discountTax).toFixed(2) + "€";

        element.shippingTotal =
          (element.shippingTotal + element.shippingTax).toFixed(2) + "€";
        element.total = element.total.toFixed(2) + "€";
        element.totalTax = element.totalTax.toFixed(2) + "€";
      });
      return data;
    },
    async loadData() {
      this.loading = true;
      await FetchCustomerOrder(
        this.pageNumber,
        this.pageSize,
        this.ordersSearch.DataInizio,
        this.ordersSearch.DataFine,
        this.ordersSearch.PkAgenti,
        this.ordersSearch.Ricerca,
        this.ordersSearch.Status
      ).then((response) => {
        this.orders = this.initTable(response.data);
        this.pager = JSON.parse(response.headers["x-pagination"]);
        this.pageNumber++;
      });
      this.loading = false;
    },
    handleloadMore(value) {
      this.orders = this.orders.concat(value);
      if (this.pageNumber < this.pager.TotalPages) {
        this.pageNumber++;
      } else {
        this.noMoreOrders = true;
      }
    },
  },
};
</script>

<style>
</style>
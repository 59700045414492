<template>
  <slot :agents="agents"></slot>
  <el-button
    size="medium"
    type="success"
    icon="el-icon-edit"
    @click="handleCreate"
  >
    Crea un nuovo ordine
  </el-button>

  <el-dialog
    v-model="dialogFormCreateVisible"
    :before-close="handleClose"
    center
  >
    <template #title>
      <span class="fw-bold">Crea nuovo ordine</span>
    </template>
    <div
      :class="[
        'alert',
        'text-center',
        submitResult ? 'alert-success' : 'alert-danger',
      ]"
      v-if="isSubmitted"
    >
      {{ submitResult ? axiosSuccessMessage : axiosErrorMessage }}
    </div>
    <el-form show-message label-position="left" label-width="220px">
      <el-form-item label="ID Ordine" :error="orderIDField.errorMessage">
        <el-input
          :model-value="orderIDField.value"
          type="number"
          @update:model-value="orderIDField.handleChange"
          @change="formModified = true"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="Referral ID" :error="referralIDField.errorMessage">
        <el-button
          type="primary"
          class="me-3"
          @click="handleReferralDialogVisible"
          >Modifica</el-button
        >
        <el-input
          style="width: 15%"
          disabled
          :model-value="referralIDField.value"
          type="text"
          @click="handleReferralDialogVisible"
          @update:model-value="referralIDField.handleChange"
        >
        </el-input>
        <span class="ms-2">{{ referralIDNome }}</span>
      </el-form-item>
      <el-form-item
        label="L'ordine è stato pagato"
        :error="paymentField.errorMessage"
        style="width: 150px"
      >
        <el-radio-group
          @change="paymentField.handleChange"
          v-model="paymentField.value"
        >
          <el-radio label="Si">Si</el-radio>
          <el-radio label="No">No</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="Email"
        :error="billingEmailField.errorMessage"
        @change="formModified = true"
      >
        <el-input
          :model-value="billingEmailField.value"
          type="text"
          @update:model-value="billingEmailField.handleChange"
          @change="formModified = true"
        >
        </el-input>
      </el-form-item>
      <el-form-item
        label="Cliente"
        :error="customerField.errorMessage"
        @change="formModified = true"
      >
        <el-input
          :model-value="customerField.value"
          type="text"
          @update:model-value="customerField.handleChange"
          @change="formModified = true"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="Totale Lordo" :error="totalField.errorMessage">
        <el-input
          :model-value="totalField.value"
          type="number"
          @update:model-value="totalField.handleChange"
          @keyup="calcoloTotali"
        >
        </el-input>
      </el-form-item>

      <el-form-item label="IVA" :error="totalTaxField.errorMessage">
        <el-input
          :model-value="totalTaxField.value"
          type="number"
          @update:model-value="totalTaxField.handleChange"
          @change="formModified = true"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="Totale Netto" :error="totalNoTaxField.errorMessage">
        <el-input
          disabled
          :model-value="totalNoTaxField.value"
          type="number"
          @update:model-value="totalNoTaxField.handleChange"
          @change="formModified = true"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="Coupon" :error="discountTotalField.errorMessage">
        <el-input
          :model-value="discountTotalField.value"
          type="number"
          @update:model-value="discountTotalField.handleChange"
          @change="formModified = true"
        >
        </el-input>
      </el-form-item>
      <el-form-item
        label="Spese di Spedizione"
        :error="shippingTotalField.errorMessage"
      >
        <el-input
          :model-value="shippingTotalField.value"
          type="number"
          @update:model-value="shippingTotalField.handleChange"
          @keyup="calcoloTotali"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="Tipo Ordine" :error="tipoOrdineField.errorMessage">
        <el-select
          :model-value="tipoOrdineField.value"
          @update:model-value="tipoOrdineField.handleChange"
          @change="formModified = true"
        >
          <el-option
            v-for="item in tipoOrdine"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Data Ordine">
        <el-date-picker
          style="width: 100%"
          type="datetime"
          placeholder="gg/mm/aaaa hh:mm:ss"
          format="DD/MM/YYYY HH:mm:ss"
          name="NascitaData"
          v-model="orderCreatedField.value"
          @update:v-model="orderCreatedField.handleChange"
        >
          >
        </el-date-picker>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="info" @click="handleClose"> Chiudi </el-button>
        <el-button
          type="primary"
          @click="submitCreate"
          :loading="loadingCreate"
          :disabled="createButtonDisabled"
          v-if="!submitResult"
        >
          Salva
        </el-button>
      </span></template
    >
  </el-dialog>

  <el-dialog center v-model="refIdDialog" width="600px" append-to-body>
    <template #title>
      <span class="fw-bold">Seleziona un Agente</span>
    </template>
    <div class="row">
      <div class="col text-center">
        <el-select
          v-model="referralID"
          placeholder="Seleziona Agente"
          filterable
          size="medium"
          class="ms-3"
          style="width: 260px"
          ><el-option
            v-for="item in agents"
            :key="item.pkAgente"
            :label="item.nome + ' ' + item.cognome + ' (' + item.codice + ')'"
            :value="
              item.codice +
              '&' +
              item.pkAgente +
              '&' +
              item.nome +
              ' ' +
              item.cognome
            "
          ></el-option>
        </el-select>
      </div>
    </div>
    <template #footer>
      <el-button type="info" @click="refIdDialog = false"> Indietro </el-button>
      <el-button type="primary" @click="handleReferralChange">
        Conferma
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import moment from "moment";
import { CreateCustomerOrder } from "@/api/modifyData.js";
import { useField, useForm } from "vee-validate";
import { reactive } from "vue";
import * as yup from "yup";
export default {
  emits: ["createSuccess"],
  setup() {
    const { meta: formMeta, handleSubmit, resetForm, handleReset } = useForm();
    const paymentField = reactive(useField("payment"));
    const parentIDField = reactive(useField("parentID"));
    const customerField = reactive(useField("customer"));
    const orderCreatedField = reactive(
      useField("orderCreated", yup.string().required("Campo richiesto"))
    );
    const tipoOrdineField = reactive(
      useField("tipoOrdine"),
      yup.string().required("Campo richiesto")
    );
    const orderIDField = reactive(useField("orderID", yup.string().nullable()));
    const referralIDField = reactive(
      useField("referralID", yup.string().required("Campo richiesto"))
    );
    const billingEmailField = reactive(
      useField(
        "billingEmail",
        yup
          .string()
          .required("Campo richiesto")
          .email("inserire un email valida")
      )
    );
    const totalField = reactive(
      useField("total", yup.string().required("Campo richiesto"))
    );
    const totalNoTaxField = reactive(useField("totalNoTax"));
    const totalTaxField = reactive(
      useField(
        "totalTax",
        yup
          .string()
          .required("Campo richiesto")
          .test(
            "IVA positiva",
            "L'IVA non può essere negativa",
            function (value) {
              return value >= 0;
            }
          )
      )
    );
    const discountTotalField = reactive(
      useField("discountTotal", yup.string())
    );
    const discountTaxField = reactive(useField("discountTax", yup.string()));
    const shippingTotalField = reactive(
      useField("shippingTotal", yup.string().required("Campo richiesto"))
    );
    const shippingTaxField = reactive(useField("shippingTax", yup.string()));

    return {
      customerField,
      tipoOrdineField,
      totalNoTaxField,
      paymentField,
      parentIDField,
      orderCreatedField,
      referralIDField,
      handleSubmit,
      formMeta,
      billingEmailField,
      resetForm,
      handleReset,
      totalField,
      totalTaxField,
      discountTotalField,
      discountTaxField,
      shippingTotalField,
      shippingTaxField,
      orderIDField,
    };
  },
  props: ["agents"],
  data() {
    return {
      referralIDNome: "",
      isSubmitted: false,
      submitResult: false,
      dialogFormCreateVisible: false,
      axiosSuccessMessage: "",
      axiosErrorMessage: "",
      submitCreate: this.handleSubmit(this.onSubmitCreate),
      formModified: false,
      referralID: "",
      refIdDialog: false,
      createButtonDisabled: false,
      loadingCreate: false,
      tipoOrdine: [
        {
          value: "Vendita",
          label: "Vendita",
        },
        {
          value: "Licenza",
          label: "Licenza",
        },
      ],
    };
  },
  methods: {
    calcoloTotali() {
      this.formModified = true;
      if (
        this.totalField.value != undefined &&
        this.shippingTotalField.value != undefined
      ) {
        this.totalTaxField.value =
          this.totalField.value -
          this.shippingTotalField.value -
          (this.totalField.value - this.shippingTotalField.value) / 1.22;
        this.totalTaxField.value = this.totalTaxField.value.toFixed(2);
      }
      if (this.totalTaxField.value != undefined) {
        this.totalNoTaxField.value =
          this.totalField.value - this.totalTaxField.value;
        this.totalNoTaxField.value = this.totalNoTaxField.value.toFixed(2);
      }
    },
    handleReferralChange() {
      let referral = this.referralID.split("&");
      this.referralIDField.value = referral[0];
      this.parentIDField.value = referral[1];
      this.referralIDNome = referral[2];
      this.refIdDialog = false;
    },
    handleReferralClose() {
      this.refIdDialog = false;
      this.formModified = true;
    },
    handleReferralDialogVisible() {
      this.refIdDialog = true;
      this.referralID = "";
    },
    handleClose(done) {
      if (this.formModified == true) {
        this.$confirm("Are you sure to close this dialog?")
          .then(() => {
            done;
            this.dialogFormUpdateVisible = false;
            this.dialogFormCreateVisible = false;
            this.formModified = false;
          })
          .catch(() => {});
      } else {
        this.dialogFormUpdateVisible = false;
        this.dialogFormCreateVisible = false;
      }
    },
    initForm() {
      this.orderIDField.value = null;
      this.totalField.value = 0;
      this.totalTaxField.value = 0;
      this.totalNoTaxField.value = 0;
      this.discountTotalField.value = 0;
      this.shippingTotalField.value = 0;
      this.orderCreatedField.value = moment()
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSSZ");
      this.shippingTaxField.value = 0;
    },
    handleCreate() {
      this.referralIDNome = "";
      this.isSubmitted = false;
      this.submitResult = false;
      this.dialogFormCreateVisible = true;
      this.resetForm();
      this.initForm();
    },
    async onSubmitCreate(formValues) {
      this.loadingCreate = false;
      this.createButtonDisabled = true;
      this.isSubmitted = false;
      formValues.orderCreated = moment
        .utc(formValues.orderCreated)
        .local()
        .format();
      if (formValues.payment === "Si") {
        let transactionId = { transactionId: "MANUALE" };
        let paymentMethod = { paymentMethod: "MANUALE" };
        Object.assign(formValues, transactionId);
        Object.assign(formValues, paymentMethod);
      }
      if (formValues.discountTotal != 0) {
        formValues.discountTax =
          formValues.discountTotal - formValues.discountTotal / 1.22;
        formValues.discountTax = formValues.discountTax.toFixed(2);
        formValues.discountTotal =
          formValues.discountTotal - formValues.discountTax;
      } else {
        formValues.discountTax = 0;
      }
      if (formValues.orderID == undefined) {
        formValues.orderID = null;
      }
      await CreateCustomerOrder(formValues)
        .then(() => {
          this.isSubmitted = true;
          this.submitResult = true;
          this.axiosSuccessMessage = "Dati creati con successo";
          this.formModified = false;
          this.$emit("createSuccess");
        })
        .catch((error) => {
          this.isSubmitted = true;
          this.submitResult = false;
          this.axiosErrorMessage = "Creazione fallita";
          if (error != undefined) {
            if (
              error.response != undefined &&
              error.response.data != undefined &&
              error.response.data.detail != undefined
            ) {
              this.axiosErrorMessage += " : " + error.response.data.detail;
            }
            if (
              error.response != undefined &&
              error.response.data != undefined &&
              error.response.errors != undefined
            ) {
              let keys = Object.keys(error.response.data.errors);
              if (keys.indexOf("OrderID") !== -1) {
                this.orderIDField.errors.push(
                  error.response.data.errors.OrderID[0]
                );
              }
              if (keys.indexOf("ReferralID") !== -1) {
                this.referralIDField.errors.push(
                  error.response.data.errors.ReferralID[0]
                );
              }
              if (keys.indexOf("BillinEmail") !== -1) {
                this.billinEmailField.errors.push(
                  error.response.data.errors.BillinEmail[0]
                );
              }
              if (keys.indexOf("Status") !== -1) {
                this.statusField.errors.push(
                  error.response.data.errors.Status[0]
                );
              }
              if (keys.indexOf("Currency") !== -1) {
                this.currencyField.errors.push(
                  error.response.data.errors.Currency[0]
                );
              }
              if (keys.indexOf("Version") !== -1) {
                this.versionField.errors.push(
                  error.response.data.errors.Version[0]
                );
              }
              if (keys.indexOf("Total") !== -1) {
                this.totalField.errors.push(
                  error.response.data.errors.Total[0]
                );
              }
              if (keys.indexOf("TotalTax") !== -1) {
                this.totalTaxField.errors.push(
                  error.response.data.errors.TotalTax[0]
                );
              }
              if (keys.indexOf("DiscountTotal") !== -1) {
                this.discountTotalField.errors.push(
                  error.response.data.errors.DiscountTotal[0]
                );
              }
              if (keys.indexOf("DiscountTax") !== -1) {
                this.discountTaxField.errors.push(
                  error.response.data.errors.DiscountTax[0]
                );
              }
              if (keys.indexOf("ShippingTotal") !== -1) {
                this.shippingTotalField.errors.push(
                  error.response.data.errors.ShippingTotal[0]
                );
              }
              if (keys.indexOf("ShippingTax") !== -1) {
                this.shippingTaxField.errors.push(
                  error.response.data.errors.ShippingTax[0]
                );
              }
            }
          }
        })
        .finally(() => {
          this.createButtonDisabled = false;
          this.loadingCreate = false;
        });
    },
  },
};
</script>

<style>
</style>
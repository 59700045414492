<template>
  <el-table
    v-loading="loading"
    :data="licenze"
    stripe
    fit
    highlight-current-row
    border
  >
    <el-table-column label="N° licenza" prop="pkAgenteLicenza" align="center" />
    <el-table-column
      label="Data Inizio"
      prop="validitaDataInizio"
      align="center"
    />
    <el-table-column label="Data Fine" prop="validitaDataFine" align="center" />
    <el-table-column
      label="Scadenza"
      prop="validitaDataScadenza"
      align="center"
    />
    <el-table-column label="Rinnovo" prop="rinnovoData" align="center" />
    <el-table-column
      label="Fine Attività"
      prop="fineAttivitaData"
      align="center"
    />
  </el-table>
  <slot :id="id"></slot>
</template>

<script>
import moment from "moment";
import { FetchLicenzeAgenti } from "../../../api/fetchData";
export default {
  props: ["id"],
  created() {
    this.fetchLicenzeAgenti();
  },
  data() {
    return {
      licenze: [],
      loading: false,
    };
  },
  methods: {
    initTable(data) {
      data.forEach((element) => {
        if (element.validitaDataInizio) {
          element.validitaDataInizio = moment(
            element.validitaDataInizio
          ).format("DD/MM/YYYY");
        }
        if (element.validitaDataFine) {
          element.validitaDataFine = moment(element.validitaDataFine).format(
            "DD/MM/YYYY"
          );
        }
        if (element.validitaDataScadenza) {
          element.validitaDataScadenza = moment(
            element.validitaDataScadenza
          ).format("DD/MM/YYYY");
        }
        if (element.rinnovoData) {
          element.rinnovoData = moment(element.rinnovoData).format(
            "DD/MM/YYYY"
          );
        }
        if (element.fineAttivitaData) {
          element.fineAttivitaData = moment(element.fineAttivitaData).format(
            "DD/MM/YYYY"
          );
        }
      });
      return data;
    },
    async fetchLicenzeAgenti() {
      this.loading = true;
      if (this.id != undefined) {
        await FetchLicenzeAgenti(this.id)
          .then((response) => {
            this.licenze = this.initTable(response.data);
          })
          .catch(() => {
            alert("Errore durante il caricamento dei dati");
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style>
</style>
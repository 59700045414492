<template>
  <div class="container-fluid">
    <div v-if="device === 'mobile'">
      <TableMobile />
    </div>
    <div v-else>
      <Table />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TableMobile from "../../components/Provvigioni/User/TableMobile.vue";
import Table from "../../components/Provvigioni/User/Table.vue"
export default {
  components: { TableMobile, Table },
  computed: {
    ...mapState({
      device: (state) => state.app.device,
    }),
  },
};
</script>

<style>
</style>
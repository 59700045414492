<template>
  <el-card class="box-card" shadow="never" v-loading="loadingCard">
    <template #header>
      <p class="text-center fs-3 fw-bold">Conto Corrente</p>
    </template>
    <div v-if="fetchBankAccountError == true">
      <div class="text-center">
        <i class="el-icon-warning-outline"></i>
        <h2 class="warning-title fw-bold">Ooops...</h2>
        <p class="warning-description h4">
          Qualcosa è andato storto, Riprova!
        </p>
      </div>

      <div class="d-grid gap-2 col-3 mx-auto">
        <el-button type="danger" @click="tryAgain">Riprova</el-button>
      </div>
    </div>
    <div v-else>
      <dl class="row">
        <dt class="col-sm-3 fs-6 fw-bold">Banca:</dt>
        <dd class="col-sm-9 fs-6">{{ bankAccount.banca }}</dd>
        <el-divider></el-divider>
        <dt class="col-sm-3 fs-6 fw-bold">Filiale:</dt>
        <dd class="col-sm-9 fs-6">{{ bankAccount.filiale }}</dd>
        <el-divider></el-divider>
        <dt class="col-sm-3 fs-6 fw-bold">IBAN:</dt>
        <dd class="col-sm-9 fs-6">{{ bankAccount.iban }}</dd>
        <el-divider></el-divider>
        <dt class="col-sm-3 fs-6 fw-bold">ABI:</dt>
        <dd class="col-sm-9 fs-6">{{ bankAccount.abi }}</dd>
        <el-divider></el-divider>
        <dt class="col-sm-3 fs-6 fw-bold">CAB:</dt>
        <dd class="col-sm-9 fs-6">{{ bankAccount.cab }}</dd>
        <el-divider></el-divider>
        <dt class="col-sm-3 fs-6 fw-bold">Conto:</dt>
        <dd class="col-sm-9 fs-6">{{ bankAccount.conto }}</dd>
        <el-divider></el-divider>
      </dl>
      <div class="d-grid gap-2 col-3 mx-auto">
        <el-button type="primary" icon="el-icon-edit" @click="handleUpdate"
          >Edit</el-button
        >
      </div>
    </div>
  </el-card>
  <el-dialog v-model="dialogFormVisible" :before-close="handleClose" :width="device === 'mobile' ? '80%' : '50%'" center>
    <template #title>
      <span class="fw-bold">Modifica Conto Corrente</span>
    </template>
    <div
      :class="[
        'alert',
        'text-center',
        submitResult ? 'alert-success' : 'alert-danger',
      ]"
      v-if="isSubmitted"
    >
      {{ submitResult ? axiosSuccessMessage : axiosErrorMessage }}
    </div>
    <el-form show-message :label-position="device === 'mobile' ? 'top' : 'left'" label-width="120px">
      <el-form-item label="Banca" :error="bancaField.errorMessage">
        <el-input
          type="text"
          @change="handleChange"
          :model-value="bancaField.value"
          @update:model-value="bancaField.handleChange"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="Filiale" :error="filialeField.errorMessage">
        <el-input
          type="text"
          @change="handleChange"
          :model-value="filialeField.value"
          @update:model-value="filialeField.handleChange"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="IBAN" :error="ibanField.errorMessage">
        <el-input
          type="text"
          @change="handleChange"
          :model-value="ibanField.value"
          @update:model-value="ibanField.handleChange"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="ABI" :error="abiField.errorMessage">
        <el-input
          type="text"
          @change="handleChange"
          :model-value="abiField.value"
          @update:model-value="abiField.handleChange"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="ABI" :error="abiField.errorMessage">
        <el-input
          type="text"
          @change="handleChange"
          :model-value="abiField.value"
          @update:model-value="abiField.handleChange"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="CAB" :error="cabField.errorMessage">
        <el-input
          type="text"
          @change="handleChange"
          :model-value="cabField.value"
          @update:model-value="cabField.handleChange"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="Conto" :error="contoField.errorMessage">
        <el-input
          type="text"
          @change="handleChange"
          :model-value="contoField.value"
          @update:model-value="contoField.handleChange"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="info" @click="handleClose"> Indietro </el-button>
        <el-button :loading="loading" type="primary" @click="submitForm">
          Conferma
        </el-button>
      </span></template
    >
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import { FetchBankAccount } from "@/api/fetchData.js";
import { useField, useForm } from "vee-validate";
import { UpdateBankAccount } from "@/api/modifyData.js";
import { reactive } from "vue";
import * as yup from "yup";
export default {
    computed: {
    ...mapState({
      device: (state) => state.app.device,
    }),
  },
  setup() {
    const { meta: formMeta, handleSubmit, resetForm, handleReset } = useForm();
    const bancaField = reactive(
      useField("banca", yup.string().required("Campo richiesto").nullable())
    );
    const filialeField = reactive(useField("filiale", yup.string().nullable()));
    const ibanField = reactive(
      useField("iban", yup.string().required("Campo richiesto").nullable())
    );
    const abiField = reactive(useField("abi", yup.string().nullable()));
    const cabField = reactive(useField("cab", yup.string().nullable()));
    const contoField = reactive(useField("conto", yup.string().nullable()));
    return {
      formMeta,
      handleSubmit,
      resetForm,
      handleReset,
      bancaField,
      filialeField,
      abiField,
      ibanField,
      cabField,
      contoField,
    };
  },
  data() {
    return {
      bankAccount: [],
      dialogFormVisible: false,

      formModified: false,
      isSubmitted: false,
      submitResult: false,
      axiosErrorMessage: "",
      axiosSuccessMessage: "",
      submitForm: this.handleSubmit(this.onSubmit),
      loading: false,
      loadingCard: false,
      fetchNationsError: false,
      fetchBankAccountError: false,
    };
  },

  created() {
    this.fetchBankAccount();
  },
  methods: {
    handleChange() {
      this.formModified = true;
      this.isSubmitted = false;
    },
    tryAgain() {
      this.fetchBankAccount().then(() => {
        if (this.bankAccount == []) this.fetchBankAccountError = false;
        else {
          this.fetchBankAccountError = true;
        }
      });
    },
    async fetchBankAccount() {
      this.loadingCard = true;
      await FetchBankAccount()
        .then((response) => {
          this.bankAccount = response.data;
        })
        .catch(() => {
          this.fetchBankAccountError = true;
        });
      this.loadingCard = false;
    },
    async onSubmit(formValues) {
      this.loading = true;
      this.isSubmitted = false;
      await UpdateBankAccount(formValues)
        .then(() => {
          this.isSubmitted = true;
          this.submitResult = true;
          this.axiosSuccessMessage = "Dati aggiornati con successo";
          this.formModified = false;
          this.fetchBankAccount();
        })
        .catch((error) => {
          this.isSubmitted = true;
          this.submitResult = false;
          this.axiosErrorMessage = "Aggiornamento dati fallito";
          let keys = Object.keys(error.response.data.errors);
          if (keys.indexOf("banca") !== -1) {
            this.bancaField.errors.push(error.response.data.errors.banca[0]);
          }
          if (keys.indexOf("filiale") !== -1) {
            this.filialeField.errors.push(
              error.response.data.errors.filiale[0]
            );
          }
          if (keys.indexOf("iban") !== -1) {
            this.ibanField.errors.push(error.response.data.errors.iban[0]);
          }
          if (keys.indexOf("abi") !== -1) {
            this.abiField.errors.push(error.response.data.errors.abi[0]);
          }
          if (keys.indexOf("cab") !== -1) {
            this.cabField.errors.push(error.response.data.errors.cab[0]);
          }
          if (keys.indexOf("conto") !== -1) {
            this.contoField.errors.push(error.response.data.errors.conto[0]);
          }
        });
      this.loading = false;
    },
    handleUpdate() {
      this.dialogFormVisible = true;
      this.initForm();
    },
    initForm() {
      this.isSubmitted = false;
      this.bancaField.value = this.bankAccount.banca;
      this.filialeField.value = this.bankAccount.filiale;
      this.ibanField.value = this.bankAccount.iban;
      this.abiField.value = this.bankAccount.abi;
      this.cabField.value = this.bankAccount.cab;
      this.contoField.value = this.bankAccount.conto;
    },
    handleClose(done) {
      if (this.formModified == true) {
        this.$confirm("Sei sicuro di voler chiudere?")
          .then(() => {
            done;
            this.dialogFormVisible = false;

            this.formModified = false;
          })
          .catch(() => {});
      } else {
        this.dialogFormVisible = false;
      }
    },
  },
};
</script>

<style scoped>
.warning-description {
  color: #ff4949;
  padding: 4px;
  margin-bottom: 15px;
}
.warning-title {
  color: #ff4949;
  padding: 4px;
}
.el-icon-warning-outline {
  color: #ff4949;
  font-size: 150px;
  margin-bottom: 25px;
}
.box-card {
  margin-bottom: 25px;
}
.row {
  margin-bottom: 0px;
}
</style>
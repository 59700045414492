<template>
<div class="row mt-5 mb-4 text-center">
    <div class="col">
      <h2>Agenti</h2>
    </div>
  </div>
  <div class="container-fluid">
<Table />
  </div>
</template>

<script>
import Table from "../../components/Agenti/Admin/AgentiAdminTable.vue"
export default {
  components:{Table,}

}
</script>

<style>

</style>
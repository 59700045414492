<template>
  <div class="row mt-5 mb-4 text-center">
    <div class="col">
      <h2>Fatture</h2>
    </div>
  </div>
  <div class="container-fluid">
      <Table></Table>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Table from "../../components/Fatturazione/Admin/AdminTableFatture.vue"
export default {
    components:{Table},
    computed: {
    ...mapState({
      device: (state) => state.app.device,
    }),
  },

}
</script>

<style>

</style>
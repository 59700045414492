<template>
  <el-card class="box-card" shadow="never" v-loading="loadingCard">
    <template #header>
      <div class="row justify-content-center">
        <div class="col text-center">
          <el-avatar
            :size="device === 'mobile' ? 120 : 200"
            :src="circleUrl"
          ></el-avatar>
        </div>
        <p class="text-center fs-4 fw-bold">
          {{ agent.nome }} {{ agent.cognome }}
        </p>
      </div>
    </template>
    <div v-if="fetchAgentError == true">
      <div class="text-center">
        <i class="el-icon-warning-outline"></i>
        <h2 class="warning-title fw-bold">Ooops...</h2>
        <p class="warning-description h4">Qualcosa è andato storto, Riprova!</p>
      </div>
      <div class="d-grid gap-2 col-3 mx-auto">
        <el-button type="danger" @click="tryAgain">Riprova</el-button>
      </div>
    </div>
    <div v-else>
      <dl class="row">
        <dt class="col-sm-5 fs-6 fw-bold">Codice:</dt>
        <dd class="col-sm-7 fs-6">
          {{ agent.codice }}
        </dd>
        <el-divider></el-divider>
        <dt class="col-sm-5 fs-6 fw-bold">Inizio attività:</dt>
        <dd class="col-sm-7 fs-6">{{ agent.attivitaInizio }}</dd>
        <el-divider></el-divider>
        <dt class="col-sm-5 fs-6 fw-bold">Qualifica:</dt>
        <dd class="col-sm-7 fs-6">{{ agent.qualifica }}</dd>
        <el-divider></el-divider>
        <dt class="col-sm-5 fs-6 fw-bold">Nazionalità:</dt>
        <dd class="col-sm-7 fs-6">{{ agent.nazione }}</dd>
        <el-divider></el-divider>
      </dl>

   <!--   <div class="d-grid gap-2 col-3 mx-auto">
        <el-button type="primary" icon="el-icon-edit" @click="handleUpdate"
          >Edit</el-button
        >
      </div>-->
    </div>
  </el-card>
  <el-dialog
    v-model="dialogFormVisible"
    :before-close="handleClose"
    :width="device === 'mobile' ? '80%' : '50%'"
    center
  >
    <template #title>
      <span class="fw-bold">Modifica Profilo</span>
    </template>
    <div
      :class="[
        'alert',
        'text-center',
        submitResult ? 'alert-success' : 'alert-danger',
      ]"
      v-if="isSubmitted"
    >
      {{ submitResult ? axiosSuccessMessage : axiosErrorMessage }}
    </div>
    <el-form
      show-message
      :label-position="device === 'mobile' ? 'top' : 'left'"
      label-width="120px"
    >
      <el-form-item label="Nome" :error="nomeField.errorMessage">
        <el-input
          type="text"
          @change="handleChange"
          :model-value="nomeField.value"
          @update:model-value="nomeField.handleChange"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="Cognome" :error="cognomeField.errorMessage">
        <el-input
          type="text"
          @change="handleChange"
          :model-value="cognomeField.value"
          @update:model-value="cognomeField.handleChange"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="Nazionalità" :error="nazioneField.errorMessage">
        <el-select
          :style="device === 'mobile' ? 'width: 100%;' : ''"
          v-model="nazioneField.value"
          filterable
          placeholder="Select"
          @update:v-model="nazioneField.handleChange"
          @change="handleChange"
        >
          <el-option
            v-for="item in nations"
            :key="item.pkNazione"
            :label="item.nazione"
            :value="item.pkNazione"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="info" @click="handleClose"> Indietro </el-button>
        <el-button :loading="loading" type="primary" @click="submitForm">
          Conferma
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import { FetchAgent, FetchNations } from "@/api/fetchData.js";
import { useField, useForm } from "vee-validate";
import { UpdateAgent } from "@/api/modifyData.js";
import { reactive } from "vue";
import * as yup from "yup";
export default {
  computed: {
    ...mapState({
      device: (state) => state.app.device,
    }),
  },
  setup() {
    const { meta: formMeta, handleSubmit, resetForm, handleReset } = useForm();
    const nazioneField = reactive(
      useField("FkNazione", yup.string().required("Campo richiesto"))
    );
    const nomeField = reactive(
      useField("nome", yup.string().required("Campo richiesto"))
    );
    const cognomeField = reactive(
      useField("cognome", yup.string().required("Campo richiesto"))
    );
    return {
      formMeta,
      handleSubmit,
      resetForm,
      handleReset,
      nazioneField,
      nomeField,
      cognomeField,
    };
  },
  data() {
    return {
      agent: [],
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      dialogFormVisible: false,
      nations: [],
      formModified: false,
      isSubmitted: false,
      submitResult: false,
      axiosErrorMessage: "",
      axiosSuccessMessage: "",
      submitForm: this.handleSubmit(this.onSubmit),
      loading: false,
      loadingCard: false,
      fetchNationsError: false,
      fetchAgentError: false,
    };
  },

  created() {
    this.fetchAgent();
  },
  methods: {
    handleChange() {
      this.formModified = true;
      this.isSubmitted = false;
    },
    tryAgain() {
      this.fetchAgent().then(() => {
        if (this.agent == []) {
          this.fetchAgentError = false;
        } else {
          this.fetchAgentError = true;
        }
      });
    },
    async onSubmit(formValues) {
      this.loading = true;
      this.isSubmitted = false;
      await UpdateAgent(formValues)
        .then(() => {
          this.isSubmitted = true;
          this.submitResult = true;
          this.axiosSuccessMessage = "Dati aggiornati con successo";
          this.fetchAgent();
        })
        .catch((error) => {
          this.isSubmitted = true;
          this.submitResult = false;
          this.axiosErrorMessage = "Aggiornamento dati fallito";
          let keys = Object.keys(error.response.data.errors);
          if (keys.indexOf("FkNazione") !== -1) {
            this.nazioneField.errors.push(
              error.response.data.errors.nazione[0]
            );
          }
          if (keys.indexOf("nome") !== -1) {
            this.nomeField.errors.push(error.response.data.errors.nome[0]);
          }
          if (keys.indexOf("cognome") !== -1) {
            this.cognomeField.errors.push(
              error.response.data.errors.cognome[0]
            );
          }
        });
      this.loading = false;
    },
    async fetchAgent() {
      this.loadingCard = true;
      await FetchAgent()
        .then((response) => {
          this.agent = response.data;
        })
        .catch(() => {
          this.fetchAgentError = true;
        });
      this.loadingCard = false;
    },
    async handleUpdate() {
      await this.fetchNations();
      if (this.fetchNationsError == false) {
        this.dialogFormVisible = true;
        this.initForm();
      } else {
        this.errorAlert();
      }
    },
    initForm() {
      this.isSubmitted = false;
      this.nazioneField.value = this.agent.pkNazione;
      this.nomeField.value = this.agent.nome;
      this.cognomeField.value = this.agent.cognome;
    },
    handleClose(done) {
      if (this.formModified == true) {
        this.$confirm("Sei sicuro di voler chiudere?")
          .then(() => {
            done;
            this.dialogFormVisible = false;

            this.formModified = false;
          })
          .catch(() => {});
      } else {
        this.dialogFormVisible = false;
      }
    },
    errorAlert() {
      this.$message.error("Qualcosa è andato storto, riprova!");
    },
    async fetchNations() {
      await FetchNations()
        .then((response) => {
          this.nations = response.data;
        })
        .catch(() => {
          this.fetchNationsError = true;
        });
    },
  },
};
</script>

<style scoped>
.warning-description {
  color: #ff4949;
  padding: 4px;
  margin-bottom: 15px;
}
.warning-title {
  color: #ff4949;
  padding: 4px;
}
.el-icon-warning-outline {
  color: #ff4949;
  font-size: 150px;
  margin-bottom: 25px;
}
.avatar {
  position: relative;
  left: 107px;
}
.box-card {
  margin-bottom: 25px;
}
.row {
  margin-bottom: 0px;
}
.dialog-footer {
  align-content: center;
}
</style>
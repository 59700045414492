<template>
  <div class="row mt-5 mb-4 text-center">
    <div class="col">
      <h2>Provvigioni</h2>
    </div>
  </div>
  <div class="app-container">
    <div class="filter-container">
      <div class="row">
        <div class="col-12 mb-4">
          <el-select
            size="medium"
            v-model="conto"
            placeholder="Seleziona"
            :v-loading="loadingSelect"
            @change="loadData"
          >
            <el-option
              v-for="item in conti"
              :key="item.pkConto"
              :label="item.descrizione + ' (' + item.codice + ')'"
              :value="item.pkConto"
            >
            </el-option>
          </el-select>
          <div class="col-12 mb-4 mt-3">
            <el-date-picker
              v-model="provvigioniSearch.DataMese"
              type="month"
              size="medium"
              format="MM/YYYY"
              style="width: 190px"
            >
            </el-date-picker>
            <el-button
              size="medium"
              icon="el-icon-search"
              type="primary"
              class="ms-3"
              :loading="loadingRicerca"
              @click="handleRicerca"
              >Cerca</el-button
            >
            <el-button
              type="text"
              size="medium"
              class="ms-3"
              @click="handleDialogSearch"
            >
              Ricerca avanzata
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <el-dialog
    v-model="dialogSearchVisible"
    width="80%"
    title="Ricerca avanzata"
    center
  >
    <div class="row justify-content-center">
      <div class="col text-center">
        <el-date-picker
          v-model="provvigioniSearch.DataMese"
          type="month"
          size="medium"
          format="MM/YYYY"
          style="width: 240px"
        >
        </el-date-picker>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col text-center">
        <el-input
          v-model="provvigioniSearch.IdOrdine"
          placeholder="ID Ordine"
          size="medium"
          class="mt-2"
          style="width: 240px"
        />
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col text-center">
        <el-select
          clearable
          v-model="provvigioniSearch.Tipo"
          placeholder="Tipo Provvigione"
          multiple
          size="medium"
          class="mt-2"
          style="width: 240px"
          ><el-option
            v-for="item in tipo"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option
        ></el-select>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col text-center">
        <el-button
          size="medium"
          icon="el-icon-search"
          type="primary"
          class="mt-2"
          :loading="loadingRicerca"
          @click="handleRicerca"
          >Cerca</el-button
        >
      </div>
    </div>
  </el-dialog>
  <div v-if="loading" class="d-flex justify-content-center">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div v-else>
    <div class="table-responsive mb-3">
      <table class="table table-striped text-center">
        <thead>
          <tr>
            <td class="fw-bold">Conto</td>
            <td class="fw-bold">Qualifica Agente</td>
            <td class="fw-bold">Data Ordine</td>
            <td class="fw-bold">ID Ordine</td>
            <td class="fw-bold">Totale pagato</td>
            <td class="fw-bold">Qualifica</td>
            <td class="fw-bold">Livello</td>
            <td class="fw-bold">Base provvigione</td>
            <td class="fw-bold">Percentuale</td>
            <td class="fw-bold">Provvigione</td>
            <td class="fw-bold">Numero Rendiconto</td>
            <td class="fw-bold">Stato</td>
            <td class="fw-bold">Tipo</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in provvigioni" :key="item.orderID">
            <td>{{ item.conto }}</td>
            <td>{{ item.qualificaAgente }}</td>
            <td>{{ item.dataOra }}</td>
            <td>
              <router-link
                :to="{ name: 'CustomerOrder', params: { id: item.orderID } }"
                ><el-button type="text">{{
                  item.orderID
                }}</el-button></router-link
              >
            </td><td>{{ item.total }}</td>
            <td>{{ item.qualifica }}</td>
            <td>{{ item.livello }}</td>
            <td>{{ item.commissionabile }}</td>
            <td>{{ item.percentuale }}</td>
            <td><el-popover
            placement="bottom"
            :width="300"
            trigger="click"
            v-if="item.note != null"
          >
            {{ item.note }}
            <template #reference>
              <el-button type="text"
                >{{ item.valore }}<i class="el-icon-info ms-1"></i
              ></el-button>
            </template>
          </el-popover></td>
            <td>{{ item.numeroRendiconto }}</td>
            <td>{{ item.stato }}</td>
            <td>{{ item.tipo }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <loading-mobile-table
      @loadMoreOrder="handleloadMore"
      :pageNumber="pageNumber"
      :pager="pager"
      :conto="conto"
      :provvigioniSearch="provvigioniSearch"
      class="mt-2"
    ></loading-mobile-table>
  </div>
</template>
<script>
import moment from "moment";
import LoadingMobileTable from "@/components/Provvigioni/LoadingMobileTable.vue";
import {
  FetchConti,
  FetchProvvigioni,
  FetchAgentsSelect,
} from "@/api/fetchData.js";
export default {
  components: { LoadingMobileTable },
  data() {
    return {
      dialogSearchVisible: false,
      loading: false,
      pager: [],
      pageNumber: 1,
      pageSize: 10,
      provvigioni: [],
      conto: null,
      conti: null,
      loadingSelect: false,
      noMoreOrders: false,
      provvigioniSearch: {
        DataMese: undefined,
        PkAgenti: undefined,
        IdOrdine: undefined,
        Status: undefined,
        Tipo: undefined,
      },
      tipo: [
        {
          label: "Sospesa",
          value: 1,
        },
        {
          label: "Maturata",
          value: 2,
        },
        {
          label: "Contabilizzata",
          value: 3,
        },
        {
          label: "Fatturata",
          value: 4,
        },
      ],
      status: [
        {
          label: "Tutte",
          value: 0,
        },
        {
          label: "Da pagare",
          value: 1,
        },
        {
          label: "Da non pagare",
          value: 2,
        },
        {
          label: "Con Riporto",
          value: 3,
        },
      ],
      loadingAgents: false,
      loadingRicerca: false,
    };
  },
  async created() {
    await this.loadConti().then(() => {
      this.fetchAgents();
      this.loadData();
    });
  },
  methods: {
    handleDialogSearch() {
      this.fetchAgents();
      this.dialogSearchVisible = true;
    },
    async handleRicerca() {
      this.disabledPagination = true;
      this.pageNumber = 1;
      this.pageSize = 10;
      this.loadingRicerca = true;

      if (this.provvigioniSearch.DataMese != undefined) {
        this.provvigioniSearch.DataMese = moment
          .utc(this.provvigioniSearch.DataMese)
          .local()
          .format("YYYY-MM-DD[T]00:00:00");
      }
      await FetchProvvigioni(
        this.conto,
        this.pageNumber,
        this.pageSize,
        this.provvigioniSearch.DataMese,
        this.provvigioniSearch.PkAgenti,
        this.provvigioniSearch.IdOrdine,
        this.provvigioniSearch.Status,
        this.provvigioniSearch.Tipo
      )
        .then((response) => {
          this.provvigioni = response.data;
          this.pager = JSON.parse(response.headers["x-pagination"]);
          this.provvigioni.forEach((element) => {
            element.total = element.total.toFixed(2) + "€";
            element.valore = element.valore.toFixed(2) + "€";
            element.percentuale = element.percentuale + "%";
            element.commissionabile = element.commissionabile + "€";
            element.dataOra = moment(element.dataOra).format(
              "DD/MM/YYYY, HH:mm:ss"
            );
          });
        })
        .catch((error) =>
          window.alert("Attenzione, si è verificato un errore " + error)
        )
        .finally(() => {
          this.loadingRicerca = false;
          this.disabledPagination = false;
          this.dialogSearchVisible = false;
        });
    },
    async loadConti() {
      this.loading = true;
      this.loadingSelect = true;
      await FetchConti().then((response) => {
        this.conti = response.data;
        this.conto = response.data[0].pkConto;
      });
      this.loadingSelect = false;
    },
    async loadData() {
      this.disabledPagination = true;

      await FetchProvvigioni(
        this.conto,
        this.pageNumber,
        this.pageSize,
        this.provvigioniSearch.DataMese,
        this.provvigioniSearch.PkAgenti,
        this.provvigioniSearch.IdOrdine,
        this.provvigioniSearch.Status,
        this.provvigioniSearch.Tipo
      )
        .then((response) => {
          this.provvigioni = response.data;
          this.pager = JSON.parse(response.headers["x-pagination"]);
          this.provvigioni.forEach((element) => {
            element.total = element.total.toFixed(2) + "€";
            element.valore = element.valore.toFixed(2) + "€";
            element.percentuale = element.percentuale + "%";
            element.commissionabile = element.commissionabile + "€";
            element.dataOra = moment(element.dataOra).format(
              "DD/MM/YYYY, HH:mm:ss"
            );
          });
        })
        .catch((error) =>
          window.alert("Attenzione, si è verificato un errore " + error)
        )
        .finally(() => {
          this.loading = false;
          this.disabledPagination = false;
        });
    },
    handleloadMore(value) {
      this.provvigioni = this.provvigioni.concat(value);
      this.pageNumber++;
    },
    async fetchAgents() {
      this.loadingAgents = false;
      await FetchAgentsSelect()
        .then((response) => {
          this.agents = response.data;
        })
        .catch((error) =>
          window.alert("Attenzione, si è verificato un errore " + error)
        )
        .finally(() => {
          this.loadingAgents = false;
        });
    },
  },
};
</script>

<style scoped>
.el-input {
  width: 190px;
}
.el-select {
  width: 260px;
}
</style>
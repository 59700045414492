 <template>
  <div class="row">
    <div class="col ms-3 mb-4">
      <el-date-picker
        v-model="dateSearch"
        type="daterange"
        size="medium"
        format="DD/MM/YYYY"
        placeholder="Seleziona"
        start-placeholder="Data iniziale"
        end-placeholder="Data finale"
        range-separator="al"
        clearable
      >
      </el-date-picker>

      <el-input
        v-model="ordersSearch.Ricerca"
        placeholder="Ricerca"
        size="medium"
        class="ms-3"
        style="width: 210px"
      />
      <el-select
        v-model="ordersSearch.Status"
        placeholder="Stato"
        multiple
        size="medium"
        class="ms-3"
        style="width: 260px"
        ><el-option
          v-for="item in status"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option
      ></el-select>
      <el-select
        :loading="loadingAgents"
        v-model="ordersSearch.PkAgenti"
        placeholder="Seleziona Agente"
        filterable
        multiple
        size="medium"
        class="ms-3"
        style="width: 260px"
        ><el-option
          v-for="item in agents"
          :key="item.pkAgente"
          :value="item.pkAgente"
          :label="item.nome + ' ' + item.cognome + ' (' + item.codice + ')'"
        >
        </el-option>
      </el-select>
      <el-button
        size="medium"
        icon="el-icon-search"
        type="primary"
        class="ms-3"
        :loading="loadingRicerca"
        @click="handleRicerca"
        >Cerca</el-button
      >
    </div>
  </div>
  <el-table v-loading="loading" :data="orders" stripe fit highlight-current-row>
    <el-table-column type="expand">
      <template #default="props">
        <div v-if="props.row.isElaborato">
          <provvigioni-table
            :id="props.row.pkCustomerOrder"
          ></provvigioni-table>
        </div>
      </template>
    </el-table-column>
    <el-table-column align="center" width="50px">
      <template #default="props">
        <font-awesome-icon
          v-if="props.row.isElaborato"
          :icon="calculator"
        ></font-awesome-icon>
      </template>
    </el-table-column>
    <el-table-column label="Tipo Vendita" prop="tipoOrdine" align="center" />
    <el-table-column label="Data Ordine" prop="orderCreated" align="center" />
    <el-table-column label="ID Ordine" prop="orderID" align="center" />
    <el-table-column label="Agente" prop="agente" align="center" />
    <el-table-column label="Email Cliente" prop="billingEmail" align="center" />
    <el-table-column label="Pagato" align="center">
      <template v-slot="props">
        <el-popover placement="bottom" :width="300" trigger="click">
          <p><span class="fw-bold">IVA:</span> {{ props.row.totalTax }}</p>
          <p>
            <span class="fw-bold">Coupon:</span> {{ props.row.discountTotal }}
          </p>
          <p>
            <span class="fw-bold"> Spese di spedizione:</span>
            {{ props.row.shippingTotal }}
          </p>
          <p>
            <span class="fw-bold"> Licenza:</span>
            {{ props.row.licenza }}
          </p>
          <p>
            <span class="fw-bold"> Totale licenza:</span>
            {{ props.row.licenzaTotal }}
          </p>
          <p>
            <span class="fw-bold"> IVA licenza:</span>
            {{ props.row.licenzaTax }}
          </p>
          <p>
            <span class="fw-bold"> Gift Card:</span>
            {{ props.row.giftCard }}
          </p>
          <p>
            <span class="fw-bold"> Gift Card ID:</span>
            {{ props.row.giftCardId }}
          </p>
          <template #reference>
            <el-button type="text"
              >{{ props.row.total }}<i class="el-icon-info ms-1"></i
            ></el-button>
          </template>
        </el-popover> </template
    ></el-table-column>
    <el-table-column
      label="Fatturato"
      prop="totaleFatturabile"
      align="center"
    />
    <el-table-column label="Stato" prop="status" align="center" />
  </el-table>

  <pagination
    v-show="Number(pager.TotalCount) > 0"
    :total="Number(pager.TotalCount)"
    v-model:current-page="pageNumber"
    v-model:page-size="pageSize"
    @pagination="loadData"
    :disabled="disabledPagination"
  />
</template>

<script>
import { faCalculator } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import ProvvigioniTable from "./ProvvigioniTable.vue";
import { mapState } from "vuex";
import moment from "moment";
import { FetchCustomerOrder, FetchAgentsSelect } from "@/api/fetchData.js";
import Pagination from "@/components/Pagination";
export default {
  components: {
    Pagination,
    ProvvigioniTable,
    FontAwesomeIcon,
  },
  computed: {
    ...mapState({
      device: (state) => state.app.device,
    }),
  },
  data() {
    return {
      dateSearch: null,
      calculator: faCalculator,
      loading: true,
      loadingAgents: false,
      pager: [],
      pageNumber: 1,
      pageSize: 10,
      orders: [],
      order: [],
      disabledPagination: false,
      status: [
        {
          value: "Creato",
          label: "Creato",
        },
        {
          value: "Pagato",
          label: "Pagato",
        },
      ],
      agents: [],
      ordersSearch: {
        DataInizio: undefined,
        DataFine: undefined,
        PkAgenti: undefined,
        Ricerca: undefined,
        Status: undefined,
      },
      loadingRicerca: false,
    };
  },
  created() {
    this.loadData();
    this.fetchAgents();
  },
  methods: {
    handleRicerca() {
      try {
        this.disabledPagination = true;
        this.pageNumber = 1;
        this.pageSize = 10;
        this.loadingRicerca = true;

        if (this.dateSearch != null) {
          if (this.dateSearch[0] != null) {
            this.ordersSearch.DataInizio = moment
              .utc(this.dateSearch[0])
              .local()
              .format("YYYY-MM-DD[T]00:00:00");
          } else {
            this.ordersSearch.DataInizio = undefined;
          }
          if (this.dateSearch[1] != null) {
            this.ordersSearch.DataFine = moment
              .utc(this.dateSearch[1])
              .local()
              .format("YYYY-MM-DD[T]00:00:00");
          } else {
            this.ordersSearch.DataFine = undefined;
          }
        } else {
          this.ordersSearch.DataInizio = undefined;
          this.ordersSearch.DataFine = undefined;
        }
        FetchCustomerOrder(
          this.pageNumber,
          this.pageSize,
          this.ordersSearch.DataInizio,
          this.ordersSearch.DataFine,
          this.ordersSearch.PkAgenti,
          this.ordersSearch.Ricerca,
          this.ordersSearch.Status
        )
          .then((response) => {
            this.orders = response.data;
            this.pager = JSON.parse(response.headers["x-pagination"]);
            this.orders = this.initTable(this.orders);
          })
          .catch((error) =>
            window.alert("Attenzione, si è verificato un errore " + error)
          )
          .finally(() => {
            this.loadingRicerca = false;
            this.disabledPagination = false;
          });
      } catch {
        this.loadingRicerca = false;
        this.disabledPagination = false;
      }
    },

    initTable(data) {
      data.forEach((element) => {
        element.orderCreated = moment(element.orderCreated).format(
          "DD/MM/YYYY, HH:mm:ss"
        );
        element.discountTotal =
          (element.discountTotal + element.discountTax).toFixed(2) + "€";

        element.shippingTotal =
          (element.shippingTotal + element.shippingTax).toFixed(2) + "€";
        element.total = element.total.toFixed(2) + "€";
        element.totalTax = element.totalTax.toFixed(2) + "€";
        element.totaleFatturabile = element.totaleFatturabile.toFixed(2) + "€";
      });
      return data;
    },
    loadData() {
      if (this.$route.params.id != undefined) {
        this.ordersSearch.Ricerca = this.$route.params.id;
      }
      this.disabledPagination = true;
      this.loading = true;
      FetchCustomerOrder(
        this.pageNumber,
        this.pageSize,
        this.ordersSearch.DataInizio,
        this.ordersSearch.DataFine,
        this.ordersSearch.PkAgenti,
        this.ordersSearch.Ricerca,
        this.ordersSearch.Status
      )
        .then((response) => {
          this.orders = this.initTable(response.data);
          this.pager = JSON.parse(response.headers["x-pagination"]);
        })
        .catch((error) =>
          window.alert("Attenzione, si è verificato un errore " + error)
        )
        .finally(() => {
          this.loading = false;

          this.disabledPagination = false;
        });
    },
    fetchAgents() {
      this.loadingAgents = false;
      FetchAgentsSelect()
        .then((response) => {
          this.agents = response.data;
        })
        .catch((error) =>
          window.alert("Attenzione, si è verificato un errore " + error)
        )
        .finally(() => {
          this.loadingAgents = false;
        });
    },
  },
};
</script>
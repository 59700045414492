<template>
  <slot :id="id" :DataMese="DataMese"></slot>
  <el-popconfirm
    title="Sei sicuro di voler fatturare le provvigioni?"
    @confirm="fatturareProvvigione"
    confirmButtonText="Si"
    cancelButtonText="No"
  >
    <template #reference>
      <el-button
        type="primary"
        icon="el-icon-coin"
        size="mini"
        :loading="loading"
      >
        Fatturare
      </el-button>
    </template>
  </el-popconfirm>
</template>

<script>
import { RequestFatturareProvvigione } from "@/api/modifyData.js";
export default {
    emits:["fatturareSuccess"],
  props: ["id","DataMese"],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async fatturareProvvigione() {
      this.loading = true;
      let data = {Datamese:this.DataMese}
      await RequestFatturareProvvigione(this.id, data)
        .then(() => {
          alert("Provvigioni fatturate con successo.");
          this.$emit('fatturareSuccess')
        })
        .catch(() => {
          alert("Errore nella fatturazione delle provvigioni");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
</style>
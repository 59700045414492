<template>
  <el-card class="box-card" shadow="never" v-loading="loadingCard">
    <template #header>
      <p class="text-center fs-3 fw-bold">Contatti</p>
    </template>
    <div v-if="fetchContactsError == true">
      <div class="text-center">
        <i class="el-icon-warning-outline"></i>
        <h2 class="warning-title fw-bold">Ooops...</h2>
        <p class="warning-description h4">Qualcosa è andato storto, Riprova!</p>
      </div>

      <div class="d-grid gap-2 col-3 mx-auto">
        <el-button type="danger" @click="tryAgain">Riprova</el-button>
      </div>
    </div>
    <div v-else>
      <div class="d-grid gap-2 col-3 mx-auto">
        <el-button type="success" icon="el-icon-plus" @click="handleCreate">
          Inserisci
        </el-button>
      </div>
      <div class="table-responsive" v-if="device === 'mobile'">
        <table class="table table-striped text-center">
          <thead>
            <tr>
              <td class="fw-bold">Descrizione</td>
              <td class="fw-bold">Contatto</td>
              <td class="fw-bold">Azioni</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in contacts" :key="item.pkAgenteContatto">
              <td>
                {{ item.descrizione }}
              </td>
              <td>{{ item.contatto }}</td>
              <td>
                <el-button
                class="ms-0"
                  type="primary"
                  size="mini"
                  icon="el-icon-edit"
                  @click="
                    handleUpdate(
                      item.pkAgenteContatto,
                      item.descrizione,
                      item.contatto
                    )
                  "
                >
                  Modifica
                </el-button>
               <p class="mt-1"> <el-button
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  @click="handleDelete(item.pkAgenteContatto)"
                >
                  Elimina
                </el-button></p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <el-table
          :data="contacts"
          stripe
          height="208"
          fit
          highlight-current-row
        >
          <el-table-column
            label="Descrizione"
            prop="descrizione"
            align="center"
          />
          <el-table-column label="Contatto" prop="contatto" align="center" />

          <el-table-column
            label="Azioni"
            align="center"
            width="230"
            class-name="small-padding fixed-width"
          >
            <template v-slot="{ row }">
              <el-button
                type="primary"
                size="mini"
                icon="el-icon-edit"
                @click="
                  handleUpdate(
                    row.pkAgenteContatto,
                    row.descrizione,
                    row.contatto
                  )
                "
              >
                Modifica
              </el-button>

              <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete"
                @click="handleDelete(row.pkAgenteContatto)"
              >
                Elimina
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </el-card>
  <el-dialog
    :width="device === 'mobile' ? '85%' : '50%'"
    v-model="dialogCreateFormVisible"
    :before-close="handleClose"
    center
  >
    <template #title>
      <span class="fw-bold">Inserisci Nuovo Contatto</span>
    </template>
    <div
      :class="[
        'alert',
        'text-center',
        submitResult ? 'alert-success' : 'alert-danger',
      ]"
      v-if="isSubmitted"
    >
      {{ submitResult ? axiosSuccessMessage : axiosErrorMessage }}
    </div>
    <el-form
      show-message
      :label-position="device === 'mobile' ? 'top' : 'left'"
      label-width="120px"
      ><el-form-item label="Descrizione" :error="descrizioneField.errorMessage">
        <el-input
          type="text"
          @change="handleChange"
          :model-value="descrizioneField.value"
          @update:model-value="descrizioneField.handleChange"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="Contatto" :error="contattoField.errorMessage">
        <el-input
          type="text"
          @change="handleChange"
          :model-value="contattoField.value"
          @update:model-value="contattoField.handleChange"
        >
        </el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button type="info" @click="handleClose"> Indietro </el-button>
        <el-button :loading="loading" type="primary" @click="submitCreateForm">
          Conferma
        </el-button>
      </span></template
    ></el-dialog
  >
  <el-dialog
    :width="device === 'mobile' ? '80%' : '50%'"
    v-model="dialogUpdateFormVisible"
    :before-close="handleClose"
    center
  >
    <template #title>
      <span class="fw-bold">Modifica Contatto</span>
    </template>
    <div
      :class="[
        'alert',
        'text-center',
        submitResult ? 'alert-success' : 'alert-danger',
      ]"
      v-if="isSubmitted"
    >
      {{ submitResult ? axiosSuccessMessage : axiosErrorMessage }}
    </div>
    <el-form
      show-message
      :label-position="device === 'mobile' ? 'top' : 'left'"
      label-width="120px"
      ><el-form-item label="Descrizione" :error="descrizioneField.errorMessage">
        <el-input
          type="text"
          @change="handleChange"
          :model-value="descrizioneField.value"
          @update:model-value="descrizioneField.handleChange"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="Contatto" :error="contattoField.errorMessage">
        <el-input
          type="text"
          @change="handleChange"
          :model-value="contattoField.value"
          @update:model-value="contattoField.handleChange"
        >
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-input type="hidden" :model-value="agenteContattoField.value">
        </el-input>
      </el-form-item>
    </el-form>
    <template #footer
      ><span class="dialog-footer">
        <el-button type="info" @click="handleClose"> Indietro </el-button>
        <el-button :loading="loading" type="primary" @click="submitUpdateForm">
          Conferma
        </el-button>
      </span></template
    >
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import { FetchContacts } from "@/api/fetchData.js";
import { useField, useForm } from "vee-validate";
import {
  UpdateContact,
  CreateContact,
  DeleteContact,
} from "@/api/modifyData.js";
import { reactive } from "vue";
import * as yup from "yup";
export default {
  computed: {
    ...mapState({
      device: (state) => state.app.device,
    }),
  },
  setup() {
    const { meta: formMeta, handleSubmit, resetForm, handleReset } = useForm();
    const descrizioneField = reactive(
      useField("descrizione", yup.string().required("Campo richiesto"))
    );
    const contattoField = reactive(
      useField("contatto", yup.string().required("Campo richiesto"))
    );
    const agenteContattoField = reactive(useField("pkAgenteContatto"));
    return {
      formMeta,
      handleSubmit,
      resetForm,
      handleReset,
      descrizioneField,
      contattoField,
      agenteContattoField,
    };
  },
  data() {
    return {
      contacts: [],
      dialogUpdateFormVisible: false,
      dialogCreateFormVisible: false,
      formModified: false,
      isSubmitted: false,
      submitResult: false,
      axiosErrorMessage: "",
      axiosSuccessMessage: "",
      submitUpdateForm: this.handleSubmit(this.onSubmitUpdate),
      loading: false,
      loadingCard: false,
      submitCreateForm: this.handleSubmit(this.onSubmitCreate),
      fetchContactsError: false,
    };
  },

  created() {
    this.fetchContacts();
  },
  methods: {
    handleChange() {
      this.formModified = true;
      this.isSubmitted = false;
    },
    tryAgain() {
      this.fetchContacts().then(() => {
        if (this.contacts == []) this.fetchContactsError = false;
        else {
          this.fetchContactsError = true;
        }
      });
    },
    async fetchContacts() {
      this.loadingCard = true;
      await FetchContacts()
        .then((response) => {
          this.contacts = response.data;
        })
        .catch(() => {
          this.fetchContactsError = true;
        });
      this.loadingCard = false;
    },
    async handleDelete(id) {
      this.loading = true;
      await DeleteContact(id)
        .then(() => {
          this.$message.success("Eliminato");
          this.fetchContacts();
        })
        .catch(() => {
          this.$message.error("Qualcosa è andato storto, riprova!");
        });
      this.loading = false;
    },
    async onSubmitUpdate(formValues) {
      this.loading = true;
      this.isSubmitted = false;
      await UpdateContact(formValues, formValues.pkAgenteContatto)
        .then(() => {
          this.isSubmitted = true;
          this.submitResult = true;
          this.axiosSuccessMessage = "Dati aggiornati con successo";
          this.formModified = false;
          this.fetchContacts();
        })
        .catch((error) => {
          this.isSubmitted = true;
          this.submitResult = false;
          this.axiosErrorMessage = "Aggiornamento dati fallito";
          let keys = Object.keys(error.response.data.errors);
          if (keys.indexOf("descrizione") !== -1) {
            this.descrizioneField.errors.push(
              error.response.data.errors.descrizione[0]
            );
          }
          if (keys.indexOf("contatto") !== -1) {
            this.contattoField.errors.push(
              error.response.data.errors.contatto[0]
            );
          }
        });
      this.loading = false;
    },
    async onSubmitCreate(formValues) {
      this.loading = true;
      this.isSubmitted = false;
      await CreateContact(formValues)
        .then(() => {
          this.isSubmitted = true;
          this.submitResult = true;
          this.axiosSuccessMessage = "Dati creati con successo";
          this.formModified = false;
          this.fetchContacts();
        })
        .catch((error) => {
          this.isSubmitted = true;
          this.submitResult = false;
          this.axiosErrorMessage = "Creazione dati fallita";
          let keys = Object.keys(error.response.data.errors);
          if (keys.indexOf("descrizione") !== -1) {
            this.descrizioneField.errors.push(
              error.response.data.errors.descrizione[0]
            );
          }
          if (keys.indexOf("contatto") !== -1) {
            this.contattoField.errors.push(
              error.response.data.errors.contatto[0]
            );
          }
        });
      this.loading = false;
    },
    handleUpdate(id, descrizione, contatto) {
      this.dialogUpdateFormVisible = true;
      this.initForm(id, descrizione, contatto);
    },
    handleCreate() {
      this.dialogCreateFormVisible = true;
      this.initForm(null, null, null);
    },
    initForm(id, descrizione, contatto) {
      this.isSubmitted = false;
      if (this.dialogUpdateFormVisible) {
        this.descrizioneField.value = descrizione;
        this.contattoField.value = contatto;
        this.agenteContattoField.value = id;
      } else {
        this.resetForm();
      }
    },
    handleClose(done) {
      if (this.formModified == true) {
        this.$confirm("Sei sicuro di voler chiudere?")
          .then(() => {
            done;
            this.dialogUpdateFormVisible = false;
            this.dialogCreateFormVisible = false;
            this.formModified = false;
          })
          .catch(() => {});
      } else {
        this.dialogUpdateFormVisible = false;
        this.dialogCreateFormVisible = false;
      }
    },
  },
};
</script>

<style scoped>
.warning-description {
  color: #ff4949;
  padding: 4px;
  margin-bottom: 15px;
}
.warning-title {
  color: #ff4949;
  padding: 4px;
}
.el-icon-warning-outline {
  color: #ff4949;
  font-size: 150px;
  margin-bottom: 25px;
}
.box-card {
  margin-bottom: 25px;
}

</style>
<template>
    <slot :id="id"
          :search="search"
          :pageSize="pageSize"
          :pageNumber="pageNumber"
          :tipoRiga="tipoRiga"></slot>
    <div class="row mt-3 text-center">
        <div class="col">
            <span class="fw-bold fs-5">Provvigioni</span>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col">
            <el-table v-loading="loadingProvvigioni"
                      :data="provvigioni"
                      stripe
                      highlight-current-row
                      border>
                <el-table-column label="Qualifica"
                                 prop="qualificaAgente"
                                 align="center" />
                <el-table-column label="Data" prop="dataOra" align="center">
                    <template v-slot="props">
                        {{props.row.data}}
                        <br />
                        <small>{{props.row.ora}}</small>
                    </template>

                </el-table-column>
                <el-table-column label="ID Ordine" align="center" width="90px">
                    <template v-slot="props">
                        <router-link :to="{
                                name: 'CustomerOrderAdmin',
                                params: { id: props.row.orderID },
                            }">
                            <el-button type="text">
                                {{ props.row.orderID }}
                            </el-button>
                        </router-link>
                    </template>
                </el-table-column>
                <el-table-column label="Totale pagato" prop="total" align="right" />
                <el-table-column label="Livello" prop="livello" align="center" />
                <el-table-column label="Base provvigione"
                                 prop="commissionabile"
                                 align="right" />
                <el-table-column label="Percentuale"
                                 prop="percentuale"
                                 align="right" />
                <el-table-column label="Provvigione" align="right">
                    <template v-slot="props">
                        {{ props.row.valore }}
                        <el-popover placement="bottom"
                                    :width="300"
                                    trigger="click"
                                    v-if="props.row.note != null">
                            {{ props.row.note }}
                            <template #reference>
                                <el-button type="text">
                                    <i class="el-icon-info ms-1"></i>
                                </el-button>
                            </template>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column label="Stato" prop="stato" align="center" />
                <el-table-column label="Tipo" prop="tipo" align="center" />
            </el-table>
            <!--   <el-table-column label="Azioni" align="center">
                <template v-slot="props">
                  <btn-modifica-provvigione
                    :pkAgenteProvvigione="props.row.pkAgenteProvvigione"
                    @modificaProvvigioneSuccess="loadProvvigioni"
                  ></btn-modifica-provvigione>:FkFattura="props.row.FkFattura"
                </template>
              </el-table-column>

            <el-table
              v-loading="loadingProvvigioni"
              highlight-current-row
              border
              :show-header="false"
              row-style="background-color:lightgrey"
            >
              <el-table-column align="center">
                <template> Somme </template>
              </el-table-column>
              <el-table-column align="center">
                <template> Somme </template>
              </el-table-column>
              <el-table-column align="center">
                <template> Somme </template>
              </el-table-column>
              <el-table-column align="center">
                <template> Somme </template>
              </el-table-column>
              <el-table-column align="center">
                <template> Somme </template>
              </el-table-column>
              <el-table-column align="center">
                <template> Somme </template>
              </el-table-column>
              <el-table-column align="center">
                <template> Somme </template>
              </el-table-column>
              <el-table-column align="center">
                <template> Somme </template>
              </el-table-column>
              <el-table-column align="center">
                <template> Somme </template>
              </el-table-column>
            </el-table>-->
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    //import BtnModificaProvvigione from "./BtnModificaProvvigione.vue";
    import { AdminFetchProvvigioniContabilita } from '@/api/fetchData.js';

    export default {
        //components: { BtnModificaProvvigione },
        props: ['id', 'search', 'pageSize', 'pageNumber', 'tipoRiga'],
        data() {
            return {
                provvigioni: [],
                loadingProvvigioni: false,
            };
        },
        created() {
            this.loadProvvigioni(this);
        },

        methods: {
            async loadProvvigioni(e) {

                e.loadingProvvigioni = true;
                if (e.id != undefined) {
                    await AdminFetchProvvigioniContabilita(e.id, e.search.DataMese, e.search.PkAgenti, e.search.Status).then((response) => {

                        e.provvigioni = response.data;
                        e.provvigioni.forEach((element) => {
                            element.total = element.total.toFixed(2) + '€';
                            element.valore = element.valore.toFixed(2) + '€';
                            element.percentuale = element.percentuale + '%';
                            element.commissionabile = element.commissionabile.toFixed(2) + '€';
                            if (element.agenteCodice != null) {
                                element.agente = element.agente + ' (' + element.agenteCodice + ')';
                            }
                            //element.dataOra = moment(element.dataOra).format('DD/MM/YYYY, HH:mm:ss');

                            if (element.livello == 0) {
                                element.livello = "Vendita diretta"
                            } else {
                                element.livello = "Agente " + element.livello + "° livello"
                            }
                            element.data = moment(element.dataOra).format("DD/MM/YYYY");
                            element.ora = moment(element.dataOra).format("HH:mm:ss");

                        });
                    }).catch((error) => window.alert('Attenzione, si è verificato un errore ' + error)
                    ).finally(() => (e.loadingProvvigioni = false));
                }
            },
        },
    };
</script>

<style>
</style>

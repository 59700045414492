<template>
	<slot :searchParam="searchParam"></slot>
	<export-2-excel
		:filename="defaultFileName"
		:headers="header"
		:filterVal="filterVal"
		@export2excel="riempiTabella($event)"
		ref="excelExport"
	></export-2-excel>
</template>

<script>
import moment from 'moment';
import Export2Excel from '../../Excel/index.vue';
import { Contabilita } from '@/api/fetchData.js';
export default {
	components: { Export2Excel },
	props: ['searchParam'],
	data() {
		return {
			defaultFileName: 'Contabilita',
			header: [
				'Nome',
				'Cognome',
				'Descrizione',
				'Banca',
				'IBAN',
				'Contributi INPS',
				'Riutenute Fiscali',
				'Tipo',
				'Provvigioni',
				'Riporto',
				'Totale Provvigioni',
				'Deduzione Forfettaria',
				'Imponibile Fiscale',
				'Ritenuta Fiscale',
				'Totale Dovuto',
			],
			filterVal: [
				'nome',
				'cognome',
				'descrizione',
				'banca',
				'iban',
				'contributiInps',
				'ritenuteFiscali',
				'flagProvvigione',
				'provvigioni',
				'riporto',
				'totaleProvvigioni',
				'deduzioneForfettaria',
				'imponibileFiscale',
				'ritenutaFiscale',
				'totaleDovuto',
			],
		};
	},
	methods: {
		async riempiTabella() {
			
			let dataMese = this.searchParam.DataMese;
			if (dataMese != undefined) {
				dataMese = moment
					.utc(this.searchParam.DataMese)
					.local()
					.format('YYYY-MM-DD[T]00:00:00');
			}
			let c = new Contabilita(0, 0, true);
			let result = c.Search(
				dataMese,
				this.searchParam.PkAgenti,
				this.searchParam.Status,				
			);

			result
				.then((response) => {
					const tableData = response.data;
					this.$refs.excelExport.Export2Excel(tableData);
				})
				.catch((error) =>
					window.alert('Attenzione, si è verificato un errore ' + error)
				)
				.finally(() => {});
		},
	},
};
</script>

<style>
</style>

<template>
  <div class="row mt-5 mb-4 text-center">
    <div class="col">
      <h2>Provvigioni</h2>
    </div>
  </div>
  <div class="app-container">
    <div class="filter-container">
      <div class="row">
        <div class="col ms-3">
          <el-select
            size="medium"
            v-model="conto"
            placeholder="Seleziona"
            :v-loading="loadingSelect"
            @change="loadData"
            class="ms-3 me-3"
          >
            <el-option
              v-for="item in conti"
              :key="item.pkConto"
              :label="item.descrizione + ' (' + item.codice + ')'"
              :value="item.pkConto"
            >
            </el-option>
          </el-select>
          <el-date-picker
            v-model="provvigioniSearch.DataMese"
            type="month"
            size="medium"
            format="MM/YYYY"
          >
          </el-date-picker>

          <el-input
            v-model="provvigioniSearch.IdOrdine"
            placeholder="ID Ordine"
            size="medium"
            class="ms-3"
            style="width: 210px"
          />
          <!-- <el-select
            clearable
            v-model="provvigioniSearch.Status"
            placeholder="Stato"
            multiple
            size="medium"
            class="ms-3"
            style="width: 260px"
            ><el-option
              v-for="item in status"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option
          ></el-select>
          <el-select
            clearable
            :loading="loadingAgents"
            v-model="provvigioniSearch.PkAgenti"
            placeholder="Seleziona Agente"
            filterable
            multiple
            size="medium"
            class="ms-3"
            style="width: 260px"
            ><el-option
              v-for="item in agents"
              :key="item.pkAgente"
              :value="item.pkAgente"
              :label="item.nome + ' ' + item.cognome + ' (' + item.codice + ')'"
            >
            </el-option>
          </el-select>-->
          <el-select
            clearable
            v-model="provvigioniSearch.Tipo"
            placeholder="Tipo Provvigione"
            multiple
            size="medium"
            class="ms-3"
            style="width: 260px"
            ><el-option
              v-for="item in tipo"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option
          ></el-select>
          <el-button
            size="medium"
            icon="el-icon-search"
            type="primary"
            class="ms-3"
            :loading="loadingRicerca"
            @click="handleRicerca"
            >Cerca</el-button
          >
        </div>
      </div>
    </div>

    <el-table
      v-loading="loading"
      :data="provvigioni"
      stripe
      highlight-current-row
      ><!--    <el-table-column type="expand" >
      <template #default="props">
        <p>{{props.row.livello}}</p>
      </template>
      </el-table-column>-->
      <el-table-column label="Conto" prop="conto" align="center" />
      <el-table-column
        label="Qualifica Agente"
        prop="qualificaAgente"
        align="center"
      />
      <el-table-column label="Data Ordine" prop="dataOra" align="center" />
      <el-table-column label="ID Ordine" align="center" width="90px">
        <template v-slot="props">
          <router-link
            :to="{ name: 'CustomerOrder', params: { id: props.row.orderID } }"
            ><el-button type="text">{{
              props.row.orderID
            }}</el-button></router-link
          >
        </template>
      </el-table-column>
      <el-table-column
        label="Totale pagato"
        prop="total"
        align="center"
        width="130px"
      />
      <el-table-column
        label="Qualifica"
        prop="qualifica"
        align="center"
      /><el-table-column
        label="Livello"
        prop="livello"
        align="center"
        width="90px"
      />
      <el-table-column
        label="Base provvigione"
        prop="commissionabile"
        align="center"
        width="160px"
      /><el-table-column
        label="Percentuale"
        prop="percentuale"
        align="center"
        width="130px"
      /><el-table-column
        label="Provvigione"
        prop="valore"
        align="center"
        width="130px"
      >
        <template v-slot="props">
          <div v-if="props.row.note != null">
          {{ props.row.valore }}
          <el-popover
            placement="bottom"
            :width="300"
            trigger="click"
            
          >
            {{ props.row.note }}
            <template #reference>
              <el-button type="text"
                ><i class="el-icon-info ms-1"></i
              ></el-button>
            </template>
          </el-popover>
          </div>
          <div v-else>
            {{ props.row.valore }}
          </div>
        </template></el-table-column
      >
      <el-table-column
        label="Numero Rendiconto"
        prop="numeroRendiconto"
        align="center"
      />
      <el-table-column
        label="Stato"
        prop="stato"
        align="center"
        width="130px"
      />
      <el-table-column label="Tipo" prop="tipo" align="center" width="130px" />
    </el-table>

    <pagination
      v-show="Number(pager.TotalCount) > 0"
      :total="Number(pager.TotalCount)"
      v-model:current-page="pageNumber"
      v-model:page-size="pageSize"
      @pagination="loadData"
      :disabled="disabledPagination"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import {
  FetchConti,
  FetchProvvigioni,
  FetchAgentsSelect,
} from "@/api/fetchData.js";
import Pagination from "@/components/Pagination";
export default {
  components: {
    Pagination,
  },
  computed: {
    ...mapState({
      device: (state) => state.app.device,
    }),
  },
  data() {
    return {
      disabledPagination: false,
      loading: false,
      pager: [],
      pageNumber: 1,
      pageSize: 10,
      provvigioni: [],
      conto: null,
      conti: null,
      agents: [],
      loadingSelect: false,
      provvigioniSearch: {
        DataMese: undefined,
        PkAgenti: undefined,
        IdOrdine: undefined,
        Status: undefined,
        Tipo: undefined,
      },
      tipo: [
        {
          label: "Sospesa",
          value: 1,
        },
        {
          label: "Maturata",
          value: 2,
        },
        {
          label: "Contabilizzata",
          value: 3,
        },
        {
          label: "Fatturata",
          value: 4,
        },
      ],
      status: [
        {
          label: "Tutte",
          value: 0,
        },
        {
          label: "Da pagare",
          value: 1,
        },
        {
          label: "Da non pagare",
          value: 2,
        },
        {
          label: "Con Riporto",
          value: 3,
        },
      ],
      loadingAgents: false,
      loadingRicerca: false,
    };
  },
  async created() {
    await this.loadConti().then(() => {
      this.fetchAgents();
      this.loadData();
    });
  },
  methods: {
    async handleRicerca() {
      this.disabledPagination = true;
      this.pageNumber = 1;
      this.pageSize = 10;
      this.loadingRicerca = true;

      if (this.provvigioniSearch.DataMese != undefined) {
        this.provvigioniSearch.DataMese = moment
          .utc(this.provvigioniSearch.DataMese)
          .local()
          .format("YYYY-MM-DD[T]00:00:00");
      }
      await FetchProvvigioni(
        this.conto,
        this.pageNumber,
        this.pageSize,
        this.provvigioniSearch.DataMese,
        this.provvigioniSearch.PkAgenti,
        this.provvigioniSearch.IdOrdine,
        this.provvigioniSearch.Status,
        this.provvigioniSearch.Tipo
      )
        .then((response) => {
          this.provvigioni = response.data;
          this.pager = JSON.parse(response.headers["x-pagination"]);
          this.provvigioni.forEach((element) => {
            element.total = element.total.toFixed(2) + "€";
            element.valore = element.valore.toFixed(2) + "€";
            element.percentuale = element.percentuale + "%";
            element.commissionabile = element.commissionabile.toFixed(2) + "€";
            element.dataOra = moment(element.dataOra).format(
              "DD/MM/YYYY, HH:mm:ss"
            );
          });
        })
        .catch((error) =>
          window.alert("Attenzione, si è verificato un errore " + error)
        )
        .finally(() => {
          this.loadingRicerca = false;
          this.disabledPagination = false;
        });
    },
    async loadConti() {
      this.loading = true;
      this.loadingSelect = true;
      await FetchConti().then((response) => {
        this.conti = response.data;
        this.conto = response.data[0].pkConto;
      });
      this.loadingSelect = false;
    },
    async loadData() {
      this.disabledPagination = true;

      await FetchProvvigioni(
        this.conto,
        this.pageNumber,
        this.pageSize,
        this.provvigioniSearch.DataMese,
        this.provvigioniSearch.PkAgenti,
        this.provvigioniSearch.IdOrdine,
        this.provvigioniSearch.Status,
        this.provvigioniSearch.Tipo
      )
        .then((response) => {
          this.provvigioni = response.data;
          this.pager = JSON.parse(response.headers["x-pagination"]);
          this.provvigioni.forEach((element) => {
            element.total = element.total.toFixed(2) + "€";
            element.valore = element.valore.toFixed(2) + "€";
            element.percentuale = element.percentuale + "%";
            element.commissionabile = element.commissionabile + "€";
            element.dataOra = moment(element.dataOra).format(
              "DD/MM/YYYY, HH:mm:ss"
            );
          });
        })
        .catch((error) =>
          window.alert("Attenzione, si è verificato un errore " + error)
        )
        .finally(() => {
          this.loading = false;
          this.disabledPagination = false;
        });
    },
    async fetchAgents() {
      this.loadingAgents = false;
      await FetchAgentsSelect()
        .then((response) => {
          this.agents = response.data;
        })
        .catch((error) =>
          window.alert("Attenzione, si è verificato un errore " + error)
        )
        .finally(() => {
          this.loadingAgents = false;
        });
    },
  },
};
</script>

<style scoped>
.el-input {
  width: 190px;
}
.el-select {
  width: 260px;
}
</style>
<template>
  <slot :id="id" :attiva="attiva" :sospesa="sospesa" :cessata="cessata"></slot>
  <el-popconfirm
    title="Sei sicuro di voler rinnovare questa licenza?"
    @confirm="rinnovaLicenza"
    confirmButtonText="Si"
    cancelButtonText="No"
  >
    <template #reference>
      <el-button :type="attiva ? 'primary' : 'warning'" size="mini" :loading="loading" > Rinnova </el-button>
    </template>
  </el-popconfirm>
</template>

<script>
import moment from 'moment'
import { RinnovaLicenza } from "@/api/modifyData.js";
export default {
  emits: ["licenzaRinnovata"],
  props: ["id", "attiva", "sospesa", "cessata"],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async rinnovaLicenza() {
      this.loading = true;
      let currentDate = {RinnovoData:moment().utc().local()}
      await RinnovaLicenza(this.id, currentDate)
        .then(() => {
          alert("Licenza rinnovata");
          this.$emit("licenzaRinnovata");
        })
        .catch(() => {
          alert("Errore durante la rinnovazione della licenza");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
</style>
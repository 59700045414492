<template>
  <div class="row mt-5 mb-4 text-center">
    <div class="col">
      <h2>Ordini</h2>
    </div>
  </div>
  <div class="container-fluid">
    <div v-if="device === 'mobile'">
      <TableMobile />
    </div>
    <div v-else>
      <Table />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TableMobile from "../../components/CustomerOrder/Admin/AdminTableMobile.vue";
import Table from "../../components/CustomerOrder/Admin/AdminTable.vue"
export default {
  components: { TableMobile, Table },
  computed: {
    ...mapState({
      device: (state) => state.app.device,
    }),
  },
};
</script>

<style>
</style>
       
<template>
  <el-table
    v-loading="loading"
    :data="qualifiche"
    stripe
    fit
    highlight-current-row
    border
  >
    <el-table-column label="Data" prop="pkData" align="center" />
    <el-table-column label="Qualifica" prop="qualifica" align="center" />
  </el-table>
  <slot :id="id"></slot>
</template>

<script>
import { FetchQualificheAgenti } from "../../../api/fetchData";
export default {
  props: ["id"],
  created() {
    this.fetchQualificheAgenti();
  },
  data() {
    return {
      qualifiche: [],
      loading: false,
    };
  },
  methods: {
    async fetchQualificheAgenti() {
      this.loading = true;
      if (this.id != undefined) {
        await FetchQualificheAgenti(this.id)
          .then((response) => {
            this.qualifiche = response.data;
          })
          .catch(() => {
            alert("Errore durante il caricamento dei dati");
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style>
</style>
<template>
  <div class="row">
    <div class="col ms-3 mb-4">
      <el-input
        v-model="ordersSearch.Ricerca"
        placeholder="Ricerca"
        size="medium"
        class="ms-3"
        style="width: 210px"
      />
      <el-select
        :loading="loadingAgentsSelect"
        v-model="ordersSearch.PkAgenti"
        placeholder="Seleziona Sponsor"
        clearable
        filterable
        size="medium"
        class="ms-3"
        style="width: 260px"
        ><el-option
          v-for="item in agentsSelect"
          :key="item.pkAgente"
          :label="item.nome + ' ' + item.cognome + ' (' + item.codice + ')'"
          :value="item.pkAgente"
        ></el-option>
      </el-select>
      <el-checkbox
        class="ms-3"
        v-model="ordersSearch.strutturaCompleta"
        label="Struttura Completa"
      >
      </el-checkbox>
      <el-select
        clearable
        :loading="loading"
        v-model="ordersSearch.FkQualifica"
        placeholder="Seleziona Qualifica"
        multiple
        size="medium"
        class="ms-3"
        style="width: 260px"
        ><el-option
          :loading="loadingQualificheSelect"
          v-for="item in qualificaSelect"
          :key="item.pkQualifica"
          :label="item.descrizione"
          :value="item.pkQualifica"
        ></el-option>
      </el-select>
      <el-select
        clearable
        v-model="ordersSearch.Licenza"
        placeholder="Seleziona Licenza"
        multiple
        size="medium"
        class="ms-3"
        style="width: 260px"
        ><el-option
          v-for="item in licenza"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <el-select
        v-model="ordersSearch.Status"
        placeholder="Status Agente"
        size="medium"
        class="ms-3"
        style="width: 260px"
        ><el-option
          v-for="item in status"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <el-button
        size="medium"
        icon="el-icon-search"
        type="primary"
        class="ms-3"
        @click="ricerca"
        :loading="loadingRicerca"
        >Cerca</el-button
      >
    </div>
  </div>
  <el-table
    stripe
    fit
    highlight-current-row
    border
    :data="agents"
    v-loading="loading"
  >
    <el-table-column type="expand">
      <template #default="props">
        <tabs :id="props.row.fkAgente"> </tabs>
      </template>
    </el-table-column>
    <el-table-column label="Agente" prop="nome" align="center" />
    <el-table-column label="Sponsor" prop="sponsorNome" align="center" />
    <el-table-column label="Qualifica" prop="qualifica" align="center" />
    <el-table-column
      label="Inizio Attività"
      prop="attivitaInizio"
      align="center"
    />
    <el-table-column label="Fine Attività" prop="attivitaFine" align="center" />
    <el-table-column label="Licenza" prop="licenza" align="center" />
    <el-table-column label="Azioni" align="center" class-name="fixed-width">
      <template #default="props">
        <reset-password :id="props.row.fkAgente"> </reset-password>
        <div class="mb-1"></div>
        <attiva-iscrizione
          :id="props.row.fkAgente"
          v-if="props.row.isRegistrato"
          @licenzaAttivata="refreshTable"
          :qualifiche="qualificaSelect"
          :agents="agentsSelect"
        ></attiva-iscrizione>
        <rinnova-licenza
          :id="props.row.fkAgente"
          :attiva="props.row.licenzaAttiva"
          :sospesa="props.row.licenzaSospesa"
          :cessata="props.row.licenzaCessata"
          v-if="
            props.row.licenzaSospesa ||
            (props.row.licenzaAttiva && props.row.rinnovare)
          "
          @licenzaRinnovata="refreshTable"
        >
        </rinnova-licenza>
      </template>
    </el-table-column>
  </el-table>
  <pagination
    v-show="Number(pager.TotalCount) > 0"
    :total="Number(pager.TotalCount)"
    v-model:current-page="pageNumber"
    v-model:page-size="pageSize"
    @pagination="fetchAgents"
    :disabled="disabledPagination"
  />
</template>

<script>
import ResetPassword from "./ResetPassword.vue";
import Tabs from "./TabsContainer.vue";
import RinnovaLicenza from "./RinnovaLicenza.vue";
import AttivaIscrizione from "./AttivaIscrizione.vue";
import moment from "moment";
import {
  FetchAgents,
  FetchAgentsSelect,
  FetchQualificheSelect,
} from "../../../api/fetchData.js";
import Pagination from "../../Pagination";
export default {
  components: {
    Tabs,
    Pagination,
    AttivaIscrizione,
    RinnovaLicenza,
    ResetPassword,
  },
  data() {
    return {
      agents: [],
      agentsSelect: [],
      pageNumber: 1,
      pageSize: 10,
      loading: false,
      loadingAgentsSelect: false,
      loadingQualificheSelect: false,
      pager: [],
      disabledPagination: false,
      ordersSearch: {
        PkAgenti: undefined,
        FkQualifica: undefined,
        Ricerca: undefined,
        Licenza: undefined,
        Status: 1,
        strutturaCompleta: undefined,
      },
      loadingRicerca: false,
      qualificaSelect: [],
      licenza: [
        {
          label: "Attiva",
          value: 0,
        },
        { label: "Sospesa", value: 1 },

        { label: "Cessata", value: 2 },
      ],
      status: [
        { label: "Agenti attivi", value: 1 },
        { label: "Agenti Cancellati", value: 2 },
        { label: "Nuovi agenti", value: 3 },
      ],
    };
  },
  created() {
    this.fetchAgents();
    this.fetchAgentsSelect();
    this.fetchQualificheSelect();
  },
  methods: {
    async refreshTable() {
      this.disabledPagination = true;
      this.loading = true;
      await FetchAgents(
        this.pageNumber,
        this.pageSize,
        this.ordersSearch.PkAgenti,
        this.ordersSearch.FkQualifica,
        this.ordersSearch.Ricerca,
        this.ordersSearch.Licenza,
        this.ordersSearch.Status,
        this.ordersSearch.strutturaCompleta
      )
        .then((response) => {
          this.agents = this.initTable(response.data);
          this.pager = this.pager = JSON.parse(
            response.headers["x-pagination"]
          );
        })
        .catch((error) =>
          window.alert("Attenzione, si è verificato un errore " + error)
        )
        .finally(() => {
          this.loading = false;
          this.disabledPagination = false;
        });
    },
    async ricerca() {
      this.disabledPagination = true;
      this.loading = true;
      this.pageNumber = 1;
      this.pageSize = 10;
      await FetchAgents(
        this.pageNumber,
        this.pageSize,
        this.ordersSearch.PkAgenti,
        this.ordersSearch.FkQualifica,
        this.ordersSearch.Ricerca,
        this.ordersSearch.Licenza,
        this.ordersSearch.Status,
        this.ordersSearch.strutturaCompleta
      )
        .then((response) => {
          this.agents = this.initTable(response.data);
          this.pager = this.pager = JSON.parse(
            response.headers["x-pagination"]
          );
        })
        .catch((error) =>
          window.alert("Attenzione, si è verificato un errore " + error)
        )
        .finally(() => {
          this.loading = false;
          this.disabledPagination = false;
        });
    },
    async fetchAgentsSelect() {
      this.loadingAgentsSelect = true;
      await FetchAgentsSelect()
        .then((response) => {
          this.agentsSelect = response.data;
        })
        .catch((error) =>
          window.alert("Attenzione, si è verificato un errore " + error)
        )
        .finally(() => (this.loadingAgentsSelect = false));
    },

    async fetchQualificheSelect() {
      this.loadingQualificheSelect = true;
      await FetchQualificheSelect()
        .then((response) => {
          this.qualificaSelect = response.data;
        })
        .catch((error) =>
          window.alert("Attenzione, si è verificato un errore " + error)
        )
        .finally(() => (this.loadingQualificheSelect = false));
    },

    async fetchAgents() {
      this.disabledPagination = true;
      this.loading = true;
      await FetchAgents(
        this.pageNumber,
        this.pageSize,
        this.ordersSearch.PkAgenti,
        this.ordersSearch.FkQualifica,
        this.ordersSearch.Ricerca,
        this.ordersSearch.Licenza,
        this.ordersSearch.Status,
        this.ordersSearch.strutturaCompleta
      )
        .then((response) => {
          this.agents = this.initTable(response.data);
          this.pager = this.pager = JSON.parse(
            response.headers["x-pagination"]
          );
        })
        .catch((error) =>
          window.alert("Attenzione, si è verificato un errore " + error)
        )
        .finally(() => {
          this.loading = false;
          this.disabledPagination = false;
        });
    },
    initTable(data) {
      data.forEach((element) => {
        element.nome =
          element.nome + " " + element.cognome + " (" + element.codice + ")";
        if (element.sponsorNome != null) {
          element.sponsorNome =
            element.sponsorNome +
            " " +
            element.sponsorCognome +
            " (" +
            element.sponsorCodice +
            ")";
        }

        let currentDate = moment();

        const rinnova = {
          rinnovare:
            moment(new Date(element.attivitaFine)).diff(
              new Date(currentDate),
              "months",
              true
            ) < 6,
        };
        Object.assign(element, rinnova);

        element.attivitaInizio = moment(element.attivitaInizio).format(
          "DD/MM/YYYY"
        );
        element.attivitaFine = moment(element.attivitaFine).format(
          "DD/MM/YYYY"
        );
        if (element.licenzaAttiva) {
          let licenza = { licenza: "Attiva" };
          Object.assign(element, licenza);
        } else if (element.licenzaSospesa) {
          let licenza = { licenza: "Sospesa" };
          Object.assign(element, licenza);
        } else if (element.licenzaCessata) {
          let licenza = { licenza: "Cessata" };
          Object.assign(element, licenza);
        }
      });
      return data;
    },
  },
};
</script>

<style>
</style>

<template>
  <el-card class="box-card" shadow="never" v-loading="loadingCard">
    <template #header>
      <p class="text-center fs-3 fw-bold">Residenza</p>
    </template>
    <div v-if="fetchAddressError == true">
      <div class="text-center">
        <i class="el-icon-warning-outline"></i>
        <h2 class="warning-title fw-bold">Ooops...</h2>
        <p class="warning-description h4">Qualcosa è andato storto, Riprova!</p>
      </div>

      <div class="d-grid gap-2 col-3 mx-auto">
        <el-button type="danger" @click="tryAgain">Riprova</el-button>
      </div>
    </div>
    <div v-else>
      <dl class="row">
        <dt class="col-sm-3 fs-6 fw-bold">Nazione:</dt>
        <dd class="col-sm-9 fs-6">{{ address.nazione }}</dd>
        <el-divider></el-divider>
        <dt class="col-sm-3 fs-6 fw-bold">Regione:</dt>
        <dd class="col-sm-9 fs-6">{{ address.regione }}</dd>
        <el-divider></el-divider>
        <dt class="col-sm-3 fs-6 fw-bold">Provincia:</dt>
        <dd class="col-sm-9 fs-6">{{ address.provincia }}</dd>
        <el-divider></el-divider>
        <dt class="col-sm-3 fs-6 fw-bold">Comune:</dt>
        <dd class="col-sm-9 fs-6">{{ address.comune }}</dd>
        <el-divider></el-divider>
        <dt class="col-sm-3 fs-6 fw-bold">Indirizzo:</dt>
        <dd class="col-sm-9 fs-6">
          {{ address.indirizzo }}
        </dd>
        <el-divider></el-divider>
        <dt class="col-sm-3 fs-6 fw-bold">CAP:</dt>
        <dd class="col-sm-9 fs-6">{{ address.cap }}</dd>
        <el-divider></el-divider>
        <dt class="col-sm-3 fs-6 fw-bold">Località:</dt>
        <dd class="col-sm-9 fs-6">{{ address.localita }}</dd>
        <el-divider></el-divider>
        <dt class="col-sm-5 fs-6 fw-bold">Edificio:</dt>
        <dd class="col-sm-7 fs-6">{{ address.edificio }}</dd>
        <el-divider></el-divider>
        <dt class="col-sm-5 fs-6 fw-bold">Numero Civico:</dt>
        <dd class="col-sm-7 fs-6">{{ address.numeroCivico }}</dd>
        <el-divider></el-divider>
      </dl>

      <div class="d-grid gap-2 col-3 mx-auto">
        <el-button type="primary" icon="el-icon-edit" @click="handleUpdate"
          >Edit</el-button
        >
      </div>
    </div>
  </el-card>

  <el-dialog
    v-model="dialogFormVisible"
    :before-close="handleClose"
    :width="device === 'mobile' ? '80%' : '50%'"
    center
  >
    <template #title>
      <span class="fw-bold">Modifica Residenza</span>
    </template>
    <div
      :class="[
        'alert',
        'text-center',
        submitResult ? 'alert-success' : 'alert-danger',
      ]"
      v-if="isSubmitted"
    >
      {{ submitResult ? axiosSuccessMessage : axiosErrorMessage }}
    </div>
    <el-form
      show-message
      :label-position="device === 'mobile' ? 'top' : 'left'"
      label-width="120px"
    >
      <el-form-item label="Nazione" :error="nazioneField.errorMessage">
        <el-select
          :style="device === 'mobile' ? 'width: 100%;' : ''"
          v-model="nazioneField.value"
          filterable
          placeholder="Select"
          @update:v-model="nazioneField.handleChange"
          @change="handleChange"
        >
          <el-option
            v-for="item in nations"
            :key="item.pkNazione"
            :label="item.nazione"
            :value="item.pkNazione"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Regione" :error="regioneField.errorMessage">
        <el-input
          type="text"
          @change="handleChange"
          :model-value="regioneField.value"
          @update:model-value="regioneField.handleChange"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="Provincia" :error="provinciaField.errorMessage">
        <el-input
          type="text"
          @change="handleChange"
          :model-value="provinciaField.value"
          @update:model-value="provinciaField.handleChange"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="Comune" :error="comuneField.errorMessage">
        <el-input
          type="text"
          @change="handleChange"
          :model-value="comuneField.value"
          @update:model-value="comuneField.handleChange"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="Indirizzo" :error="indirizzoField.errorMessage">
        <el-input
          type="text"
          @change="handleChange"
          :model-value="indirizzoField.value"
          @update:model-value="indirizzoField.handleChange"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="CAP" :error="capField.errorMessage">
        <el-input
          type="text"
          @change="handleChange"
          :model-value="capField.value"
          @update:model-value="capField.handleChange"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="Località" :error="localitaField.errorMessage">
        <el-input
          type="text"
          @change="handleChange"
          :model-value="localitaField.value"
          @update:model-value="localitaField.handleChange"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="Edificio" :error="edificioField.errorMessage">
        <el-input
          type="text"
          @change="handleChange"
          :model-value="edificioField.value"
          @update:model-value="edificioField.handleChange"
        >
        </el-input>
      </el-form-item>
      <el-form-item
        label="Numero Civico"
        :error="numeroCivicoField.errorMessage"
      >
        <el-input
          type="text"
          @change="handleChange"
          :model-value="numeroCivicoField.value"
          @update:model-value="numeroCivicoField.handleChange"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="info" @click="handleClose"> Indietro </el-button>
        <el-button :loading="loading" type="primary" @click="submitForm">
          Conferma
        </el-button>
      </span></template
    >
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import { FetchAddress, FetchNations } from "@/api/fetchData.js";
import { useField, useForm } from "vee-validate";
import { UpdateAddress } from "@/api/modifyData.js";
import { reactive } from "vue";
import * as yup from "yup";
export default {
  computed: {
    ...mapState({
      device: (state) => state.app.device,
    }),
  },
  setup() {
    const { meta: formMeta, handleSubmit, resetForm, handleReset } = useForm();
    const nazioneField = reactive(
      useField("fkNazione", yup.string().required("Campo richiesto"))
    );
    const regioneField = reactive(
      useField("regione", yup.string().required("Campo richiesto"))
    );
    const provinciaField = reactive(
      useField("provincia", yup.string().required("Campo richiesto"))
    );
    const comuneField = reactive(
      useField("comune", yup.string().required("Campo richiesto"))
    );
    const indirizzoField = reactive(
      useField("indirizzo", yup.string().required("Campo richiesto"))
    );
    const capField = reactive(
      useField("CAP", yup.string().required("Campo richiesto"))
    );
    const localitaField = reactive(
      useField("localita", yup.string().nullable())
    );
    const edificioField = reactive(
      useField("edificio", yup.string().nullable())
    );
    const numeroCivicoField = reactive(
      useField("numeroCivico", yup.string().nullable())
    );

    return {
      nazioneField,
      regioneField,
      provinciaField,
      comuneField,
      indirizzoField,
      capField,
      localitaField,
      edificioField,
      numeroCivicoField,
      formMeta,
      handleSubmit,
      resetForm,
      handleReset,
    };
  },
  data() {
    return {
      address: [],
      dialogFormVisible: false,
      nations: [],
      formModified: false,
      isSubmitted: false,
      submitResult: false,
      axiosErrorMessage: "",
      axiosSuccessMessage: "",
      submitForm: this.handleSubmit(this.onSubmit),
      loading: false,
      loadingCard: false,
      fetchNationsError: false,
      fetchAddressError: false,
    };
  },

  created() {
    this.fetchAddress();
  },
  methods: {
    handleChange() {
      this.formModified = true;
      this.isSubmitted = false;
    },
    tryAgain() {
      this.fetchAddress().then(() => {
        if (this.address == []) {
          this.fetchAddressError = false;
        } else {
          this.fetchAddressError = true;
        }
      });
    },
    async onSubmit(formValues) {
      this.loading = true;
      this.isSubmitted = false;
      await UpdateAddress(formValues)
        .then(() => {
          this.isSubmitted = true;
          this.submitResult = true;
          this.axiosSuccessMessage = "Dati aggiornati con successo";
          this.formModified = false;
          this.fetchAddress();
        })
        .catch((error) => {
          this.isSubmitted = true;
          this.submitResult = false;
          this.axiosErrorMessage = "Aggiornamento dati fallito";
          let keys = Object.keys(error.response.data.errors);
          if (keys.indexOf("FkNazione") !== -1) {
            this.nazioneField.errors.push(
              error.response.data.errors.nazione[0]
            );
          }
          if (keys.indexOf("regione") !== -1) {
            this.regioneField.errors.push(
              error.response.data.errors.regione[0]
            );
          }
          if (keys.indexOf("provincia") !== -1) {
            this.provinciaField.errors.push(
              error.response.data.errors.provincia[0]
            );
          }
          if (keys.indexOf("comune") !== -1) {
            this.comuneField.errors.push(error.response.data.errors.comune[0]);
          }
          if (keys.indexOf("indirizzo") !== -1) {
            this.indirizzoField.errors.push(
              error.response.data.errors.indirizzo[0]
            );
          }
          if (keys.indexOf("CAP") !== -1) {
            this.capField.errors.push(error.response.data.errors.CAP[0]);
          }
          if (keys.indexOf("localita") !== -1) {
            this.localitaField.errors.push(
              error.response.data.errors.localita[0]
            );
          }
          if (keys.indexOf("edificio") !== -1) {
            this.edificioField.errors.push(
              error.response.data.errors.edificio[0]
            );
          }
          if (keys.indexOf("numeroCivico") !== -1) {
            this.numeroCivicoField.errors.push(
              error.response.data.errors.numeroCivico[0]
            );
          }
        });
      this.loading = false;
    },
    async handleUpdate() {
      await this.fetchNations();
      if (this.fetchNationsError == false) {
        this.dialogFormVisible = true;
        this.initForm();
      } else {
        this.errorAlert();
      }
    },
    initForm() {
      this.isSubmitted = false;
      this.nazioneField.value = this.address.pkNazione;
      this.regioneField.value = this.address.regione;
      this.provinciaField.value = this.address.provincia;
      this.comuneField.value = this.address.comune;
      this.indirizzoField.value = this.address.indirizzo;
      this.capField.value = this.address.cap;
      this.localitaField.value = this.address.localita;
      this.edificioField.value = this.address.edificio;
      this.numeroCivicoField.value = this.address.numeroCivico;
    },
    handleClose(done) {
      if (this.formModified == true) {
        this.$confirm("Sei sicuro di voler chiudere?")
          .then(() => {
            done;
            this.dialogFormVisible = false;

            this.formModified = false;
          })
          .catch(() => {});
      } else {
        this.dialogFormVisible = false;
      }
    },
    errorAlert() {
      this.$message.error("Qualcosa è andato storto, riprova!");
    },
    async fetchNations() {
      await FetchNations()
        .then((response) => {
          this.nations = response.data;
        })
        .catch(() => {
          this.fetchNationsError = true;
        });
    },
    async fetchAddress() {
      this.loadingCard = true;
      await FetchAddress()
        .then((response) => {
          this.address = response.data;
        })
        .catch(() => {
          this.fetchAddressError = true;
        });
      this.loadingCard = false;
    },
  },
};
</script>

<style scoped>
.warning-description {
  color: #ff4949;
  padding: 4px;
  margin-bottom: 15px;
}
.warning-title {
  color: #ff4949;
  padding: 4px;
}
.el-icon-warning-outline {
  color: #ff4949;
  font-size: 150px;
  margin-bottom: 25px;
}
.box-card {
  margin-bottom: 25px;
}
.row {
  margin-bottom: 0px;
}
</style>
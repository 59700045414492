<template>
  <div class="row">
    <div class="col-12 mb-4">
      <el-date-picker
        v-model="dateSearch"
        type="daterange"
        size="medium"
        format="DD/MM/YYYY"
        placeholder="Seleziona"
        start-placeholder="Data iniziale"
        end-placeholder="Data finale"
        range-separator="al"
        clearable
        style="width: 230px"
      >
      </el-date-picker>
      <el-button
        size="medium"
        icon="el-icon-search"
        type="primary"
        class="ms-3"
        :loading="loadingRicerca"
        @click="handleRicerca"
        >Cerca</el-button
      >

      <el-button
        type="text"
        size="medium"
        class="ms-3"
        @click="handleDialogSearch"
      >
        Ricerca avanzata
      </el-button>
    </div>
  </div>
  <el-dialog
    v-model="dialogSearchVisible"
    width="80%"
    title="Ricerca avanzata"
    center
  >
    <div class="row justify-content-center">
      <div class="col text-center">
        <el-date-picker
          v-model="dateSearch"
          type="daterange"
          size="medium"
          format="DD/MM/YYYY"
          placeholder="Seleziona"
          start-placeholder="Data iniziale"
          end-placeholder="Data finale"
          range-separator="al"
          clearable
          style="width: 240px"
        >
        </el-date-picker>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col text-center">
        <el-input
          v-model="ordersSearch.Ricerca"
          placeholder="Ricerca"
          size="medium"
          class="mt-2"
          style="width: 240px"
        />
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col text-center">
        <el-select
          v-model="ordersSearch.Status"
          placeholder="Stato"
          multiple
          size="medium"
          class="mt-2"
          style="width: 240px"
          ><el-option
            v-for="item in status"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option
        ></el-select>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col text-center">
        <el-select
          :loading="loadingAgents"
          v-model="ordersSearch.PkAgenti"
          placeholder="Seleziona Agente"
          filterable
          multiple
          size="medium"
          class="mt-2"
          style="width: 240px"
          ><el-option
            v-for="item in agents"
            :key="item.pkAgente"
            :value="item.pkAgente"
            :label="item.nome + ' ' + item.cognome + ' (' + item.codice + ')'"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col text-center">
        <el-button
          size="medium"
          icon="el-icon-search"
          type="primary"
          class="mt-2"
          :loading="loadingRicerca"
          @click="handleRicerca"
          >Cerca</el-button
        >
      </div>
    </div>
  </el-dialog>
  <div v-if="loading" class="d-flex justify-content-center">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div v-else>
    <div class="table-responsive">
      <table class="table table-striped text-center">
        <thead>
          <tr>
            <td class="fw-bold">Tipo Vendita</td>
            <td class="fw-bold">Data Ordine</td>
            <td class="fw-bold">ID Ordine</td>
            <td class="fw-bold">Agente</td>
            <td class="fw-bold">Email Cliente</td>
            <td class="fw-bold">Pagato</td>
            <td class="fw-bold">Fatturato</td>
            <td class="fw-bold">Stato</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in orders" :key="item.pkCustomerOrder">
            <td>{{ item.tipoOrdine }}</td>
            <td>{{ item.orderCreated }}</td>
            <td>{{ item.orderID }}</td>
            <td>{{ item.agente }}</td>
            <td>{{ item.billingEmail }}</td>
            <td><el-popover placement="bottom" :width="300" trigger="click">
          <p><span class="fw-bold">IVA:</span> {{ item.totalTax }}</p>
          <p>
            <span class="fw-bold">Coupon:</span> {{ item.discountTotal }}
          </p>
          <p>
            <span class="fw-bold"> Spese di spedizione:</span>
            {{ item.shippingTotal }}
          </p>
          <template #reference>
            <el-button type="text"
              >{{ item.total }}<i class="el-icon-info ms-1"></i
            ></el-button>
          </template>
        </el-popover></td>
            <td>{{ item.totaleFatturabile }}</td>
            <td>{{ item.status }}</td>
            
          </tr>
        </tbody>
      </table>
    </div>
    <loading-mobile-table
      @loadMoreOrder="handleloadMore"
      :pageNumber="pageNumber"
      :pager="pager"
      :ordersSearch="ordersSearch"
      class="mt-2"
    ></loading-mobile-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import { FetchCustomerOrder, FetchAgentsSelect } from "@/api/fetchData.js";
import LoadingMobileTable from "@/components/CustomerOrder/LoadingMobileTable";
export default {
  components: { LoadingMobileTable },
  computed: {
    ...mapState({
      device: (state) => state.app.device,
    }),
  },
  data() {
    return {
      dateSearch: null,
      loading: false,
      pageNumber: 1,
      pageSize: 10,
      pager: [],
      orders: [],
      agents: [],
      loadingAfterScroll: false,
      noMoreOrders: false,
      ordersSearch: {
        DataInizio: undefined,
        DataFine: undefined,
        PkAgenti: undefined,
        Ricerca: undefined,
        Status: undefined,
      },
      status: [
        {
          value: "Creato",
          label: "Creato",
        },
        {
          value: "Pagato",
          label: "Pagato",
        },
      ],
      loadingAgents: false,
      dialogSearchVisible: false,
      loadingRicerca: false,
    };
  },
  created() {
    this.loadData();
  },

  methods: {
     handleRicerca() {
      try {
        this.disabledPagination = true;
        this.pageNumber = 1;
        this.pageSize = 10;
        this.loadingRicerca = true;
        if (this.dateSearch != null) {
          if (this.dateSearch[0] != null) {
            this.ordersSearch.DataInizio = moment
              .utc(this.dateSearch[0])
              .local()
              .format("YYYY-MM-DD[T]00:00:00");
          } else {
            this.ordersSearch.DataInizio = undefined;
          }
          if (this.dateSearch[1] != null) {
            this.ordersSearch.DataFine = moment
              .utc(this.dateSearch[1])
              .local()
              .format("YYYY-MM-DD[T]00:00:00");
          } else {
            this.ordersSearch.DataFine = undefined;
          }
        } else {
          this.ordersSearch.DataInizio = undefined;
          this.ordersSearch.DataFine = undefined;
        }
         FetchCustomerOrder(
          this.pageNumber,
          this.pageSize,
          this.ordersSearch.DataInizio,
          this.ordersSearch.DataFine,
          this.ordersSearch.PkAgenti,
          this.ordersSearch.Ricerca,
          this.ordersSearch.Status
        )
          .then((response) => {
            this.orders = response.data;
            this.pager = JSON.parse(response.headers["x-pagination"]);
            this.orders = this.initTable(this.orders);
          })
          .catch((error) =>
            window.alert("Attenzione, si è verificato un errore " + error)
          )
          .finally(() => {
            this.loadingRicerca = false;
            this.disabledPagination = false;
            this.dialogSearchVisible = false;
          });
      } catch {
        this.loadingRicerca = false;
        this.disabledPagination = false;
      }
    },
     fetchAgents() {
      this.loadingAgents = false;
       FetchAgentsSelect()
        .then((response) => {
          this.agents = response.data;
        })
        .catch((error) =>
          window.alert("Attenzione, si è verificato un errore " + error)
        )
        .finally(() => {
          this.loadingAgents = false;
        });
    },
    handleDialogSearch() {
      this.fetchAgents();
      this.dialogSearchVisible = true;
    },
    initTable(data) {
      data.forEach((element) => {
        element.orderCreated = moment(element.orderCreated).format(
          "DD/MM/YYYY, HH:mm:ss"
        );
        element.discountTotal =
          (element.discountTotal + element.discountTax).toFixed(2) + "€";

        element.shippingTotal =
          (element.shippingTotal + element.shippingTax).toFixed(2) + "€";
        element.total = element.total.toFixed(2) + "€";
        element.totalTax = element.totalTax.toFixed(2) + "€";
        element.totaleFatturabile = element.totaleFatturabile.toFixed(2) + "€";
      });
      return data;
    },
     loadData() {
      if (this.$route.params.id != undefined) {
        this.ordersSearch.Ricerca = this.$route.params.id;
      }
      this.disabledPagination = true;
      this.loading = true;
       FetchCustomerOrder(
        this.pageNumber,
        this.pageSize,
        this.ordersSearch.DataInizio,
        this.ordersSearch.DataFine,
        this.ordersSearch.PkAgenti,
        this.ordersSearch.Ricerca,
        this.ordersSearch.Status
      )
        .then((response) => {
          this.orders = this.initTable(response.data);
          this.pager = JSON.parse(response.headers["x-pagination"]);
        })
        .catch((error) =>
          window.alert("Attenzione, si è verificato un errore " + error)
        )
        .finally(() => {
          this.loading = false;

          this.disabledPagination = false;
        });
    },
    handleloadMore(value) {
      this.orders = this.orders.concat(value);
      this.pageNumber++;
    },
  },
};
</script>

<style>
</style>
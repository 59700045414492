<template>
  <div class="text-center" v-if="pager.HasNext">
    <el-button type="info" round @click="loadMore" :loading="loading"
      >Vedi altri</el-button
    >
  </div>
</template>
<script>
import moment from "moment";
import { FetchProvvigioni } from "@/api/fetchData.js";
export default {
  emits: ["loadMoreOrder"],
  props: ["pageNumber", "pager", "conto", "provvigioniSearch"],
  data() {
    return {
      newOrders: [],
      loading: false,
      pageSize: 10,
    };
  },
  methods: {
    async loadData() {
      await FetchProvvigioni(
        this.conto,
        this.pageNumber,
        this.pageSize,
        this.provvigioniSearch.DataMese,
        this.provvigioniSearch.PkAgenti,
        this.provvigioniSearch.IdOrdine,
        this.provvigioniSearch.Status,
        this.provvigioniSearch.Tipo
      ).then((response) => {
        this.newOrders = response.data;
        this.newOrders.forEach((element) => {
          element.total = element.total.toFixed(2) + "€";
          element.valore = element.valore.toFixed(2) + "€";
          element.percentuale = element.percentuale + "%";

          element.dataOra = moment(element.dataOra).format(
            "DD/MM/YYYY, HH:mm:ss"
          );
        });
      });
    },
    async loadMore() {
      this.loading = true;

      await this.loadData().then(() => {
        this.$emit("loadMoreOrder", this.newOrders);
      });

      this.loading = false;
    },
  },
};
</script>
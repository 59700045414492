<template>
  <form @submit.prevent="handleLogin">
    <input type="text" name="username" v-model="formValues.username" />
    <input type="password" name="password" v-model="formValues.password" />
    <button type="submit">Invia</button>
  </form>
</template>

<script>
import { Login } from "../api/login";
export default {
  data() {
    return {
      formValues: {
        username: "",
        password: "",
      },
    };
  },
  methods: {
    async handleLogin() {
      alert("1");
      await Login(this.formValues)
        .then((response) => {
            alert("2")
          this.$store.dispatch("user/login", response.data).then(() => {
            if (this.role == "User") {
              this.$router.push({ path: "/dashboard" });
			} else if ((this.role == "Admin") || (this.role == "President")) {
              this.$router.push({ path: "/dashboardAdmin" });
            }
          });
        })
        .catch((e) => {alert(e)});
    },
  },
};
</script>

<style>
</style>

<template>
    <div class="row">
        <div class="col ms-3 mb-3">
            <export-excel :searchParam="FormRicerca"></export-excel>
        </div>
    </div>
    <div class="row">
        <div class="col ms-3 mb-4">
            <el-date-picker v-model="FormRicerca.DataMese"
                            type="month"
                            size="medium"
                            format="MM/YYYY"
                            :default-value="FormRicerca.DataMese" />

            <el-select clearable
                       multiple
                       :loading="selectAgentiLoading"
                       v-model="FormRicerca.PkAgenti"
                       placeholder="Seleziona Agente"
                       filterable
                       size="medium"
                       class="ms-3"
                       style="width: 260px">
                <el-option v-for="item in AgentiItems"
                           :key="item.pkAgente"
                           :value="item.pkAgente"
                           :label="item.nome + ' ' + item.cognome + ' (' + item.codice + ')'" />
            </el-select>

            <el-select v-model="FormRicerca.Status"
                       placeholder="Stato"
                       size="medium"
                       class="ms-3"
                       style="width: 260px">
                <el-option v-for="item in StatusItems"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value" />
            </el-select>

            <el-button size="medium" icon="el-icon-search" type="primary" class="ms-3" :loading="btnRicercaLoading" @click="onClickRicerca">Cerca</el-button>
        </div>
    </div>

    <el-table v-loading="loading"
              :data="tableData"
              stripe
              fit
              highlight-current-row
              border>
        <el-table-column type="expand">
            <template #default="props">
                <provvigioni-table :id="props.row.pkAgente"
                                   :search="FormRicerca"
                                   :pageSize="pageSize"
                                   :pageNumber="pageNumber"
                                   :tipoRiga="props.row.flagProvvigione">
                </provvigioni-table>
            </template>
        </el-table-column>
        <el-table-column label="Descrizione" align="left" min-width="150">
            <template #default="props">
                <span>
                    <b>{{ props.row.nome }} {{ props.row.cognome }}</b><br />
                </span>
                <span>
                    <small>{{ props.row.descrizione }}</small>
                </span>
            </template>
        </el-table-column>

        <el-table-column label="Banca" align="left">
            <template #default="props">
                <small v-show="props.row.banca != ''">
                    <b><span>{{ props.row.banca }}</span></b><br />
                </small>
                <span>{{ props.row.iban }}</span>
            </template>
        </el-table-column>

        <el-table-column label="Informazioni Fiscali" align="left" min-width="150">
            <template #default="props">
                <span v-show="props.row.contributiInps != ''">
                    <small>{{ props.row.contributiInps }}<br /></small>
                </span>
                <span v-show="props.row.ritenuteFiscali != ''">
                    <small>{{ props.row.ritenuteFiscali }}<br /></small>
                </span>
            </template>
        </el-table-column>

        <el-table-column label="Tipo"
                         prop="flagProvvigione"
                         align="center"
                         min-width="45" />
        <el-table-column label="Provvigioni" align="right" min-width="60">
            <template #default="props">
                <span>{{ props.row.provvigioni.toFixed(2) }} €</span>
            </template>
        </el-table-column>

        <el-table-column label="Riporto" align="right" min-width="45">
            <template #default="props">
                <span>{{ props.row.riporto.toFixed(2) }} €</span>
            </template>
        </el-table-column>
        <el-table-column label="Totale Provvigioni" align="right">
            <template #default="props">
                <span>{{ props.row.totaleProvvigioni.toFixed(2) }} €</span>
            </template>
        </el-table-column>

        <el-table-column label="Deduzione Forfettaria"
                         prop="deduzioneForfettaria"
                         align="right"
                         min-width="95" />
        <el-table-column label="Imponibile Fiscale"
                         prop="imponibileFiscale"
                         align="right" />
        <el-table-column label="Ritenuta Fiscale"
                         prop="ritenutaFiscale"
                         align="right"
                         min-width="70" />

        <el-table-column label="Totale Dovuto" align="right" min-width="65">
            <template #default="props">
                <span>{{ props.row.totaleDovuto.toFixed(2) }} €</span>
            </template>
        </el-table-column>

        <el-table-column label="Azioni" align="center" class-name=" fixed-width">
            <template v-slot="{ row }">

                <BtnContabilizzareProvvigione :id="row.pkAgente"
                                              :DataMese="FormRicerca.DataMese"
                                              @contabilizzareSuccess="riempiTabella"
                                              v-if="row.flagProvvigione === 'Maturata'" />

                <BtnFatturareProvvigione :id="row.pkAgente"
                                           :DataMese="FormRicerca.DataMese"
                                           @fatturareSuccess="riempiTabella"
                                           v-if="row.flagProvvigione === 'Contabilizzata'" />

            </template>
        </el-table-column>
    </el-table>
    <pagination v-show="Number(pager.TotalCount) > 0"
                :total="Number(pager.TotalCount)"
                v-model:current-page="pageNumber"
                v-model:page-size="pageSize"
                @pagination="riempiTabella()"
                :disabled="disabledPagination" />
</template>

<script>
    import moment from 'moment';
    import ExportExcel from './AdminExport2ExcelContabilita.vue';
    import ProvvigioniTable from './AdminContabilitaExpandRow.vue';
    import BtnContabilizzareProvvigione from './BtnContabilizzareProvvigione.vue';
    import BtnFatturareProvvigione from './BtnFatturareProvvigione.vue';
    import { Contabilita, FetchAgentsSelect } from '@/api/fetchData.js';
    import Pagination from '@/components/Pagination';

    export default {
        components: {
            Pagination,
            ExportExcel,
            ProvvigioniTable,
            BtnContabilizzareProvvigione,
            BtnFatturareProvvigione,
        },
        data() {
            return {
                loading: false,
                pager: [],
                pageNumber: 1,
                pageSize: 10,
                tableData: [],
                FormRicerca: {
                    DataMese: moment().utc().local(),
                    PkAgenti: undefined,
                    Status: 1,
                    AllRows: undefined,
                },
                StatusItems: [
                    { value: 1, label: 'Maturate da pagare' },
                    { value: 2, label: 'Maturate da non pagare' },
                    { value: 3, label: 'Maturate con riporto' },
                    { value: 4, label: 'Maturate senza riporto' },
                    { value: 5, label: 'Contabilizzate da fatturare' }
                ],
                selectAgentiLoading: false,
                btnRicercaLoading: false,
                disabledPagination: false,
                AgentiItems: [],
            };
        },

        created() {
            this.riempiSelectAgenti();
            this.riempiTabella();
        },

        methods: {

            riempiTabella() {
                if (this.FormRicerca.DataMese != undefined) {
                    this.FormRicerca.DataMese = moment.utc(this.FormRicerca.DataMese).local().format('YYYY-MM-01[T]00:00:00');
                }
                this.disabledPagination = true;
                this.loading = true;

                let c = new Contabilita(this.pageNumber, this.pageSize);
                let result = c.Search(
                    this.FormRicerca.DataMese,
                    this.FormRicerca.PkAgenti,
                    this.FormRicerca.Status
                );

                result.then((response) => {
                    this.tableData = response.data;
                    this.pager = JSON.parse(response.headers['x-pagination']);
                    //this.tableData = this.initTable(this.tableData);
                })
                    .catch((error) =>
                        window.alert('Attenzione, si è verificato un errore ' + error)
                    )
                    .finally(() => {
                        this.loading = false;
                        this.disabledPagination = false;
                    });
            },

            riempiSelectAgenti() {

                this.selectAgentiLoading = true;

                FetchAgentsSelect().then((response) => {
                        this.AgentiItems = response.data;
                    }).catch((error) =>
                        window.alert('Attenzione, si è verificato un errore ' + error)
                    ).finally(() => {
                        this.selectAgentiLoading = false;
                    });
            },

            onClickRicerca() {

                this.disabledPagination = true;
                this.pageNumber = 1;
                this.pageSize = 10;
                this.btnRicercaLoading = true;
                this.tableData = []

                if (this.FormRicerca.DataMese != undefined) {
                    this.FormRicerca.DataMese = moment.utc(this.FormRicerca.DataMese).local().format('YYYY-MM-01[T]00:00:00');
                }

                let c = new Contabilita(this.pageNumber, this.pageSize);
                let result = c.Search(
                    this.FormRicerca.DataMese,
                    this.FormRicerca.PkAgenti,
                    this.FormRicerca.Status
                );

                result.then((response) => {
                    this.tableData = response.data;
                    this.pager = JSON.parse(response.headers['x-pagination']);
                })
                    .catch((error) => {
                        window.alert('Attenzione, si è verificato un errore ' + error);
                    })
                    .finally(() => {
                        this.btnRicercaLoading = false;
                        this.disabledPagination = false;
                    });
            },

            //    initTable(data) {
            //        data.forEach((element) => {
            //            element.nome = element.nome + " " + element.cognome + " (" + element.codice + ")";
            //            element.provvigioni = element.provvigioni.toFixed(2) + "€";
            //            element.riporto = element.riporto.toFixed(2) + "€";
            //            element.totaleProvvigioni = element.totaleProvvigioni.toFixed(2) + "€";
            //            element.deduzioneForfettaria = element.deduzioneForfettaria.toFixed(2) + "€";
            //            element.imponibileFiscale = element.imponibileFiscale.toFixed(2) + "€";
            //            element.ritenutaFiscale = element.ritenutaFiscale.toFixed(2) + "€";
            //            element.totaleDovuto = element.totaleDovuto.toFixed(2) + "€";
            //        });

            //        return data;
            //    },
        },
    };
</script>

<style>
</style>

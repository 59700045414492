<template>
  <slot :id="id"></slot>
  <el-button type="info" @click="loadLogProvvigioni"> Log </el-button>
  <div v-if="showLogProvvigioni">
    <div class="row mt-3 text-center">
      <div class="col">
        <span class="fw-bold fs-5">Log</span>
      </div>
    </div>
    <div class="row mt-3 text-start">
      <div class="col" style="height: 200px; overflow: auto">
        <!--<table class="table table-bordered table-responsive">
                    <tbody>
                      <tr v-for="item in logProvvigioni" :key="item" ><td>{{item}}</td></tr>
                    </tbody>
                    
                    </table>  -->
        <ul v-for="item in logProvvigioni" :key="item">
          <li>{{ item }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { LogProvvigioni } from "@/api/fetchData.js";
export default {
  emits: ["log"],
  props: ["id"],
  data() {
    return {
      loading: false,
      logProvvigioni: [],
      showLogProvvigioni: false,
    };
  },
  methods: {
    async loadLogProvvigioni() {
      this.loading = true;
      await LogProvvigioni(this.id)
        .then((response) => {
          this.logProvvigioni = response.data;
          this.showLogProvvigioni = true;
        })
        .catch(() => {
          alert("Errore nel caricamento dei dati");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
</style>